import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  data: {
    RPAbilities: {},
    Scrolls: {},
    Potions: {},
    Paths: {},
  },
  characterSheet: {
    level: '',
    characterName: '',
    characterTitle: '',
    characterGuild: 'None',
    characterGuildTitle: 'Member',
    path: '',
    subpath: '',
    subpathRPAbilityPlace: 'None',
    temporaryHitPoints: '',
    currentHitPoints: '',
    hitPointsMaximum: '',
    divineInfluence: '',
    agilityDefense: '',
    intuitionDefense: '',
    might: [false, false, false, false, false, false],
    divination: [false, false, false, false, false, false],
    agility: [false, false, false, false, false, false],
    intuition: [false, false, false, false, false, false],
    characterImage: '',
    declaredScrolls: [-1, -1, -1, -1, -1, -1, -1, -1, -1, -1],
    leveledScrolls: [],
    selectedScroll: null,
    showingScroll: 0,
    showModal: false,
    showNotes: false,
    showInventory: false,
    showPathSelect: false,
    showGuildSelect: false,
    isPhone: false,
    pathOrSubPathSelection: 'path',
    levelIsFocused: false,
  },
};

const cotaSlice = createSlice({
  name: 'cota',
  initialState,
  reducers: {
    setCharacterSheet: (state, action) => {
      state.characterSheet = { ...state.characterSheet, ...action.payload };
    },
    setCharacterImage: (state, action) => {
      state.characterSheet.characterImage = action.payload;
    },
    setShowModal: (state, action) => {
      state.characterSheet.showModal = action.payload;
    },
    // Add more reducers as needed
  },
});

export const { setCharacterSheet, setCharacterImage, setShowModal } =
  cotaSlice.actions;
export default cotaSlice.reducer;

import { useState, useEffect } from 'react';
import cotastatsimg from '../../assets/charactersheets/cotastats.png';
//import cotalogo from '../../assets/logos/cotalogo.png';
//import cotatitle from '../../assets/logos/cotatitle.png';
import '../../styles/Page.css';
import CotaCharacterSheet from '../../components/CotaCharacterSheet';
import TrialmentCharacterSheet from '../../components/TrialmentCharacterSheet';
import TopBar from '../../components/TopBar';
import DiceBox from '@3d-dice/dice-box';

const diceBox = new DiceBox('#dice-box', {
  id: 'dice-canvas',
  assetPath: '/assets/dice-box/',
  startingHeight: 10,
  throwForce: 6,
  spinForce: 5,
  lightIntensity: 0.9,
  scale: 4,
  themeColor: '#e94f37',
});

export default function Campaign() {
  const [system] = useState(localStorage.getItem('system'));
  const [campaign] = useState(localStorage.getItem('campaign'));
  const [mode, setMode] = useState('light');

  useEffect(() => {
    diceBox.init();
    const savedData = localStorage.getItem('appearance');
    if (savedData) {
      const { mode } = JSON.parse(savedData);
      setMode(mode);
    }
  }, []);

  useEffect(() => {
    const appearance = {
      mode,
    };
    localStorage.setItem('appearance', JSON.stringify(appearance));
  }, [mode]);

  const darkMode = () => {
    if (mode === 'dark') {
      return '_dark';
    }
    return '';
  };

  return (
    <div className='page_container'>
      <div className={'page_top page_top' + darkMode()}>
        <TopBar mode={mode} setMode={setMode} />
      </div>
      <div className='page_content'>
        <div className='page_1'>
          {system && system === 'trialment' ? (
            <TrialmentCharacterSheet
              campaign={campaign}
              mode={mode}
              editable={true}
              onModal={false}
              dmView={false}
              roll={true}
              diceBox={diceBox}
            />
          ) : ( 
            <CotaCharacterSheet
              mode={mode}
              editable={true}
              onModal={false}
              dmView={false}
              roll={true}
              diceBox={diceBox}
            />
          )}
        </div>
      </div>
    </div>
  );
}

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  data: {},
  characterSheet: {},
};

const trialmentSlice = createSlice({
  name: 'trialment',
  initialState,
  reducers: {
    // Define reducers for trialment
  },
});

export default trialmentSlice.reducer;
import Rodal from 'rodal';
import { useState, useEffect, useRef } from 'react';
import 'rodal/lib/rodal.css';
import '../styles/Cota.css';

export const CotaNotes = (props) => {
  const { show, close, mode } = props;
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [notes, setNotes] = useState('');
  const contentRef = useRef(null);

  const handleNotesChange = (event) => {
    setNotes(event.target.value);
  };

  const darkMode = () => {
    if (mode === 'dark') {
      return '_dark';
    }
    return '';
  };

  useEffect(() => {
    const savedNotes = localStorage.getItem('Notes');
    if (savedNotes) {
      setNotes(savedNotes);
    }
    const mediaQuery = window.matchMedia('(max-aspect-ratio: 1510/962)');
    const handleMediaChange = (e) => {
      setIsFullScreen(e.matches);
    };
    handleMediaChange(mediaQuery);
    mediaQuery.addListener(handleMediaChange);
    return () => mediaQuery.removeListener(handleMediaChange);
  }, []);

  useEffect(() => {
    localStorage.setItem('Notes', notes);
  }, [notes]);

  return (
    <Rodal
      animation={'door'}
      visible={show}
      onClose={close}
      customStyles={{
        position: 'absolute',
        border: mode === 'dark' ? '2px solid #393e41' : '2px solid #e4E7C5', // beige
        backgroundColor: mode === 'dark' ? '#26292C' : '#f6f7eb', // ivory
        width: isFullScreen ? 'calc(100% - 34px)' : '500px',
        height: isFullScreen ? 'calc(100% - 114px)' : '562px',
        marginTop: isFullScreen ? '80px' : '',
      }}
    >
      <div
        ref={contentRef}
        className={
          'cota_scroll_modal_content cota_scroll_modal_content' + darkMode()
        }
      >
        <h3 className='cota_scroll_modal_paragraph'>Notes</h3>
        <div
          className={
            'cota_scroll_modal_divider cota_scroll_modal_divider' + darkMode()
          }
        />

        <textarea
          className={
            'cota_notes_modal_notes cota_notes_modal_notes' + darkMode()
          }
          value={notes}
          onChange={handleNotesChange}
        />
      </div>
    </Rodal>
  );
};

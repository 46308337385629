export const combatScrolls = [
  {
    path: 'Any',
    subpath: '',
    name: 'Weapon Strike',
    rarity: 'Neutral unique combat scroll',
    attackRoll: [
      '2d6',
      '2d6',
      '3d6',
      '3d6',
      '4d6',
      '4d6',
      '5d6',
      '5d6',
      '6d6',
      '6d6',
    ],
    defendingStat: 'Agility',
    priority: [1, 1, 1, 1, 1, 1, 1, 1, 1, 1],
    damage: ['5', '7', '7', '9', '9', '12', '12', '15', '15', '17'],
    target: [
      'One enemy',
      'One enemy',
      'One enemy',
      'One enemy',
      'One enemy',
      'One enemy',
      'One enemy',
      'One enemy',
      'One enemy',
      'One enemy',
    ],
    keyword: 'None',
    duration: [
      'Instant',
      'Instant',
      'Instant',
      'Instant',
      'Instant',
      'Instant',
      'Instant',
      'Instant',
      'Instant',
      'Instant',
    ],
    durable: [
      'Yes',
      'Yes',
      'Yes',
      'Yes',
      'Yes',
      'Yes',
      'Yes',
      'Yes',
      'Yes',
      'Yes',
    ],
    description: 'The Awakened wields his weapon and attacks his enemy',
    effects: [
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
    ],
    fragmentsToLevel: [0, 100, 200, 200, 200, 200, 200, 200, 200, 200],
  },
  {
    path: 'Any',
    subpath: '',
    name: 'Ichor Strike',
    rarity: 'Neutral uncommon combat scroll',
    attackRoll: [
      '2d6',
      '2d6',
      '3d6',
      '3d6',
      '4d6',
      '4d6',
      '5d6',
      '5d6',
      '6d6',
      '6d6',
    ],
    defendingStat: 'Agility',
    priority: [10, 12, 12, 14, 14, 16, 16, 18, 18, 20],
    damage: ['5', '7', '7', '9', '9', '12', '12', '15', '15', '17'],
    target: [
      'One enemy',
      'One enemy',
      'One enemy',
      'One enemy',
      'One enemy',
      'One enemy',
      'One enemy',
      'One enemy',
      'One enemy',
      'One enemy',
    ],
    keyword: 'None',
    duration: [
      'Instant',
      'Instant',
      'Instant',
      'Instant',
      'Instant',
      'Instant',
      'Instant',
      'Instant',
      'Instant',
      'Instant',
    ],
    durable: ['No', 'No', 'No', 'No', 'No', 'No', 'No', 'No', 'No', 'No'],
    description:
      'The Awakened launches an attack with his weapon enhanced by the power of the ichor.',
    effects: [
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
    ],
    fragmentsToLevel: [0, 50, 80, 120, 180, 250, 350, 500, 700, 1000],
  },
  {
    path: 'Any',
    subpath: '',
    name: 'Ichor Assault',
    rarity: 'Neutral uncommon combat scroll',
    attackRoll: [
      '2d6',
      '2d6',
      '3d6',
      '3d6',
      '4d6',
      '4d6',
      '5d6',
      '5d6',
      '6d6',
      '6d6',
    ],
    defendingStat: 'Intuition',
    priority: [10, 12, 12, 14, 14, 16, 16, 18, 18, 20],
    damage: [
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
    ],
    target: [
      'One enemy',
      'One enemy',
      'One enemy',
      'One enemy',
      'One enemy',
      'One enemy',
      'One enemy',
      'One enemy',
      'One enemy',
      'One enemy',
    ],
    keyword: 'Stun',
    duration: [
      'One Action',
      'One Action',
      'One Action',
      'One Action',
      'One Action',
      'One Action',
      'One Action',
      'One Action',
      'One Action',
      'One Action',
    ],
    durable: ['No', 'No', 'No', 'No', 'No', 'No', 'No', 'No', 'No', 'No'],
    description:
      'The Awakened uses the ichor to cause confusion to the target and force him to skip his turn.',
    effects: [
      'Stuns the enemy for their next action this round, if the enemy has an available action this round.',
      'Stuns the enemy for their next action this round, if the enemy has an available action this round.',
      'Stuns the enemy for their next action this round, if the enemy has an available action this round.',
      'Stuns the enemy for their next action this round, if the enemy has an available action this round.',
      'Stuns the enemy for their next action this round, if the enemy has an available action this round.',
      'Stuns the enemy for their next action this round, if the enemy has an available action this round.',
      'Stuns the enemy for their next action this round, if the enemy has an available action this round.',
      'Stuns the enemy for their next action this round, if the enemy has an available action this round.',
      'Stuns the enemy for their next action this round, if the enemy has an available action this round.',
      'Stuns the enemy for their next action this round, if the enemy has an available action this round.',
    ],
    fragmentsToLevel: [0, 50, 80, 120, 180, 250, 350, 500, 700, 1000],
  },
  {
    path: 'Any',
    subpath: '',
    name: 'Ichor Pulse',
    rarity: 'Neutral uncommon combat scroll',
    attackRoll: [
      '2d6',
      '2d6',
      '3d6',
      '3d6',
      '4d6',
      '4d6',
      '5d6',
      '5d6',
      '6d6',
      '6d6',
    ],
    defendingStat: 'Intuition',
    priority: [10, 12, 12, 14, 14, 16, 16, 18, 18, 20],
    damage: ['5', '7', '7', '9', '9', '12', '12', '15', '15', '17'],
    target: [
      'One enemy',
      'One enemy',
      'One enemy',
      'One enemy',
      'One enemy',
      'One enemy',
      'One enemy',
      'One enemy',
      'One enemy',
      'One enemy',
    ],
    keyword: 'None',
    duration: [
      'Instant',
      'Instant',
      'Instant',
      'Instant',
      'Instant',
      'Instant',
      'Instant',
      'Instant',
      'Instant',
      'Instant',
    ],
    durable: ['No', 'No', 'No', 'No', 'No', 'No', 'No', 'No', 'No', 'No'],
    description:
      'The Awakened releases a mental attack fueled by the power of ichor in an attempt to break the defenses of the target.',
    effects: [
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
    ],
    fragmentsToLevel: [0, 50, 80, 120, 180, 250, 350, 500, 700, 1000],
  },
  {
    path: 'Any',
    subpath: '',
    name: 'Ichor Corruption',
    rarity: 'Neutral uncommon combat scroll',
    attackRoll: [
      '2d6',
      '2d6',
      '3d6',
      '3d6',
      '4d6',
      '4d6',
      '5d6',
      '5d6',
      '6d6',
      '6d6',
    ],
    defendingStat: 'Intuition',
    priority: [10, 12, 12, 14, 14, 16, 16, 18, 18, 20],
    damage: [
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
    ],
    target: [
      'One enemy',
      'One enemy',
      'One enemy',
      'One enemy',
      'One enemy',
      'One enemy',
      'One enemy',
      'One enemy',
      'One enemy',
      'One enemy',
    ],
    keyword: 'None',
    duration: [
      'Two rounds',
      'Two rounds',
      'Two rounds',
      'Three rounds',
      'Three rounds',
      'Three rounds',
      'Three rounds',
      'Four rounds',
      'Four rounds',
      'Four rounds',
    ],
    durable: ['No', 'No', 'No', 'No', 'No', 'No', 'No', 'No', 'No', 'No'],
    description:
      'The Awakened uses his ichor to infect the target weakening future strikes.',
    effects: [
      'The target receives -3 to the damage for the duration of the scroll.',
      'The target receives -3 to the damage for the duration of the scroll.',
      'The target receives -3 to the damage for the duration of the scroll.',
      'The target receives -3 to the damage for the duration of the scroll.',
      'The target receives -5 to the damage for the duration of the scroll.',
      'The target receives -5 to the damage for the duration of the scroll.',
      'The target receives -5 to the damage for the duration of the scroll.',
      'The target receives -5 to the damage for the duration of the scroll.',
      'The target receives -8 to the damage for the duration of the scroll.',
      'The target receives -8 to the damage for the duration of the scroll.',
    ],
    fragmentsToLevel: [0, 50, 80, 120, 180, 250, 350, 500, 700, 1000],
  },
  {
    path: 'Any',
    subpath: '',
    name: 'Ichor Accurracy',
    rarity: 'Neutral uncommon combat scroll',
    attackRoll: [
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
    ],
    defendingStat: 'None',
    priority: [11, 13, 13, 15, 15, 17, 17, 19, 19, 21],
    damage: [
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
    ],
    target: [
      'One ally',
      'One ally',
      'One ally',
      'One ally',
      'One ally',
      'One ally',
      'One ally',
      'One ally',
      'One ally',
      'One ally',
    ],
    keyword: 'None',
    duration: [
      'Two rounds',
      'Two rounds',
      'Two rounds',
      'Three rounds',
      'Three rounds',
      'Three rounds',
      'Three rounds',
      'Four rounds',
      'Four rounds',
      'Four rounds',
    ],
    durable: [
      'Yes',
      'Yes',
      'Yes',
      'Yes',
      'Yes',
      'Yes',
      'Yes',
      'Yes',
      'Yes',
      'Yes',
    ],
    description:
      'The Awakened calls the power of Ichor to enhance the future strikes of the target',
    effects: [
      'The target gets +1d6 on attack rolls for the duration of the scroll.',
      'The target gets +1d6 on attack rolls for the duration of the scroll.',
      'The target gets +1d6 on attack rolls for the duration of the scroll.',
      'The target gets +2d6 on attack rolls for the duration of the scroll.',
      'The target gets +2d6 on attack rolls for the duration of the scroll.',
      'The target gets +2d6 on attack rolls for the duration of the scroll.',
      'The target gets +3d6 on attack rolls for the duration of the scroll.',
      'The target gets +3d6 on attack rolls for the duration of the scroll.',
      'The target gets +3d6 on attack rolls for the duration of the scroll.',
      'The target gets +3d6 on attack rolls for the duration of the scroll.',
    ],
    fragmentsToLevel: [0, 50, 80, 120, 180, 250, 350, 500, 700, 1000],
  },
  {
    path: 'Any',
    subpath: '',
    name: 'Ichor Disruption',
    rarity: 'Neutral uncommon combat scroll',
    attackRoll: [
      '1d6',
      '1d6',
      '1d6',
      '2d6',
      '2d6',
      '2d6',
      '3d6',
      '3d6',
      '3d6',
      '4d6',
    ],
    defendingStat: 'Intuition',
    priority: [3, 5, 5, 5, 7, 7, 7, 9, 9, 9],
    damage: ['5', '7', '7', '9', '9', '12', '12', '15', '15', '17'],
    target: [
      'Two enemy',
      'Two enemy',
      'Three enemy',
      'Three enemy',
      'Three enemy',
      'Four enemy',
      'Four enemy',
      'Four enemy',
      'Four enemy',
      'Five enemy',
    ],
    keyword: 'None',
    duration: [
      'Instant',
      'Instant',
      'Instant',
      'Instant',
      'Instant',
      'Instant',
      'Instant',
      'Instant',
      'Instant',
      'Instant',
    ],
    durable: ['No', 'No', 'No', 'No', 'No', 'No', 'No', 'No', 'No', 'No'],
    description:
      'The Awakened releases mental pulses fueled by the power of ichor in an attempt to bend the will of his targets.',
    effects: [
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
    ],
    fragmentsToLevel: [0, 50, 80, 120, 180, 250, 350, 500, 700, 1000],
  },
  {
    path: 'Any',
    subpath: '',
    name: 'Ichor Might',
    rarity: 'Neutral uncommon combat scroll',
    attackRoll: [
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
    ],
    defendingStat: 'None',
    priority: [11, 13, 13, 15, 15, 17, 17, 19, 19, 21],
    damage: [
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
    ],
    target: [
      'One ally',
      'One ally',
      'One ally',
      'One ally',
      'Two ally',
      'Two ally',
      'Two ally',
      'Two ally',
      'Two ally',
      'Three ally',
    ],
    keyword: 'Buff Damage',
    duration: [
      'Two rounds',
      'Two rounds',
      'Two rounds',
      'Three rounds',
      'Three rounds',
      'Three rounds',
      'Three rounds',
      'Four rounds',
      'Four rounds',
      'Four rounds',
    ],
    durable: [
      'Yes',
      'Yes',
      'Yes',
      'Yes',
      'Yes',
      'Yes',
      'Yes',
      'Yes',
      'Yes',
      'Yes',
    ],
    description:
      'The Awakened summons the power of Ichor to enhance the damage of his target.',
    effects: [
      'The target of the scroll gets +2 damage for the duration of the scroll.',
      'The target of the scroll gets +2 damage for the duration of the scroll.',
      'The target of the scroll gets +2 damage for the duration of the scroll.',
      'The target of the scroll gets +4 damage for the duration of the scroll.',
      'The target of the scroll gets +4 damage for the duration of the scroll.',
      'The target of the scroll gets +4 damage for the duration of the scroll.',
      'The target of the scroll gets +6 damage for the duration of the scroll.',
      'The target of the scroll gets +6 damage for the duration of the scroll.',
      'The target of the scroll gets +6 damage for the duration of the scroll.',
      'The target of the scroll gets +8 damage for the duration of the scroll.',
    ],
    fragmentsToLevel: [0, 50, 80, 120, 180, 250, 350, 500, 700, 1000],
  },
  {
    path: 'Any',
    subpath: '',
    name: 'Ichor Surge',
    rarity: 'Neutral uncommon combat scroll',
    attackRoll: [
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
    ],
    defendingStat: 'None',
    priority: [12, 14, 14, 16, 16, 18, 18, 21, 21, 23],
    damage: [
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
    ],
    target: [
      'One ally',
      'One ally',
      'One ally',
      'One ally',
      'Two ally',
      'Two ally',
      'Two ally',
      'Two ally',
      'Two ally',
      'Three ally',
    ],
    keyword: 'Buff Priority',
    duration: [
      'Two rounds',
      'Two rounds',
      'Two rounds',
      'Three rounds',
      'Three rounds',
      'Three rounds',
      'Three rounds',
      'Four rounds',
      'Four rounds',
      'Four rounds',
    ],
    durable: [
      'Yes',
      'Yes',
      'Yes',
      'Yes',
      'Yes',
      'Yes',
      'Yes',
      'Yes',
      'Yes',
      'Yes',
    ],
    description:
      'The Awakened calls the power of Ichor to enhance the speed of the target.',
    effects: [
      'The target receives +6 priority for the duration of the scroll.',
      'The target receives +6 priority for the duration of the scroll.',
      'The target receives +7 priority for the duration of the scroll.',
      'The target receives +7 priority for the duration of the scroll.',
      'The target receives +8 priority for the duration of the scroll.',
      'The target receives +8 priority for the duration of the scroll.',
      'The target receives +9 priority for the duration of the scroll.',
      'The target receives +9 priority for the duration of the scroll.',
      'The target receives +10 priority for the duration of the scroll.',
      'The target receives +10 priority for the duration of the scroll.',
    ],
    fragmentsToLevel: [0, 50, 80, 120, 180, 250, 350, 500, 700, 1000],
  },
  {
    path: 'Any',
    subpath: '',
    name: 'Ichor Protection',
    rarity: 'Neutral uncommon combat scroll',
    attackRoll: [
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
    ],
    defendingStat: 'None',
    priority: [13, 15, 15, 17, 17, 19, 19, 21, 21, 23],
    damage: [
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
    ],
    target: [
      'One ally',
      'One ally',
      'One ally',
      'One ally',
      'Two ally',
      'Two ally',
      'Two ally',
      'Two ally',
      'Two ally',
      'Three ally',
    ],
    keyword: 'Taunt, damage reduction',
    duration: [
      'Two rounds',
      'Two rounds',
      'Two rounds',
      'Two rounds',
      'Three rounds',
      'Three rounds',
      'Three rounds',
      'Three rounds',
      'Four rounds',
      'Four rounds',
    ],
    durable: [
      'Yes',
      'Yes',
      'Yes',
      'Yes',
      'Yes',
      'Yes',
      'Yes',
      'Yes',
      'Yes',
      'Yes',
    ],
    description:
      'The awakened uses the ichor to challenge his enemy to a duel and reduce the damage of the oncoming attack.',
    effects: [
      'The next attack of the target must target the awakened. The awakened receives 3 damage reduction for the duration of this scroll.',
      'The next attack of the target must target the awakened. The awakened receives 4 damage reduction for the duration of this scroll.',
      'The next attack of the target must target the awakened. The awakened receives 4 damage reduction for the duration of this scroll.',
      'The next attack of the target must target the awakened. The awakened receives 5 damage reduction for the duration of this scroll.',
      'The next attack of the target must target the awakened. The awakened receives 5 damage reduction for the duration of this scroll.',
      'The next attack of the target must target the awakened. The awakened receives 6 damage reduction for the duration of this scroll.',
      'The next attack of the target must target the awakened. The awakened receives 6 damage reduction for the duration of this scroll.',
      'The next attack of the target must target the awakened. The awakened receives 7 damage reduction for the duration of this scroll.',
      'The next attack of the target must target the awakened. The awakened receives 7 damage reduction for the duration of this scroll.',
      'The next attack of the target must target the awakened. The awakened receives 8 damage reduction for the duration of this scroll.',
    ],
    fragmentsToLevel: [0, 50, 80, 120, 180, 250, 350, 500, 700, 1000],
  },
  {
    path: 'Any',
    subpath: '',
    name: 'Ichor Quake',
    rarity: 'Neutral uncommon combat scroll',
    attackRoll: [
      '1d6',
      '1d6',
      '1d6',
      '2d6',
      '2d6',
      '2d6',
      '3d6',
      '3d6',
      '3d6',
      '4d6',
    ],
    defendingStat: 'Agility',
    priority: [3, 5, 5, 5, 7, 7, 7, 9, 9, 9],
    damage: ['5', '7', '7', '9', '9', '12', '12', '15', '15', '17'],
    target: [
      'Two enemy',
      'Two enemy',
      'Three enemy',
      'Three enemy',
      'Three enemy',
      'Four enemy',
      'Four enemy',
      'Four enemy',
      'Four enemy',
      'Five enemy',
    ],
    keyword: 'None',
    duration: [
      'Instant',
      'Instant',
      'Instant',
      'Instant',
      'Instant',
      'Instant',
      'Instant',
      'Instant',
      'Instant',
      'Instant',
    ],
    durable: ['No', 'No', 'No', 'No', 'No', 'No', 'No', 'No', 'No', 'No'],
    description:
      'The Awakened releases seismic waves fueled by the power of ichor in an attempt to harm his targets.',
    effects: [
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
    ],
    fragmentsToLevel: [0, 50, 80, 120, 180, 250, 350, 500, 700, 1000],
  },
  {
    path: 'Enlightened Archon',
    subpath: '',
    name: 'The Grimoire',
    rarity: 'Archon’s scroll',
    attackRoll: 'None',
    defendingStat: 'None',
    priority: 'Interrupt',
    damage: 'None',
    target: 'One Enlightened (not you)',
    keyword: 'None',
    duration: 'Instant',
    durable: 'Yes (2 uses)',
    description:
      'The Enlightened Archon adds 1d6 to the rolls of any Enlightened.',
    effects: 'Can be used in or out of combat.',
  },
  {
    path: 'Enlightened Herald',
    subpath: '',
    name: 'The Banner of knowledge',
    rarity: 'Herald’s scroll',
    attackRoll: 'None',
    defendingStat: 'None',
    priority: 'Interrupt',
    damage: 'None',
    target: 'One Enlightened (not you)',
    keyword: 'None',
    duration: 'Instant',
    durable: 'Yes (2 uses)',
    description:
      'The Enlightened Herald adds 1d6 to the rolls of any Enlightened.',
    effects: 'Can be used in combat only.',
  },
  {
    path: 'Enlightened Ambassador',
    subpath: '',
    name: 'Diplomatic Resolve',
    rarity: 'Ambassador’s scroll',
    attackRoll: 'None',
    defendingStat: 'None',
    priority: 'Interrupt',
    damage: 'None',
    target: 'One Enlightened (not you)',
    keyword: 'None',
    duration: 'Instant',
    durable: 'Yes (2 uses)',
    description:
      'The Enlightened Ambassador adds 1d6 to the rolls of any Enlightened.',
    effects: 'Can be used out combat only.',
  },
  {
    path: 'Ironsteam Archon',
    subpath: '',
    name: 'The Cogwheel',
    rarity: 'Archon’s scroll',
    attackRoll: 'None',
    defendingStat: 'None',
    priority: 'Interrupt',
    damage: 'None',
    target: 'One Ironsteam (not you)',
    keyword: 'None',
    duration: 'Instant',
    durable: 'Yes (2 uses)',
    description: 'The Ironsteam Archon adds 1d6 to the rolls of any Ironsteam.',
    effects: 'Can be used in or out of combat.',
  },
  {
    path: 'Ironsteam Herald',
    subpath: '',
    name: 'The Banner of Progress',
    rarity: 'Herald’s scroll',
    attackRoll: 'None',
    defendingStat: 'None',
    priority: 'Interrupt',
    damage: 'None',
    target: 'One Ironsteam (not you)',
    keyword: 'None',
    duration: 'Instant',
    durable: 'Yes (2 uses)',
    description: 'The Ironsteam Herald adds 1d6 to the rolls of any Ironsteam.',
    effects: 'Can be used in combat only.',
  },
  {
    path: 'Ironsteam Ambassador',
    subpath: '',
    name: 'Diplomatic Resolve',
    rarity: 'Ambassador’s scroll',
    attackRoll: 'None',
    defendingStat: 'None',
    priority: 'Interrupt',
    damage: 'None',
    target: 'One Ironsteam (not you)',
    keyword: 'None',
    duration: 'Instant',
    durable: 'Yes (2 uses)',
    description:
      'The Ironsteam Ambassador adds 1d6 to the rolls of any Ironsteam.',
    effects: 'Can be used out combat only.',
  },
  {
    path: 'Honorbound Archon',
    subpath: '',
    name: 'The Banner',
    rarity: 'Archon’s scroll',
    attackRoll: 'None',
    defendingStat: 'None',
    priority: 'Interrupt',
    damage: 'None',
    target: 'One Honorbound (not you)',
    keyword: 'None',
    duration: 'Instant',
    durable: 'Yes (2 uses)',
    description:
      'The Honorbound Archon adds 1d6 to the rolls of any Honorbound.',
    effects: 'Can be used in or out of combat.',
  },
  {
    path: 'Honorbound Herald',
    subpath: '',
    name: 'The Banner of Courage',
    rarity: 'Herald’s scroll',
    attackRoll: 'None',
    defendingStat: 'None',
    priority: 'Interrupt',
    damage: 'None',
    target: 'One Honorbound (not you)',
    keyword: 'None',
    duration: 'Instant',
    durable: 'Yes (2 uses)',
    description:
      'The Honorbound Herald adds 1d6 to the rolls of any Honorbound.',
    effects: 'Can be used in combat only.',
  },
  {
    path: 'Honorbound Ambassador',
    subpath: '',
    name: 'Diplomatic Resolve',
    rarity: 'Ambassador’s scroll',
    attackRoll: 'None',
    defendingStat: 'None',
    priority: 'Interrupt',
    damage: 'None',
    target: 'One Honorbound (not you)',
    keyword: 'None',
    duration: 'Instant',
    durable: 'Yes (2 uses)',
    description:
      'The Honorbound Ambassador adds 1d6 to the rolls of any Honorbound.',
    effects: 'Can be used out combat only.',
  },
  {
    path: 'Wildheart Archon',
    subpath: '',
    name: 'The Acorn',
    rarity: 'Archon’s scroll',
    attackRoll: 'None',
    defendingStat: 'None',
    priority: 'Interrupt',
    damage: 'None',
    target: 'One Wildheart (not you)',
    keyword: 'None',
    duration: 'Instant',
    durable: 'Yes (2 uses)',
    description: 'The Wildheart Archon adds 1d6 to the rolls of any Wildheart.',
    effects: 'Can be used in or out of combat.',
  },
  {
    path: 'Wildheart Herald',
    subpath: '',
    name: 'The Banner of Nature',
    rarity: 'Herald’s scroll',
    attackRoll: 'None',
    defendingStat: 'None',
    priority: 'Interrupt',
    damage: 'None',
    target: 'One Wildheart (not you)',
    keyword: 'None',
    duration: 'Instant',
    durable: 'Yes (2 uses)',
    description: 'The Wildheart Herald adds 1d6 to the rolls of any Wildheart.',
    effects: 'Can be used in combat only.',
  },
  {
    path: 'Wildheart Ambassador',
    subpath: '',
    name: 'Diplomatic Resolve',
    rarity: 'Ambassador’s scroll',
    attackRoll: 'None',
    defendingStat: 'None',
    priority: 'Interrupt',
    damage: 'None',
    target: 'One Wildheart (not you)',
    keyword: 'None',
    duration: 'Instant',
    durable: 'Yes (2 uses)',
    description:
      'The Wildheart Ambassador adds 1d6 to the rolls of any Wildheart.',
    effects: 'Can be used out combat only.',
  },
  {
    path: 'Blighter',
    subpath: '',
    name: 'Life Drain',
    rarity: 'Blighter rare combat scroll',
    attackRoll: [
      '2d6',
      '2d6',
      '3d6',
      '3d6',
      '4d6',
      '4d6',
      '5d6',
      '5d6',
      '6d6',
      '6d6',
    ],
    defendingStat: 'Agility',
    priority: [13, 15, 15, 17, 17, 19, 19, 21, 21, 23],
    damage: ['5', '7', '7', '9', '9', '12', '12', '15', '15', '17'],
    target: [
      'One enemy',
      'One enemy',
      'One enemy',
      'One enemy',
      'One enemy',
      'One enemy',
      'One enemy',
      'One enemy',
      'One enemy',
      'One enemy',
    ],
    keyword: 'Invigorating Strike',
    duration: [
      'Instant',
      'Instant',
      'Instant',
      'Instant',
      'Instant',
      'Instant',
      'Instant',
      'Instant',
      'Instant',
      'Instant',
    ],
    durable: ['No', 'No', 'No', 'No', 'No', 'No', 'No', 'No', 'No', 'No'],
    description:
      'The Blighter disrupts the very life force of their target, absorbing it to bolster their own strength.',
    effects: [
      'If this attack succeeds, the Blighter receives half of the damage he inflicted, rounded up (accounting for potential increases or decreases), as temporary hit points.',
      'If this attack succeeds, the Blighter receives half of the damage he inflicted, rounded up (accounting for potential increases or decreases), as temporary hit points.',
      'If this attack succeeds, the Blighter receives half of the damage he inflicted, rounded up (accounting for potential increases or decreases), as temporary hit points.',
      'If this attack succeeds, the Blighter receives half of the damage he inflicted, rounded up (accounting for potential increases or decreases), as temporary hit points.',
      'If this attack succeeds, the Blighter receives half of the damage he inflicted, rounded up (accounting for potential increases or decreases), as temporary hit points.',
      'If this attack succeeds, the Blighter receives half of the damage he inflicted, rounded up (accounting for potential increases or decreases), as temporary hit points.',
      'If this attack succeeds, the Blighter receives half of the damage he inflicted, rounded up (accounting for potential increases or decreases), as temporary hit points.',
      'If this attack succeeds, the Blighter receives half of the damage he inflicted, rounded up (accounting for potential increases or decreases), as temporary hit points.',
      'If this attack succeeds, the Blighter receives half of the damage he inflicted, rounded up (accounting for potential increases or decreases), as temporary hit points.',
      'If this attack succeeds, the Blighter receives half of the damage he inflicted, rounded up (accounting for potential increases or decreases), as temporary hit points.',
    ],
    fragmentsToLevel: [0, 70, 120, 180, 250, 350, 500, 700, 1000, 1500],
  },
  {
    path: 'Blighter',
    subpath: '',
    name: 'Essence Drain',
    rarity: 'Blighter rare combat scroll',
    attackRoll: [
      '2d6',
      '2d6',
      '3d6',
      '3d6',
      '4d6',
      '4d6',
      '5d6',
      '5d6',
      '6d6',
      '6d6',
    ],
    defendingStat: 'Intuition',
    priority: [7, 9, 9, 9, 11, 11, 11, 13, 13, 13],
    damage: ['5', '7', '7', '9', '9', '12', '12', '15', '15', '17'],
    target: [
      'One enemy',
      'One enemy',
      'One enemy',
      'One enemy',
      'One enemy',
      'One enemy',
      'One enemy',
      'One enemy',
      'One enemy',
      'One enemy',
    ],
    keyword: 'Debuff damage, Buff damage',
    duration: [
      'Two rounds',
      'Two rounds',
      'Two rounds',
      'Two rounds',
      'Two rounds',
      'Three rounds',
      'Three rounds',
      'Three rounds',
      'Three rounds',
      'Three rounds',
    ],
    durable: ['No', 'No', 'No', 'No', 'No', 'No', 'No', 'No', 'No', 'No'],
    description:
      'The Blighter disrupts the flow of ichor within their target, leaving them utterly weakened. The influx of ichor into the Blighter’s own body strengthens them, making them far more dangerous in future attacks..',
    effects: [
      'The target receives -3 damage and the Blighter receives +2 damage for the duration of the scroll.',
      'The target receives -3 damage and the Blighter receives +2 damage for the duration of the scroll.',
      'The target receives -4 damage and the Blighter receives +3 damage for the duration of the scroll.',
      'The target receives -4 damage and the Blighter receives +3 damage for the duration of the scroll.',
      'The target receives -5 damage and the Blighter receives +4 damage for the duration of the scroll.',
      'The target receives -5 damage and the Blighter receives +4 damage for the duration of the scroll.',
      'The target receives -6 damage and the Blighter receives +5 damage for the duration of the scroll.',
      'The target receives -6 damage and the Blighter receives +5 damage for the duration of the scroll.',
      'The target receives -7 damage and the Blighter receives +6 damage for the duration of the scroll.',
      'The target receives -7 damage and the Blighter receives +6 damage for the duration of the scroll.',
    ],
    fragmentsToLevel: [0, 70, 120, 180, 250, 350, 500, 700, 1000, 1500],
  },
  {
    path: 'Blighter',
    subpath: '',
    name: 'Weird Reconstruction',
    rarity: 'Blighter epic combat scroll',
    attackRoll: [
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
    ],
    defendingStat: 'None',
    priority: [19, 22, 22, 24, 24, 27, 27, 29, 29, 29],
    damage: [
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
    ],
    target: [
      'One ally',
      'One ally',
      'One ally',
      'One ally',
      'One ally',
      'One ally',
      'One ally',
      'One ally',
      'One ally',
      'One ally',
    ],
    keyword: 'Heal(Instant) Blight(Two rounds)',
    duration: [
      'Heal(Instant) Blight(Two rounds)',
      'Heal(Instant) Blight(Two rounds)',
      'Heal(Instant) Blight(Two rounds)',
      'Heal(Instant) Blight(Two rounds)',
      'Heal(Instant) Blight(Two rounds)',
      'Heal(Instant) Blight(Three rounds)',
      'Heal(Instant) Blight(Three rounds)',
      'Heal(Instant) Blight(Three rounds)',
      'Heal(Instant) Blight(Three rounds)',
      'Heal(Instant) Blight(Three rounds)',
    ],
    durable: ['No', 'No', 'No', 'No', 'No', 'No', 'No', 'No', 'No', 'No'],
    description:
      'Blighter controls the target’s vital fluids, compelling them to heal wounds and mend broken bones as quickly as possible. Despite its effectiveness, this hurried healing binds the target’s bones haphazardly, causing pain.',
    effects: [
      'The Blighter’s target is healed for 13 hit points and then afflicted with Blight. The targets take 2 damage after each of their actions for the duration of the scroll. If the targets perform multiple actions during their turn, they take the damage for each of those actions. This damage is not affected by buffs or debuffs.”',
      'The Blighter’s target is healed for 13 hit points and then afflicted with Blight. The targets take 3 damage after each of their actions for the duration of the scroll. If the targets perform multiple actions during their turn, they take the damage for each of those actions. This damage is not affected by buffs or debuffs.',
      'The Blighter’s target is healed for 17 hit points and then afflicted with Blight. The targets take 3 damage after each of their actions for the duration of the scroll. If the targets perform multiple actions during their turn, they take the damage for each of those actions. This damage is not affected by buffs or debuffs.',
      'The Blighter’s target is healed for 17 hit points and then afflicted with Blight. The targets take 4 damage after each of their actions for the duration of the scroll. If the targets perform multiple actions during their turn, they take the damage for each of those actions. This damage is not affected by buffs or debuffs.',
      'The Blighter’s target is healed for 21 hit points and then afflicted with Blight. The targets take 4 damage after each of their actions for the duration of the scroll. If the targets perform multiple actions during their turn, they take the damage for each of those actions. This damage is not affected by buffs or debuffs.',
      'The Blighter’s target is healed for 21 hit points and then afflicted with Blight. The targets take 5 damage after each of their actions for the duration of the scroll. If the targets perform multiple actions during their turn, they take the damage for each of those actions. This damage is not affected by buffs or debuffs.',
      'The Blighter’s target is healed for 25 hit points and then afflicted with Blight. The targets take 5 damage after each of their actions for the duration of the scroll. If the targets perform multiple actions during their turn, they take the damage for each of those actions. This damage is not affected by buffs or debuffs.',
      'The Blighter’s target is healed for 25 hit points and then afflicted with Blight. The targets take 6 damage after each of their actions for the duration of the scroll. If the targets perform multiple actions during their turn, they take the damage for each of those actions. This damage is not affected by buffs or debuffs.',
      'The Blighter’s target is healed for 29 hit points and then afflicted with Blight. The targets take 6 damage after each of their actions for the duration of the scroll. If the targets perform multiple actions during their turn, they take the damage for each of those actions. This damage is not affected by buffs or debuffs.',
      'The Blighter’s target is healed for 29 hit points and then afflicted with Blight. The targets take 7 damage after each of their actions for the duration of the scroll. If the targets perform multiple actions during their turn, they take the damage for each of those actions. This damage is not affected by buffs or debuffs.',
    ],
    fragmentsToLevel: [0, 100, 150, 250, 400, 600, 850, 1200, 1600, 2200],
  },
  {
    path: 'Blighter',
    subpath: '',
    name: 'Venomous Infestation',
    rarity: 'Blighter rare combat scroll',
    attackRoll: [
      '2d6',
      '2d6',
      '3d6',
      '3d6',
      '4d6',
      '4d6',
      '5d6',
      '5d6',
      '6d6',
      '6d6',
    ],
    defendingStat: 'Agility',
    priority: [3, 5, 5, 5, 7, 7, 7, 9, 9, 9],
    damage: ['5', '6', '6', '8', '8', '9', '9', '10', '10', '12'],
    target: [
      'One enemy',
      'One enemy',
      'One enemy',
      'One enemy',
      'One enemy',
      'One enemy',
      'One enemy',
      'One enemy',
      'One enemy',
      'One enemy',
    ],
    keyword: 'Repeating',
    duration: [
      'Two rounds',
      'Two rounds',
      'Two rounds',
      'Two rounds',
      'Two rounds',
      'Three rounds',
      'Three rounds',
      'Three rounds',
      'Three rounds',
      'Three rounds',
    ],
    durable: ['No', 'No', 'No', 'No', 'No', 'No', 'No', 'No', 'No', 'No'],
    description:
      'A single touch from Blighter is enough to poison the target’s blood, causing ongoing damage.',
    effects: [
      'The damage of this scroll repeats at the start of target’s next turn without needing to roll for a new attack. Any buff or debuff affects only the scroll damage without affecting the secondary repeating damage.',
      'The damage of this scroll repeats at the start of target’s next turn without needing to roll for a new attack. Any buff or debuff affects only the scroll damage without affecting the secondary repeating damage.',
      'The damage of this scroll repeats at the start of target’s next turn without needing to roll for a new attack. Any buff or debuff affects only the scroll damage without affecting the secondary repeating damage.',
      'The damage of this scroll repeats at the start of target’s next turn without needing to roll for a new attack. Any buff or debuff affects only the scroll damage without affecting the secondary repeating damage.',
      'The damage of this scroll repeats at the start of target’s next turn without needing to roll for a new attack. Any buff or debuff affects only the scroll damage without affecting the secondary repeating damage.',
      'The damage of this scroll repeats at the start of target’s next turn without needing to roll for a new attack. Any buff or debuff affects only the scroll damage without affecting the secondary repeating damage.',
      'The damage of this scroll repeats at the start of target’s next turn without needing to roll for a new attack. Any buff or debuff affects only the scroll damage without affecting the secondary repeating damage.',
      'The damage of this scroll repeats at the start of target’s next turn without needing to roll for a new attack. Any buff or debuff affects only the scroll damage without affecting the secondary repeating damage.',
      'The damage of this scroll repeats at the start of target’s next turn without needing to roll for a new attack. Any buff or debuff affects only the scroll damage without affecting the secondary repeating damage.',
      'The damage of this scroll repeats at the start of target’s next turn without needing to roll for a new attack. Any buff or debuff affects only the scroll damage without affecting the secondary repeating damage.',
    ],
    fragmentsToLevel: [0, 70, 120, 180, 250, 350, 500, 700, 1000, 1500],
  },
  {
    path: 'Blighter',
    subpath: '',
    name: 'Die..Die..Die..',
    rarity: 'Blighter legendary combat scroll',
    attackRoll: [
      '1d6',
      '1d6',
      '1d6',
      '2d6',
      '2d6',
      '2d6',
      '3d6',
      '3d6',
      '3d6',
      '4d6',
    ],
    defendingStat: 'Intuition',
    priority: [1, 3, 3, 3, 5, 5, 5, 7, 7, 7],
    damage: ['15', '17', '17', '20', '20', '23', '23', '27', '27', '30'],
    target: [
      'One enemy',
      'One enemy',
      'One enemy',
      'One enemy',
      'One enemy',
      'One enemy',
      'One enemy',
      'One enemy',
      'One enemy',
      'One enemy',
    ],
    keyword: 'None',
    duration: [
      'Instant',
      'Instant',
      'Instant',
      'Instant',
      'Instant',
      'Instant',
      'Instant',
      'Instant',
      'Instant',
      'Instant',
    ],
    durable: ['No', 'No', 'No', 'No', 'No', 'No', 'No', 'No', 'No', 'No'],
    description:
      'A single touch from Blighter is enough to poison the target’s blood, causing ongoing damage.',
    effects: [
      'The Blighter receives 5 damage regardless of succeeding or failing the attack roll',
      'The Blighter receives 6 damage regardless of succeeding or failing the attack roll',
      'The Blighter receives 6 damage regardless of succeeding or failing the attack roll',
      'The Blighter receives 7 damage regardless of succeeding or failing the attack roll',
      'The Blighter receives 7 damage regardless of succeeding or failing the attack roll',
      'The Blighter receives 8 damage regardless of succeeding or failing the attack roll',
      'The Blighter receives 8 damage regardless of succeeding or failing the attack roll',
      'The Blighter receives 9 damage regardless of succeeding or failing the attack roll',
      'The Blighter receives 9 damage regardless of succeeding or failing the attack roll',
      'The Blighter receives 10 damage regardless of succeeding or failing the attack roll',
    ],
    fragmentsToLevel: [0, 150, 250, 400, 600, 850, 1200, 1600, 2200, 3000],
  },
  {
    path: 'Blighter',
    subpath: '',
    name: 'Pestilence Swarm',
    rarity: 'Blighter rare combat scroll',
    attackRoll: [
      '2d6',
      '2d6',
      '3d6',
      '3d6',
      '4d6',
      '4d6',
      '5d6',
      '5d6',
      '6d6',
      '6d6',
    ],
    defendingStat: 'Agility',
    priority: [4, 6, 6, 6, 8, 8, 8, 10, 10, 10],
    damage: [
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
    ],
    target: [
      'One enemy',
      'One enemy',
      'One enemy',
      'Two enemy',
      'Two enemy',
      'Two enemy',
      'Three enemy',
      'Three enemy',
      'Three enemy',
      'Four enemy',
    ],
    keyword: 'None',
    duration: [
      'Two rounds',
      'Two rounds',
      'Three rounds',
      'Three rounds',
      'Three rounds',
      'Three rounds',
      'Four rounds',
      'Four rounds',
      'Four rounds',
      'Four rounds',
    ],
    durable: ['No', 'No', 'No', 'No', 'No', 'No', 'No', 'No', 'No', 'No'],
    description:
      'The Blighter envelops their unfortunate target in swarms of insects, carrying various diseases. The muscles and flesh of the target become infected, with wounds opening with each movement.',
    effects: [
      'The target takes 3 damage after each of their actions for the duration of the scroll. If the target performs multiple actions during their turn, they take the damage for each of those actions. This damage is not affected by buffs or debuffs.',
      'The target takes 3 damage after each of their actions for the duration of the scroll. If the target performs multiple actions during their turn, they take the damage for each of those actions. This damage is not affected by buffs or debuffs.',
      'The target takes 4 damage after each of their actions for the duration of the scroll. If the target performs multiple actions during their turn, they take the damage for each of those actions. This damage is not affected by buffs or debuffs.',
      'The target takes 4 damage after each of their actions for the duration of the scroll. If the target performs multiple actions during their turn, they take the damage for each of those actions. This damage is not affected by buffs or debuffs.',
      'The target takes 5 damage after each of their actions for the duration of the scroll. If the target performs multiple actions during their turn, they take the damage for each of those actions. This damage is not affected by buffs or debuffs.',
      'The target takes 5 damage after each of their actions for the duration of the scroll. If the target performs multiple actions during their turn, they take the damage for each of those actions. This damage is not affected by buffs or debuffs.',
      'The target takes 6 damage after each of their actions for the duration of the scroll. If the target performs multiple actions during their turn, they take the damage for each of those actions. This damage is not affected by buffs or debuffs.',
      'The target takes 6 damage after each of their actions for the duration of the scroll. If the target performs multiple actions during their turn, they take the damage for each of those actions. This damage is not affected by buffs or debuffs.',
      'The target takes 7 damage after each of their actions for the duration of the scroll. If the target performs multiple actions during their turn, they take the damage for each of those actions. This damage is not affected by buffs or debuffs.',
      'The target takes 7 damage after each of their actions for the duration of the scroll. If the target performs multiple actions during their turn, they take the damage for each of those actions. This damage is not affected by buffs or debuffs.',
    ],
    fragmentsToLevel: [0, 70, 120, 180, 250, 350, 500, 700, 1000, 1500],
  },
  {
    path: 'Blighter',
    subpath: '',
    name: 'Plague Infusion',
    rarity: 'Blighter rare combat scroll',
    attackRoll: [
      '2d6',
      '2d6',
      '3d6',
      '3d6',
      '4d6',
      '4d6',
      '5d6',
      '5d6',
      '6d6',
      '6d6',
    ],
    defendingStat: 'Intuition',
    priority: [9, 11, 11, 11, 13, 13, 13, 15, 15, 15],
    damage: ['5', '7', '7', '9', '9', '12', '12', '15', '15', '18'],
    target: [
      'One enemy',
      'One enemy',
      'One enemy',
      'One enemy',
      'One enemy',
      'One enemy',
      'One enemy',
      'One enemy',
      'One enemy',
      'One enemy',
    ],
    keyword: 'Repeating',
    duration: [
      'Two rounds',
      'Two rounds',
      'Two rounds',
      'Three rounds',
      'Three rounds',
      'Three rounds',
      'Four rounds',
      'Four rounds',
      'Four rounds',
      'Four rounds',
    ],
    durable: ['No', 'No', 'No', 'No', 'No', 'No', 'No', 'No', 'No', 'No'],
    description:
      'The Blighter’s aura carries dreadful diseases that, if they affect the target, weaken and leave them vulnerable to future attacks.',
    effects: [
      'The next damage the target receives before the end of their next turn is increased by x1.5. The damage multiplier occurs before the addition of any Buffs/Debuffs.',
      'The next damage the target receives before the end of their next turn is increased by x1.5. The damage multiplier occurs before the addition of any Buffs/Debuffs.',
      'The next damage the target receives before the end of their next turn is increased by x1.5. The damage multiplier occurs before the addition of any Buffs/Debuffs.',
      'The next damage the target receives before the end of their next turn is increased by x1.5. The damage multiplier occurs before the addition of any Buffs/Debuffs.',
      'The next damage the target receives before the end of their next turn is increased by x1.5. The damage multiplier occurs before the addition of any Buffs/Debuffs.',
      'The next damage the target receives before the end of their next turn is increased by x1.5. The damage multiplier occurs before the addition of any Buffs/Debuffs.',
      'The next damage the target receives before the end of their next turn is increased by x1.5. The damage multiplier occurs before the addition of any Buffs/Debuffs.',
      'The next damage the target receives before the end of their next turn is increased by x1.5. The damage multiplier occurs before the addition of any Buffs/Debuffs.',
      'The next damage the target receives before the end of their next turn is increased by x1.5. The damage multiplier occurs before the addition of any Buffs/Debuffs.',
      'The next damage the target receives before the end of their next turn is increased by x1.5. The damage multiplier occurs before the addition of any Buffs/Debuffs.',
    ],
    fragmentsToLevel: [0, 70, 120, 180, 250, 350, 500, 700, 1000, 1500],
  },
  {
    path: 'Blighter',
    subpath: '',
    name: 'Blight Nova',
    rarity: 'Blighter epic combat scroll',
    attackRoll: [
      '2d6',
      '2d6',
      '3d6',
      '3d6',
      '4d6',
      '4d6',
      '5d6',
      '5d6',
      '6d6',
      '6d6',
    ],
    defendingStat: 'Intuition',
    priority: [16, 18, 18, 21, 21, 23, 23, 25, 25, 27],
    damage: ['5', '7', '7', '9', '9', '12', '12', '15', '15', '17'],
    target: [
      'Two enemy and two allies',
      'Two enemy and two allies',
      'Three enemy and two allies',
      'Three enemy and two allies',
      'Four enemy and two allies',
      'Four enemy and two allies',
      'Five enemy and two allies',
      'Five enemy and two allies',
      'Six enemy and two allies',
      'Six enemy and two allies',
    ],
    keyword: 'Blight',
    duration: [
      'Two rounds',
      'Three rounds',
      'Three rounds',
      'Three rounds',
      'Four rounds',
      'Four rounds',
      'Four rounds',
      'Five rounds',
      'Five rounds',
      'Five rounds',
    ],
    durable: ['No', 'No', 'No', 'No', 'No', 'No', 'No', 'No', 'No', 'No'],
    description:
      'Blighter, in a blind burst of rage, contaminates the blood of all targets around him, causing it to boil and inflict damage with each of their movements.',
    effects: [
      'The targets take 3 damage which surpasses damage reduction after each of their actions for the duration of the scroll. If the targets perform multiple actions during their turn, they take the damage for each of those actions. This damage is not affected by buffs or debuffs. The damage of the scroll affects only the enemies while the blight affects all the enemies and allies.',
      'The targets take 3 damage which surpasses damage reduction after each of their actions for the duration of the scroll. If the targets perform multiple actions during their turn, they take the damage for each of those actions. This damage is not affected by buffs or debuffs. The damage of the scroll affects only the enemies while the blight affects all the enemies and allies.',
      'The targets take 3 damage which surpasses damage reduction after each of their actions for the duration of the scroll. If the targets perform multiple actions during their turn, they take the damage for each of those actions. This damage is not affected by buffs or debuffs. The damage of the scroll affects only the enemies while the blight affects all the enemies and allies.',
      'The targets take 3 damage which surpasses damage reduction after each of their actions for the duration of the scroll. If the targets perform multiple actions during their turn, they take the damage for each of those actions. This damage is not affected by buffs or debuffs. The damage of the scroll affects only the enemies while the blight affects all the enemies and allies.',
      'The targets take 3 damage which surpasses damage reduction after each of their actions for the duration of the scroll. If the targets perform multiple actions during their turn, they take the damage for each of those actions. This damage is not affected by buffs or debuffs. The damage of the scroll affects only the enemies while the blight affects all the enemies and allies.',
      'The targets take 3 damage which surpasses damage reduction after each of their actions for the duration of the scroll. If the targets perform multiple actions during their turn, they take the damage for each of those actions. This damage is not affected by buffs or debuffs. The damage of the scroll affects only the enemies while the blight affects all the enemies and allies.',
      'The targets take 3 damage which surpasses damage reduction after each of their actions for the duration of the scroll. If the targets perform multiple actions during their turn, they take the damage for each of those actions. This damage is not affected by buffs or debuffs. The damage of the scroll affects only the enemies while the blight affects all the enemies and allies.',
      'The targets take 3 damage which surpasses damage reduction after each of their actions for the duration of the scroll. If the targets perform multiple actions during their turn, they take the damage for each of those actions. This damage is not affected by buffs or debuffs. The damage of the scroll affects only the enemies while the blight affects all the enemies and allies.',
      'The targets take 3 damage which surpasses damage reduction after each of their actions for the duration of the scroll. If the targets perform multiple actions during their turn, they take the damage for each of those actions. This damage is not affected by buffs or debuffs. The damage of the scroll affects only the enemies while the blight affects all the enemies and allies.',
      'The targets take 3 damage which surpasses damage reduction after each of their actions for the duration of the scroll. If the targets perform multiple actions during their turn, they take the damage for each of those actions. This damage is not affected by buffs or debuffs. The damage of the scroll affects only the enemies while the blight affects all the enemies and allies.',
    ],
    fragmentsToLevel: [0, 100, 150, 250, 350, 500, 700, 1000, 1500, 2200],
  },
  {
    path: 'Blighter',
    subpath: '',
    name: 'Decay’s Grasp',
    rarity: 'Blighter rare combat scroll',
    attackRoll: [
      '2d6',
      '2d6',
      '3d6',
      '3d6',
      '4d6',
      '4d6',
      '5d6',
      '5d6',
      '6d6',
      '6d6',
    ],
    defendingStat: 'Agility',
    priority: [5, 7, 7, 7, 9, 9, 9, 11, 11, 11],
    damage: [
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
    ],
    target: [
      'One enemy',
      'One enemy',
      'One enemy',
      'Two enemy',
      'Two enemy',
      'Two enemy',
      'Two enemy',
      'Three enemy',
      'Three enemy',
      'Three enemy',
    ],
    keyword: 'Debuff priority, Debuff attack',
    duration: [
      'Two rounds',
      'Two rounds',
      'Two rounds',
      'Two rounds',
      'Two rounds',
      'Three rounds',
      'Three rounds',
      'Three rounds',
      'Three rounds',
      'Three rounds',
    ],
    durable: ['No', 'No', 'No', 'No', 'No', 'No', 'No', 'No', 'No', 'No'],
    description:
      'The living flesh obeys Blighter’s commands. With a single word, pieces of the target’s flesh rapidly start to decompose, weakening their offensive abilities and speed.',
    effects: [
      'The target receives -6 priority and -2 to the attack rolls for the duration of the scroll.',
      'The target receives -6 priority and -3 to the attack rolls for the duration of the scroll.',
      'The target receives -7 priority and -3 to the attack rolls for the duration of the scroll.',
      'The target receives -7 priority and -4 to the attack rolls for the duration of the scroll.',
      'The target receives -8 priority and -4 to the attack rolls for the duration of the scroll.',
      'The target receives -8 priority and -5 to the attack rolls for the duration of the scroll.',
      'The target receives -9 priority and -5 to the attack rolls for the duration of the scroll.',
      'The target receives -9 priority and -6 to the attack rolls for the duration of the scroll.',
      'The target receives -10 priority and -6 to the attack rolls for the duration of the scroll.',
      'The target receives -10 priority and -7 to the attack rolls for the duration of the scroll.',
    ],
    fragmentsToLevel: [0, 70, 120, 180, 250, 350, 500, 700, 1000, 1500],
  },
  {
    path: 'Blighter',
    subpath: '',
    name: 'Summon Undead Treants',
    rarity: 'Blighter legendary combat scroll',
    attackRoll: [
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
    ],
    defendingStat: 'None',
    priority: [4, 6, 6, 6, 8, 8, 8, 10, 10, 10],
    damage: [
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
    ],
    target: [
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
    ],
    keyword: 'None',
    duration: [
      'Two rounds',
      'Two rounds',
      'Two rounds',
      'Two rounds',
      'Two rounds',
      'Three rounds',
      'Three rounds',
      'Three rounds',
      'Three rounds',
      'Three rounds',
    ],
    durable: ['No', 'No', 'No', 'No', 'No', 'No', 'No', 'No', 'No', 'No'],
    description:
      'The undead nature rises and spreads plague and disease to all targets.',
    effects: [
      'The Blighter summons 2 undead treants. At the end of his turn and for the duration of this scroll, he can command them to attack to any target as he wishes. Each treant has 5 hit points and can be targeted by the Blighter’s adversaries (they have no defenses, and any attack automatically hits them). Each treant makes an attack with +1d6, hitting the Intuition defense of the target. In case of success, they cause Blight 1 (The target takes 1 damage after each of their actions for the duration of the scroll) which stacks with any other blight abilities active on the target. Aditionally, this blight renews the duration of the debuff if lower than the duration of this scroll.These treants cannot be affected by any power boost and vanish at the end of the scroll’s duration.',
      'The Blighter summons 2 undead treants. At the end of his turn and for the duration of this scroll, he can command them to attack to any target as he wishes. Each treant has 5 hit points and can be targeted by the Blighter’s adversaries (they have no defenses, and any attack automatically hits them). Each treant makes an attack with +1d6, hitting the Intuition defense of the target. In case of success, they cause Blight 1 (The target takes 1 damage after each of their actions for the duration of the scroll) which stacks with any other blight abilities active on the target. Aditionally, this blight renews the duration of the debuff if lower than the duration of this scroll.These treants cannot be affected by any power boost and vanish at the end of the scroll’s duration.',
      'The Blighter summons 3 undead treants. At the end of his turn and for the duration of this scroll, he can command them to attack to any target as he wishes. Each treant has 5 hit points and can be targeted by the Blighter’s adversaries (they have no defenses, and any attack automatically hits them). Each treant makes an attack with +1d6, hitting the Intuition defense of the target. In case of success, they cause Blight 1 (The target takes 1 damage after each of their actions for the duration of the scroll) which stacks with any other blight abilities active on the target. Aditionally, this blight renews the duration of the debuff if lower than the duration of this scroll.These treants cannot be affected by any power boost and vanish at the end of the scroll’s duration.',
      'The Blighter summons 3 undead treants. At the end of his turn and for the duration of this scroll, he can command them to attack to any target as he wishes. Each treant has 5 hit points and can be targeted by the Blighter’s adversaries (they have no defenses, and any attack automatically hits them). Each treant makes an attack with +2d6, hitting the Intuition defense of the target. In case of success, they cause Blight 2 (The target takes 2 damage after each of their actions for the duration of the scroll) which stacks with any other blight abilities active on the target. Aditionally, this blight renews the duration of the debuff if lower than the duration of this scroll.These treants cannot be affected by any power boost and vanish at the end of the scroll’s duration.',
      'The Blighter summons 4 undead treants. At the end of his turn and for the duration of this scroll, he can command them to attack to any target as he wishes. Each treant has 5 hit points and can be targeted by the Blighter’s adversaries (they have no defenses, and any attack automatically hits them). Each treant makes an attack with +2d6, hitting the Intuition defense of the target. In case of success, they cause Blight 2 (The target takes 2 damage after each of their actions for the duration of the scroll) which stacks with any other blight abilities active on the target. Aditionally, this blight renews the duration of the debuff if lower than the duration of this scroll.These treants cannot be affected by any power boost and vanish at the end of the scroll’s duration.',
      'The Blighter summons 4 undead treants. At the end of his turn and for the duration of this scroll, he can command them to attack to any target as he wishes. Each treant has 5 hit points and can be targeted by the Blighter’s adversaries (they have no defenses, and any attack automatically hits them). Each treant makes an attack with +2d6, hitting the Intuition defense of the target. In case of success, they cause Blight 2 (The target takes 2 damage after each of their actions for the duration of the scroll) which stacks with any other blight abilities active on the target. Aditionally, this blight renews the duration of the debuff if lower than the duration of this scroll.These treants cannot be affected by any power boost and vanish at the end of the scroll’s duration.',
      'The Blighter summons 5 undead treants. At the end of his turn and for the duration of this scroll, he can command them to attack to any target as he wishes. Each treant has 5 hit points and can be targeted by the Blighter’s adversaries (they have no defenses, and any attack automatically hits them). Each treant makes an attack with +3d6, hitting the Intuition defense of the target. In case of success, they cause Blight 3 (The target takes 3 damage after each of their actions for the duration of the scroll) which stacks with any other blight abilities active on the target. Aditionally, this blight renews the duration of the debuff if lower than the duration of this scroll.These treants cannot be affected by any power boost and vanish at the end of the scroll’s duration.',
      'The Blighter summons 5 undead treants. At the end of his turn and for the duration of this scroll, he can command them to attack to any target as he wishes. Each treant has 5 hit points and can be targeted by the Blighter’s adversaries (they have no defenses, and any attack automatically hits them). Each treant makes an attack with +3d6, hitting the Intuition defense of the target. In case of success, they cause Blight 3 (The target takes 3 damage after each of their actions for the duration of the scroll) which stacks with any other blight abilities active on the target. Aditionally, this blight renews the duration of the debuff if lower than the duration of this scroll.These treants cannot be affected by any power boost and vanish at the end of the scroll’s duration.',
      'The Blighter summons 6 undead treants. At the end of his turn and for the duration of this scroll, he can command them to attack to any target as he wishes. Each treant has 5 hit points and can be targeted by the Blighter’s adversaries (they have no defenses, and any attack automatically hits them). Each treant makes an attack with +3d6, hitting the Intuition defense of the target. In case of success, they cause Blight 3 (The target takes 3 damage after each of their actions for the duration of the scroll) which stacks with any other blight abilities active on the target. Aditionally, this blight renews the duration of the debuff if lower than the duration of this scroll.These treants cannot be affected by any power boost and vanish at the end of the scroll’s duration.',
      'The Blighter summons 6 undead treants. At the end of his turn and for the duration of this scroll, he can command them to attack to any target as he wishes. Each treant has 5 hit points and can be targeted by the Blighter’s adversaries (they have no defenses, and any attack automatically hits them). Each treant makes an attack with +4d6, hitting the Intuition defense of the target. In case of success, they cause Blight 4 (The target takes 4 damage after each of their actions for the duration of the scroll) which stacks with any other blight abilities active on the target. Aditionally, this blight renews the duration of the debuff if lower than the duration of this scroll.These treants cannot be affected by any power boost and vanish at the end of the scroll’s duration.',
    ],
    fragmentsToLevel: [0, 150, 250, 400, 600, 850, 1200, 1600, 2200, 3000],
  },
  {
    path: 'Blighter',
    subpath: 'Chaosweaver',
    name: 'Unstable Hexing',
    rarity: 'Blighter epic combat scroll',
    attackRoll: [
      '2d6',
      '2d6',
      '3d6',
      '3d6',
      '4d6',
      '4d6',
      '5d6',
      '5d6',
      '6d6',
      '6d6',
    ],
    defendingStat: 'Intuition',
    priority: [21, 23, 23, 25, 25, 27, 27, 29, 29, 31],
    damage: ['None', 'None', 'None', 'None', 'None', 'None', 'None', 'None', 'None', 'None'],
    target: [
      'One enemy',
      'One enemy',
      'One enemy',
      'One enemy',
      'One enemy',
      'One enemy',
      'One enemy',
      'One enemy',
      'One enemy',
      'One enemy',
    ],
    keyword: 'Debuff Weakness',
    duration: [
      'Two rounds',
      'Two rounds',
      'Two rounds',
      'Two rounds',
      'Two rounds',
      'Three rounds',
      'Three rounds',
      'Three rounds',
      'Three rounds',
      'Three rounds',

    ],
    durable: ['No', 'No', 'No', 'No', 'No', 'No', 'No', 'No', 'No', 'No'],
    description:
      'The Blighter mastered the technic to make his enemies suffer each time the target gets a debuff.',
    effects: [
      ' The target of the blighter receives 3 damage each time a player successfully put a debuff on the target (if a scroll will activate more than one debuff the target only gets 3 damage)',
      ' The target of the blighter receives 3 damage each time a player successfully put a debuff on the target (if a scroll will activate more than one debuff the target only gets 3 damage)',
      ' The target of the blighter receives 4 damage each time a player successfully put a debuff on the target (if a scroll will activate more than one debuff the target only gets 4 damage)',
      ' The target of the blighter receives 4 damage each time a player successfully put a debuff on the target (if a scroll will activate more than one debuff the target only gets 4 damage)',
      ' The target of the blighter receives 5 damage each time a player successfully put a debuff on the target (if a scroll will activate more than one debuff the target only gets 5 damage)',
      ' The target of the blighter receives 5 damage each time a player successfully put a debuff on the target (if a scroll will activate more than one debuff the target only gets 5 damage)',
      ' The target of the blighter receives 6 damage each time a player successfully put a debuff on the target (if a scroll will activate more than one debuff the target only gets 6 damage)',
      ' The target of the blighter receives 6 damage each time a player successfully put a debuff on the target (if a scroll will activate more than one debuff the target only gets 6 damage)',
      ' The target of the blighter receives 7 damage each time a player successfully put a debuff on the target (if a scroll will activate more than one debuff the target only gets 7 damage)',
      ' The target of the blighter receives 7 damage each time a player successfully put a debuff on the target (if a scroll will activate more than one debuff the target only gets 7 damage)',
    ],
    fragmentsToLevel: [0, 100, 150, 250, 350, 500, 700, 1000, 1500, 2200],
  },
  {
    path: 'Blighter',
    subpath: 'Chaosweaver',
    name: 'Soul Tether Hex',
    rarity: 'Blighter epic combat scroll',
    attackRoll: [
      '2d6',
      '2d6',
      '3d6',
      '3d6',
      '4d6',
      '4d6',
      '5d6',
      '5d6',
      '6d6',
      '6d6',
    ],
    defendingStat: 'Intuition',
    priority: [5, 7, 7, 7, 9, 9, 9, 11, 11, 11],
    damage: ['None', 'None', 'None', 'None', 'None', 'None', 'None', 'None', 'None', 'None'],
    target: [
      'One enemy',
      'One enemy',
      'One enemy',
      'One enemy',
      'One enemy',
      'One enemy',
      'One enemy',
      'One enemy',
      'One enemy',
      'One enemy',
    ],
    keyword: 'Debuff Weakness',
    duration: [
      'One action',
      'One action',
      'One action',
      'Two action',
      'Two action',
      'Two action',
      'Two action',
      'Three action',
      'Three action',
      'Three action',
    ],
    durable: ['No', 'No', 'No', 'No', 'No', 'No', 'No', 'No', 'No', 'No'],
    description:
      'The Blighter is not the master in causing pain on his opponent, but he is the master of causing pain on himself in order to achieve his goals.',
    effects: [
      'The Blighter may choose to suffer 8 damage in order to use this spell as the second of the round.\nThe target of the blighter for his next damaging attack will deal 5 less damage. ',
      'The Blighter may choose to suffer 8 damage in order to use this spell as the second of the round.\nThe target of the blighter for his next damaging attack will deal 5 less damage. ',
      'The Blighter may choose to suffer 8 damage in order to use this spell as the second of the round.\nThe target of the blighter for his next damaging attack will deal 6 less damage. ',
      'The Blighter may choose to suffer 8 damage in order to use this spell as the second of the round.\nThe target of the blighter for his next damaging attack will deal 6 less damage. ',
      'The Blighter may choose to suffer 8 damage in order to use this spell as the second of the round.\nThe target of the blighter for his next damaging attack will deal 7 less damage. ',
      'The Blighter may choose to suffer 8 damage in order to use this spell as the second of the round.\nThe target of the blighter for his next damaging attack will deal 7 less damage. ',
      'The Blighter may choose to suffer 8 damage in order to use this spell as the second of the round.\nThe target of the blighter for his next damaging attack will deal 8 less damage. ',
      'The Blighter may choose to suffer 8 damage in order to use this spell as the second of the round.\nThe target of the blighter for his next damaging attack will deal 8 less damage. ',
      'The Blighter may choose to suffer 8 damage in order to use this spell as the second of the round.\nThe target of the blighter for his next damaging attack will deal 9 less damage. ',
      'The Blighter may choose to suffer 8 damage in order to use this spell as the second of the round.\nThe target of the blighter for his next damaging attack will deal 9 less damage. ',
    ],
    fragmentsToLevel: [0, 100, 150, 250, 350, 500, 700, 1000, 1500, 2200],
  },
  {
    path: 'Blighter',
    subpath: 'Ruinbearer',
    name: 'Sacrificial Surge',
    rarity: 'Blighter epic combat scroll',
    attackRoll: [
      '2d6',
      '2d6',
      '3d6',
      '3d6',
      '4d6',
      '4d6',
      '5d6',
      '5d6',
      '6d6',
      '6d6',
    ],
    defendingStat: 'Intuition',
    priority: [6, 8, 8, 8, 10, 10, 10, 12, 12, 12],
    damage: ['7', '10', '10', '13', '13', '16', '16', '19', '19', '21'],
    target: [
      'One enemy',
      'One enemy',
      'One enemy',
      'One enemy',
      'One enemy',
      'One enemy',
      'One enemy',
      'One enemy',
      'One enemy',
      'One enemy',
    ],
    keyword: 'None',
    duration: [
      'Instant',
      'Instant',
      'Instant',
      'Instant',
      'Instant',
      'Instant',
      'Instant',
      'Instant',
      'Instant',
      'Instant',

    ],
    durable: ['No', 'No', 'No', 'No', 'No', 'No', 'No', 'No', 'No', 'No'],
    description:
      'The Blighter mastered the technic to make his enemies more by channelling the suffering of his surround.',
    effects: [
      ' When this scroll successfully target an enemy, each ally (including the blighter) may sacrifice up to 2 hit points. The total amount of life sacrificed this way is add as damage to this spell. ',
      ' When this scroll successfully target an enemy, each ally (including the blighter) may sacrifice up to 2 hit points. The total amount of life sacrificed this way is add as damage to this spell. ',
      ' When this scroll successfully target an enemy, each ally (including the blighter) may sacrifice up to 2 hit points. The total amount of life sacrificed this way is add as damage to this spell. ',
      ' When this scroll successfully target an enemy, each ally (including the blighter) may sacrifice up to 2 hit points. The total amount of life sacrificed this way is add as damage to this spell. ',
      ' When this scroll successfully target an enemy, each ally (including the blighter) may sacrifice up to 3 hit points. The total amount of life sacrificed this way is add as damage to this spell. ',
      ' When this scroll successfully target an enemy, each ally (including the blighter) may sacrifice up to 3 hit points. The total amount of life sacrificed this way is add as damage to this spell. ',
      ' When this scroll successfully target an enemy, each ally (including the blighter) may sacrifice up to 3 hit points. The total amount of life sacrificed this way is add as damage to this spell. ',
      ' When this scroll successfully target an enemy, each ally (including the blighter) may sacrifice up to 3 hit points. The total amount of life sacrificed this way is add as damage to this spell. ',
      ' When this scroll successfully target an enemy, each ally (including the blighter) may sacrifice up to 3 hit points. The total amount of life sacrificed this way is add as damage to this spell. ',
      ' When this scroll successfully target an enemy, each ally (including the blighter) may sacrifice up to 3 hit points. The total amount of life sacrificed this way is add as damage to this spell. ',
    ],
    fragmentsToLevel: [0, 100, 150, 250, 350, 500, 700, 1000, 1500, 2200],
  },
  {
    path: 'Blighter',
    subpath: 'Ruinbearer',
    name: 'Bloodcast',
    rarity: 'Blighter epic combat scroll',
    attackRoll: [
      '2d6',
      '2d6',
      '3d6',
      '3d6',
      '4d6',
      '4d6',
      '5d6',
      '5d6',
      '6d6',
      '6d6',
    ],
    defendingStat: 'Intuition',
    priority: [1, 1, 1, 1, 1, 1, 1, 1, 1, 1],
    damage: ['4', '6', '6', '8', '8', '10', '10', '12', '12', '14'],
    target: [
      'One enemy',
      'One enemy',
      'One enemy',
      'One enemy',
      'One enemy',
      'One enemy',
      'One enemy',
      'One enemy',
      'One enemy',
      'One enemy',
    ],
    keyword: 'None',
    duration: [
      'Instant',
      'Instant',
      'Instant',
      'Instant',
      'Instant',
      'Instant',
      'Instant',
      'Instant',
      'Instant',
      'Instant',

    ],
    durable: ['No', 'No', 'No', 'No', 'No', 'No', 'No', 'No', 'No', 'No'],
    description:
      'The Blighter feels the suffering of his team, and this led him into blood lust',
    effects: [
      ' When the Blighter or an ally sacrifices life in order to cast a spell, the blighter may instantly cast again the bloodcast scroll. This might trigger only once each turn. (The extra cast of bloodcast does not count toward the limitation of scrolls per round.)',
      ' When the Blighter or an ally sacrifices life in order to cast a spell, the blighter may instantly cast again the bloodcast scroll. This might trigger only once each turn. (The extra cast of bloodcast does not count toward the limitation of scrolls per round.)',
      ' When the Blighter or an ally sacrifices life in order to cast a spell, the blighter may instantly cast again the bloodcast scroll. This might trigger only once each turn. (The extra cast of bloodcast does not count toward the limitation of scrolls per round.)',
      ' When the Blighter or an ally sacrifices life in order to cast a spell, the blighter may instantly cast again the bloodcast scroll. This might trigger only once each turn. (The extra cast of bloodcast does not count toward the limitation of scrolls per round.)',
      ' When the Blighter or an ally sacrifices life in order to cast a spell, the blighter may instantly cast again the bloodcast scroll. This might trigger only once each turn. (The extra cast of bloodcast does not count toward the limitation of scrolls per round.)',
      ' When the Blighter or an ally sacrifices life in order to cast a spell, the blighter may instantly cast again the bloodcast scroll. This might trigger only once each turn. (The extra cast of bloodcast does not count toward the limitation of scrolls per round.)',
      ' When the Blighter or an ally sacrifices life in order to cast a spell, the blighter may instantly cast again the bloodcast scroll. This might trigger only once each turn. (The extra cast of bloodcast does not count toward the limitation of scrolls per round.)',
      ' When the Blighter or an ally sacrifices life in order to cast a spell, the blighter may instantly cast again the bloodcast scroll. This might trigger only once each turn. (The extra cast of bloodcast does not count toward the limitation of scrolls per round.)',
      ' When the Blighter or an ally sacrifices life in order to cast a spell, the blighter may instantly cast again the bloodcast scroll. This might trigger only once each turn. (The extra cast of bloodcast does not count toward the limitation of scrolls per round.)',
      ' When the Blighter or an ally sacrifices life in order to cast a spell, the blighter may instantly cast again the bloodcast scroll. This might trigger only once each turn. (The extra cast of bloodcast does not count toward the limitation of scrolls per round.)',
    ],
    fragmentsToLevel: [0, 100, 150, 250, 350, 500, 700, 1000, 1500, 2200],
  },
  {
    path: 'Alchemist',
    subpath: '',
    name: 'Antitoxin',
    rarity: 'Alchemist rare combat scroll',
    attackRoll: [
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
    ],
    defendingStat: 'None',
    priority: [15, 17, 17, 19, 19, 21, 21, 23, 23, 25],
    damage: [
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
    ],
    target: [
      'One ally',
      'One ally',
      'One ally',
      'Two ally',
      'Two ally',
      'Two ally',
      'Two ally',
      'Three ally',
      'Three ally',
      'Three ally',
    ],
    keyword: 'Cleanse',
    duration: [
      'Instant',
      'Instant',
      'Instant',
      'Instant',
      'Instant',
      'Instant',
      'Instant',
      'Instant',
      'Instant',
      'Instant',
    ],
    durable: ['No', 'No', 'No', 'No', 'No', 'No', 'No', 'No', 'No', 'No'],
    description:
      'The Alchemist prepares an antidote elixir capable of curing any illness.',
    effects: [
      'The Alchemist’s target removes a negative effect. The Alchemist can use this Scroll even when he cannot act under normal circumstances.',
      'The Alchemist’s target removes a negative effect. The Alchemist can use this Scroll even when he cannot act under normal circumstances.',
      'The Alchemist’s target removes a negative effect. The Alchemist can use this Scroll even when he cannot act under normal circumstances.',
      'The Alchemist’s target removes a negative effect. The Alchemist can use this Scroll even when he cannot act under normal circumstances.',
      'The Alchemist’s target removes a negative effect. The Alchemist can use this Scroll even when he cannot act under normal circumstances.',
      'The Alchemist’s target removes a negative effect. The Alchemist can use this Scroll even when he cannot act under normal circumstances.',
      'The Alchemist’s target removes a negative effect. The Alchemist can use this Scroll even when he cannot act under normal circumstances.',
      'The Alchemist’s target removes a negative effect. The Alchemist can use this Scroll even when he cannot act under normal circumstances.',
      'The Alchemist’s target removes a negative effect. The Alchemist can use this Scroll even when he cannot act under normal circumstances.',
      'The Alchemist’s target removes a negative effect. The Alchemist can use this Scroll even when he cannot act under normal circumstances.',
    ],
    fragmentsToLevel: [0, 70, 120, 180, 250, 350, 500, 700, 1000, 1500],
  },
  {
    path: 'Alchemist',
    subpath: '',
    name: 'Dark Roast Brew',
    rarity: 'Alchemist epic combat scroll',
    attackRoll: [
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
    ],
    defendingStat: 'None',
    priority: [5, 7, 7, 7, 9, 9, 9, 11, 11, 11],
    damage: [
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
    ],
    target: [
      'One ally',
      'One ally',
      'One ally',
      'Two ally',
      'Two ally',
      'Two ally',
      'Two ally',
      'Three ally',
      'Three ally',
      'Three ally',
    ],
    keyword: 'Replenish',
    duration: [
      'Instant',
      'Instant',
      'Instant',
      'Instant',
      'Instant',
      'Instant',
      'Instant',
      'Instant',
      'Instant',
      'Instant',
    ],
    durable: ['No', 'No', 'No', 'No', 'No', 'No', 'No', 'No', 'No', 'No'],
    description:
      'The Alchemist hurries and administers a double dose of his secret elixir to his target, which rejuvenates the target and allows them to move with newfound energy',
    effects: [
      'The Alchemist’s target can reuse an offensive combat scroll that has been expended. Furthermore, when they select to use this scroll, it gains +6 priority.',
      'The Alchemist’s target can reuse an offensive combat scroll that has been expended. Furthermore, when they select to use this scroll, it gains +6 priority.',
      'The Alchemist’s target can reuse an offensive combat scroll that has been expended. Furthermore, when they select to use this scroll, it gains +7 priority.',
      'The Alchemist’s target can reuse an offensive combat scroll that has been expended. Furthermore, when they select to use this scroll, it gains +7 priority.',
      'The Alchemist’s target can reuse an offensive combat scroll that has been expended. Furthermore, when they select to use this scroll, it gains +8 priority.',
      'The Alchemist’s target can reuse an offensive combat scroll that has been expended. Furthermore, when they select to use this scroll, it gains +8 priority.',
      'The Alchemist’s target can reuse an offensive combat scroll that has been expended. Furthermore, when they select to use this scroll, it gains +9 priority.',
      'The Alchemist’s target can reuse an offensive combat scroll that has been expended. Furthermore, when they select to use this scroll, it gains +9 priority.',
      'The Alchemist’s target can reuse an offensive combat scroll that has been expended. Furthermore, when they select to use this scroll, it gains +10 priority.',
      'The Alchemist’s target can reuse an offensive combat scroll that has been expended. Furthermore, when they select to use this scroll, it gains +10 priority.',
    ],
    fragmentsToLevel: [0, 100, 150, 250, 350, 500, 700, 1000, 1500, 2200],
  },
  {
    path: 'Alchemist',
    subpath: '',
    name: 'Elixir of Alacrity',
    rarity: 'Alchemist epic combat scroll',
    attackRoll: [
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
    ],
    defendingStat: 'None',
    priority: [2, 4, 4, 4, 6, 6, 6, 8, 8, 8],
    damage: [
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
    ],
    target: [
      'Two ally',
      'Two ally',
      'Three ally',
      'Three ally',
      'Three ally',
      'Four ally',
      'Four ally',
      'Four ally',
      'Five ally',
      'Five ally',
    ],
    keyword: 'Priority Buff',
    duration: [
      'Two rounds',
      'Two rounds',
      'Two rounds',
      'Two rounds',
      'Two rounds',
      'Three rounds',
      'Three rounds',
      'Three rounds',
      'Three rounds',
      'Three rounds',
    ],
    durable: [
      'Yes',
      'Yes',
      'Yes',
      'Yes',
      'Yes',
      'Yes',
      'Yes',
      'Yes',
      'Yes',
      'Yes',
    ],
    description:
      'Anyone who drinks this elixir by the Alchemist grows wingsss as they observe their movements and reflexes accelerate.',
    effects: [
      'The targeted allies receive +9 priority for the duration of the scroll',
      'The targeted allies receive +9 priority for the duration of the scroll',
      'The targeted allies receive +10 priority for the duration of the scroll',
      'The targeted allies receive +10 priority for the duration of the scroll',
      'The targeted allies receive +11 priority for the duration of the scroll',
      'The targeted allies receive +11 priority for the duration of the scroll',
      'The targeted allies receive +12 priority for the duration of the scroll',
      'The targeted allies receive +12 priority for the duration of the scroll',
      'The targeted allies receive +13 priority for the duration of the scroll',
      'The targeted allies receive +13 priority for the duration of the scroll',
    ],
    fragmentsToLevel: [0, 100, 150, 250, 350, 500, 700, 1000, 1500, 2200],
  },
  {
    path: 'Alchemist',
    subpath: '',
    name: 'Emptying the Backpack',
    rarity: 'Alchemist rare combat scroll',
    attackRoll: [
      '3d6',
      '3d6',
      '4d6',
      '4d6',
      '6d6',
      '6d6',
      '7d6',
      '7d6',
      '8d6',
      '8d6',
    ],
    defendingStat: 'Agility',
    priority: [10, 12, 12, 14, 14, 16, 16, 18, 18, 20],
    damage: ['5', '7', '7', '9', '9', '12', '12', '15', '15', '17'],
    target: [
      'One enemy',
      'One enemy',
      'One enemy',
      'One enemy',
      'One enemy',
      'One enemy',
      'One enemy',
      'One enemy',
      'One enemy',
      'One enemy',
    ],
    keyword: 'None',
    duration: [
      'Instant',
      'Instant',
      'Instant',
      'Instant',
      'Instant',
      'Instant',
      'Instant',
      'Instant',
      'Instant',
      'Instant',
    ],
    durable: ['No', 'No', 'No', 'No', 'No', 'No', 'No', 'No', 'No', 'No'],
    description:
      'The Alchemist isn’t specialized in attacks. However, when called upon to face challenges, he haphazardly launches random objects from his bag towards his adversary. Something is bound to harm them in the process…',
    effects: [
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
    ],
    fragmentsToLevel: [0, 70, 120, 180, 250, 350, 500, 700, 1000, 1500],
  },
  {
    path: 'Alchemist',
    subpath: '',
    name: 'Explosive Concoction',
    rarity: 'Alchemist rare combat scroll',
    attackRoll: [
      '2d6',
      '2d6',
      '3d6',
      '3d6',
      '4d6',
      '4d6',
      '5d6',
      '5d6',
      '6d6',
      '6d6',
    ],
    defendingStat: 'Agility',
    priority: [4, 6, 6, 6, 8, 8, 8, 10, 10, 10],
    damage: ['5', '7', '7', '9', '9', '12', '12', '15', '15', '17'],
    target: [
      'Two enemy',
      'Two enemy',
      'Two enemy',
      'Three enemy',
      'Three enemy',
      'Three enemy',
      'Four enemy',
      'Four enemy',
      'Four enemy',
      'Five enemy',
    ],
    keyword: 'None',
    duration: [
      'Instant',
      'Instant',
      'Instant',
      'Instant',
      'Instant',
      'Instant',
      'Instant',
      'Instant',
      'Instant',
      'Instant',
    ],
    durable: ['No', 'No', 'No', 'No', 'No', 'No', 'No', 'No', 'No', 'No'],
    description:
      'Explosions are one of the favorite pastimes of Alchemists. Everyone must exercise great caution around the unlabeled vials that can be found in their bags.',
    effects: [
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
    ],
    fragmentsToLevel: [0, 70, 120, 180, 250, 350, 500, 700, 1000, 1500],
  },
  {
    path: 'Alchemist',
    subpath: '',
    name: 'Mind Shielding Tonic',
    rarity: 'Alchemist rare combat scroll',
    attackRoll: [
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
    ],
    defendingStat: 'None',
    priority: [11, 13, 13, 15, 15, 17, 17, 19, 19, 21],
    damage: [
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
    ],
    target: [
      'One ally',
      'One ally',
      'One ally',
      'Two ally',
      'Two ally',
      'Two ally',
      'Two ally',
      'Three ally',
      'Three ally',
      'Three ally',
    ],
    keyword: 'Intuition Buff, Damage Reduction',
    duration: [
      'Instant',
      'Instant',
      'Instant',
      'Instant',
      'Instant',
      'Instant',
      'Instant',
      'Instant',
      'Instant',
      'Instant',
    ],
    durable: [
      'Yes',
      'Yes',
      'Yes',
      'Yes',
      'Yes',
      'Yes',
      'Yes',
      'Yes',
      'Yes',
      'Yes',
    ],
    description:
      'Anyone who consumes this potion observes his mental defenses sharpening',
    effects: [
      'The target receives +1d6 Intuition defense and 3 damage reduction.',
      'The target receives +1d6 Intuition defense and 3 damage reduction.',
      'The target receives +1d6 Intuition defense and 3 damage reduction.',
      'The target receives +2d6 Intuition defense and 3 damage reduction.',
      'The target receives +2d6 Intuition defense and 3 damage reduction.',
      'The target receives +2d6 Intuition defense and 3 damage reduction.',
      'The target receives +3d6 Intuition defense and 3 damage reduction.',
      'The target receives +3d6 Intuition defense and 3 damage reduction.',
      'The target receives +3d6 Intuition defense and 3 damage reduction.',
      'The target receives +4d6 Intuition defense and 3 damage reduction.',
    ],
    fragmentsToLevel: [0, 70, 120, 180, 250, 350, 500, 700, 1000, 1500],
  },
  {
    path: 'Alchemist',
    subpath: '',
    name: 'Potion of Fortitude',
    rarity: 'Alchemist rare combat scroll',
    attackRoll: [
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
    ],
    defendingStat: 'None',
    priority: [2, 4, 4, 4, 6, 6, 6, 8, 8, 8],
    damage: [
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
    ],
    target: [
      'One ally',
      'One ally',
      'One ally',
      'One ally',
      'One ally',
      'Two ally',
      'Two ally',
      'Two ally',
      'Two ally',
      'Two ally',
    ],
    keyword: 'Temporary hit points',
    duration: [
      'Two rounds',
      'Two rounds',
      'Two rounds',
      'Three rounds',
      'Three rounds',
      'Three rounds',
      'Four rounds',
      'Four rounds',
      'Four rounds',
      'Four rounds',
    ],
    durable: ['No', 'No', 'No', 'No', 'No', 'No', 'No', 'No', 'No', 'No'],
    description:
      'Anyone who consumes this potion by the Alchemist sees their wounds rapidly healing and their willpower soaring.',
    effects: [
      'The target receives 13 temporary hit points',
      'The target receives 13 temporary hit points',
      'The target receives 17 temporary hit points',
      'The target receives 17 temporary hit points',
      'The target receives 21 temporary hit points',
      'The target receives 21 temporary hit points',
      'The target receives 25 temporary hit points',
      'The target receives 25 temporary hit points',
      'The target receives 29 temporary hit points',
      'The target receives 29 temporary hit points',
    ],
    fragmentsToLevel: [0, 70, 120, 180, 250, 350, 500, 700, 1000, 1500],
  },
  {
    path: 'Alchemist',
    subpath: '',
    name: 'Quick Brew',
    rarity: 'Alchemist epic combat scroll',
    attackRoll: [
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
    ],
    defendingStat: 'None',
    priority: [21, 23, 23, 25, 25, 27, 27, 29, 29, 31],
    damage: [
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
    ],
    target: [
      'Self',
      'Self',
      'Self',
      'Self',
      'Self',
      'Self',
      'Self',
      'Self',
      'Self',
      'Self',
    ],
    keyword: 'Priority Buff',
    duration: [
      'Two rounds',
      'Two rounds',
      'Two rounds',
      'Two rounds',
      'Two rounds',
      'Three rounds',
      'Three rounds',
      'Three rounds',
      'Three rounds',
      'Three rounds',
    ],
    durable: ['No', 'No', 'No', 'No', 'No', 'No', 'No', 'No', 'No', 'No'],
    description:
      'The Alchemist hastily mixes various herbs and potions in the midst of battle, hoping to create something helpful for the situation.',
    effects: [
      'The Alchemist creates a random minor consumable potion that he  can consume on the spot if he wishes. The minor consumable potion does not count towards the limit of consumables he can carry and becomes inert at the end of the battle. The storyteller rolls a six-sided die, and depending on the result, offers the minor consumable potion to the Alchemist.',
      'The Alchemist creates a random minor consumable potion that he  can consume on the spot if he wishes. The minor consumable potion does not count towards the limit of consumables he can carry and becomes inert at the end of the battle. The storyteller rolls a six-sided die, and depending on the result, offers the minor consumable potion to the Alchemist.',
      'The Alchemist creates a random minor consumable potion that he  can consume on the spot if he wishes. The minor consumable potion does not count towards the limit of consumables he can carry and becomes inert at the end of the battle. The storyteller rolls a six-sided die, and depending on the result, offers the minor consumable potion to the Alchemist.',
      'The Alchemist creates two random minor consumable potions that he  can consume on the spot if he wishes. The minor consumable potion does not count towards the limit of consumables he can carry and becomes inert at the end of the battle. The storyteller rolls two six-sided die, and depending on the result, offers the minor consumable potion to the Alchemist.',
      'The Alchemist creates two random minor consumable potions that he  can consume on the spot if he wishes. The minor consumable potion does not count towards the limit of consumables he can carry and becomes inert at the end of the battle. The storyteller rolls two six-sided die, and depending on the result, offers the minor consumable potion to the Alchemist.',
      'The Alchemist creates two random minor consumable potions that he  can consume on the spot if he wishes. The minor consumable potion does not count towards the limit of consumables he can carry and becomes inert at the end of the battle. The storyteller rolls two six-sided die, and depending on the result, offers the minor consumable potion to the Alchemist.',
      'The Alchemist creates three random minor consumable potions that he  can consume on the spot if he wishes. The minor consumable potion does not count towards the limit of consumables he can carry and becomes inert at the end of the battle. The storyteller rolls three six-sided die, and depending on the result, offers the minor consumable potion to the Alchemist.',
      'The Alchemist creates three random minor consumable potions that he  can consume on the spot if he wishes. The minor consumable potion does not count towards the limit of consumables he can carry and becomes inert at the end of the battle. The storyteller rolls three six-sided die, and depending on the result, offers the minor consumable potion to the Alchemist.',
      'The Alchemist creates three random minor consumable potions that he  can consume on the spot if he wishes. The minor consumable potion does not count towards the limit of consumables he can carry and becomes inert at the end of the battle. The storyteller rolls three six-sided die, and depending on the result, offers the minor consumable potion to the Alchemist.',
      'The Alchemist creates four random minor consumable potions that he  can consume on the spot if he wishes. The minor consumable potion does not count towards the limit of consumables he can carry and becomes inert at the end of the battle. The storyteller rolls four six-sided die, and depending on the result, offers the minor consumable potion to the Alchemist.',
    ],
    fragmentsToLevel: [0, 100, 150, 250, 350, 500, 700, 1000, 1500, 2200],
  },
  {
    path: 'Alchemist',
    subpath: '',
    name: 'Smoke Bomb',
    rarity: 'Alchemist rare combat scroll',
    attackRoll: [
      '2d6',
      '2d6',
      '3d6',
      '3d6',
      '4d6',
      '4d6',
      '5d6',
      '5d6',
      '6d6',
      '6d6',
    ],
    defendingStat: 'Agility',
    priority: [
      'Interrupt',
      'Interrupt',
      'Interrupt',
      'Interrupt',
      'Interrupt',
      'Interrupt',
      'Interrupt',
      'Interrupt',
      'Interrupt',
      'Interrupt',
    ],
    damage: [
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
    ],
    target: [
      'One enemy',
      'One enemy',
      'One enemy',
      'One enemy',
      'One enemy',
      'One enemy',
      'One enemy',
      'One enemy',
      'One enemy',
      'One enemy',
    ],
    keyword: 'None',
    duration: [
      'Instant',
      'Instant',
      'Instant',
      'Instant',
      'Instant',
      'Instant',
      'Instant',
      'Instant',
      'Instant',
      'Instant',
    ],
    durable: ['No', 'No', 'No', 'No', 'No', 'No', 'No', 'No', 'No', 'No'],
    description:
      'This bomb, created from a mixture of nitroglycerin, sulfur, and mercury, produces dense clouds of black smoke that obstruct the vision of the attacker.',
    effects: [
      'This scroll activates automatically when an enemy chooses a target to attack. If this scroll succeeds, the enemy receives -2 to his attack.This scroll does not count to the turn allotment and can be used in addition to the one scroll per turn rule.',
      'This scroll activates automatically when an enemy chooses a target to attack. If this scroll succeeds, the enemy receives -3 to his attack.This scroll does not count to the turn allotment and can be used in addition to the one scroll per turn rule.',
      'This scroll activates automatically when an enemy chooses a target to attack. If this scroll succeeds, the enemy receives -3 to his attack.This scroll does not count to the turn allotment and can be used in addition to the one scroll per turn rule.',
      'This scroll activates automatically when an enemy chooses a target to attack. If this scroll succeeds, the enemy receives -4 to his attack.This scroll does not count to the turn allotment and can be used in addition to the one scroll per turn rule.',
      'This scroll activates automatically when an enemy chooses a target to attack. If this scroll succeeds, the enemy receives -4 to his attack.This scroll does not count to the turn allotment and can be used in addition to the one scroll per turn rule.',
      'This scroll activates automatically when an enemy chooses a target to attack. If this scroll succeeds, the enemy receives -5 to his attack.This scroll does not count to the turn allotment and can be used in addition to the one scroll per turn rule.',
      'This scroll activates automatically when an enemy chooses a target to attack. If this scroll succeeds, the enemy receives -5 to his attack.This scroll does not count to the turn allotment and can be used in addition to the one scroll per turn rule.',
      'This scroll activates automatically when an enemy chooses a target to attack. If this scroll succeeds, the enemy receives -6 to his attack.This scroll does not count to the turn allotment and can be used in addition to the one scroll per turn rule.',
      'This scroll activates automatically when an enemy chooses a target to attack. If this scroll succeeds, the enemy receives -6 to his attack.This scroll does not count to the turn allotment and can be used in addition to the one scroll per turn rule.',
      'This scroll activates automatically when an enemy chooses a target to attack. If this scroll succeeds, the enemy receives -7 to his attack.This scroll does not count to the turn allotment and can be used in addition to the one scroll per turn rule.',
    ],
    fragmentsToLevel: [0, 70, 120, 180, 250, 350, 500, 700, 1000, 1500],
  },
  {
    path: 'Alchemist',
    subpath: '',
    name: 'Stoneskin Lotion',
    rarity: 'Alchemist rare combat scroll',
    attackRoll: [
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
    ],
    defendingStat: 'None',
    priority: [11, 13, 13, 15, 15, 17, 17, 19, 19, 21],
    damage: [
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
    ],
    target: [
      'One ally',
      'One ally',
      'One ally',
      'One ally',
      'One ally',
      'Two ally',
      'Two ally',
      'Two ally',
      'Two ally',
      'Two ally',
    ],
    keyword: 'Buff agility, Damage Reduction',
    duration: [
      'Two rounds',
      'Two rounds',
      'Two rounds',
      'Three rounds',
      'Three rounds',
      'Three rounds',
      'Four rounds',
      'Four rounds',
      'Four rounds',
      'Four rounds',
    ],
    durable: [
      'Yes',
      'Yes',
      'Yes',
      'Yes',
      'Yes',
      'Yes',
      'Yes',
      'Yes',
      'Yes',
      'Yes',
    ],
    description:
      'Anyone who applies this ointment by the Alchemist to their body sees their skin hardening like stone while remaining just as flexible',
    effects: [
      'The target receives +1d6 Agility defense and 3 damage reduction',
      'The target receives +1d6 Agility defense and 4 damage reduction',
      'The target receives +1d6 Agility defense and 4 damage reduction',
      'The target receives +2d6 Agility defense and 5 damage reduction',
      'The target receives +2d6 Agility defense and 5 damage reduction',
      'The target receives +2d6 Agility defense and 6 damage reduction',
      'The target receives +3d6 Agility defense and 6 damage reduction',
      'The target receives +3d6 Agility defense and 7 damage reduction',
      'The target receives +3d6 Agility defense and 7 damage reduction',
      'The target receives +4d6 Agility defense and 8 damage reduction',
    ],
    fragmentsToLevel: [0, 70, 120, 180, 250, 350, 500, 700, 1000, 1500],
  },
  {
    path: 'Alchemist',
    subpath: 'Apothecary',
    name: 'Brew Artisan',
    rarity: 'Alchemist epic combat scroll',
    attackRoll: [
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
    ],
    defendingStat: 'None',
    priority: ['None', 'None', 'None', 'None', 'None', 'None', 'None', 'None', 'None', 'None'],
    damage: [
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
    ],
    target: [
      'One Ally',
      'One Ally',
      'One Ally',
      'One Ally',
      'One Ally',
      'One Ally',
      'One Ally',
      'One Ally',
      'One Ally',
      'One Ally',
    ],
    keyword: 'Passive',
    duration: [
      'Instant',
      'Instant',
      'Instant',
      'Instant',
      'Instant',
      'Instant',
      'Instant',
      'Instant',
      'Instant',
      'Instant',
    ],
    durable: ['Yes', 'Yes', 'Yes', 'Yes', 'Yes', 'Yes', 'Yes', 'Yes', 'Yes', 'Yes'],
    description:
      'The Apothecary has mastered the craft of potent use of the ichor in order to by-pass the normal uses and durations of the potions.',
    effects: [
      'Whenever an ally or the Apothecary uses on potion, the Apothecary can double the effect of the potion. (This ability can only be used once per round).This scroll does not count towards the number of scrolls you can cast in the round.',
      'Whenever an ally or the Apothecary uses on potion, the Apothecary can double the effect of the potion. (This ability can only be used once per round).This scroll does not count towards the number of scrolls you can cast in the round.',
      'Whenever an ally or the Apothecary uses on potion, the Apothecary can double the effect of the potion. (This ability can only be used once per round).This scroll does not count towards the number of scrolls you can cast in the round.',
      'Whenever an ally or the Apothecary uses on potion, the Apothecary can double the effect of the potion. (This ability can only be used once per round).This scroll does not count towards the number of scrolls you can cast in the round.',
      'Whenever an ally or the Apothecary uses on potion, the Apothecary can double the effect of the potion. (This ability can only be used Twice per round).This scroll does not count towards the number of scrolls you can cast in the round.',
      'Whenever an ally or the Apothecary uses on potion, the Apothecary can double the effect of the potion. (This ability can only be used Twice per round).This scroll does not count towards the number of scrolls you can cast in the round.',
      'Whenever an ally or the Apothecary uses on potion, the Apothecary can double the effect of the potion. (This ability can only be used Twice per round).This scroll does not count towards the number of scrolls you can cast in the round.',
      'Whenever an ally or the Apothecary uses on potion, the Apothecary can double the effect of the potion. (This ability can only be used Twice per round).This scroll does not count towards the number of scrolls you can cast in the round.',
      'Whenever an ally or the Apothecary uses on potion, the Apothecary can double the effect of the potion. (This ability can only be used Twice per round).This scroll does not count towards the number of scrolls you can cast in the round.',
      'Whenever an ally or the Apothecary uses on potion, the Apothecary can double the effect of the potion. (This ability can only be used Twice per round).This scroll does not count towards the number of scrolls you can cast in the round.',
    ],
    fragmentsToLevel: [0, 100, 150, 250, 350, 500, 700, 1000, 1500, 2200],
  },
  {
    path: 'Alchemist',
    subpath: 'Apothecary',
    name: 'Potion of Shadow Veil',
    rarity: 'Alchemist epic combat scroll',
    attackRoll: [
      '2d6',
      '2d6',
      '3d6',
      '3d6',
      '4d6',
      '4d6',
      '5d6',
      '5d6',
      '6d6',
      '6d6',
    ],
    defendingStat: 'Intuition',
    priority: [2, 4, 4, 4, 6, 6, 6, 8, 8, 8],
    damage: [
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
    ],
    target: [
      'One enemy',
      'One enemy',
      'One enemy',
      'One enemy',
      'One enemy',
      'One enemy',
      'One enemy',
      'One enemy',
      'One enemy',
      'One enemy',
    ],
    keyword: 'Passive , Stun',
    duration: [
      'Instant',
      'Instant',
      'Instant',
      'Instant',
      'Instant',
      'Instant',
      'Instant',
      'Instant',
      'Instant',
      'Instant',
    ],
    durable: ['No', 'No', 'No', 'No', 'No', 'No', 'No', 'No', 'No', 'No'],
    description:
      'he Apothecary has mastered the craft of potent use of the ichor in order to by-pass the normal uses and durations of the potions. ',
    effects: [
      'The Apothecary uses this potion in order to stop his opponent next action.',
      'The Apothecary uses this potion in order to stop his opponent next action.',
      'The Apothecary uses this potion in order to stop his opponent next 2 actions.',
      'The Apothecary uses this potion in order to stop his opponent next 2 actions.',
      'The Apothecary uses this potion in order to stop his opponent next 2 actions.',
      'The Apothecary uses this potion in order to stop his opponent next 2 actions.',
      'The Apothecary uses this potion in order to stop his opponent next 3 actions.',
      'The Apothecary uses this potion in order to stop his opponent next 3 actions.',
      'The Apothecary uses this potion in order to stop his opponent next 3 actions.',
      'The Apothecary uses this potion in order to stop his opponent next 4 actions.',
    ],
    fragmentsToLevel: [0, 100, 150, 250, 350, 500, 700, 1000, 1500, 2200],
  },
  {
    path: 'Alchemist',
    subpath: 'Elixir Artisan',
    name: 'Potion of Shadow Veil',
    rarity: 'Alchemist epic combat scroll',
    attackRoll: [
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
    ],
    defendingStat: 'None',
    priority: ['None', 'None', 'None', 'None', 'None', 'None', 'None', 'None', 'None', 'None'],
    damage: [
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
    ],
    target: [
      'One Ally',
      'One Ally',
      'One Ally',
      'One Ally',
      'One Ally',
      'One Ally',
      'One Ally',
      'One Ally',
      'One Ally',
      'One Ally',
    ],
    keyword: 'Passive',
    duration: [
      'Instant',
      'Instant',
      'Instant',
      'Instant',
      'Instant',
      'Instant',
      'Instant',
      'Instant',
      'Instant',
      'Instant',
    ],
    durable: ['No', 'No', 'No', 'No', 'No', 'No', 'No', 'No', 'No', 'No'],
    description:
      'The Elixir Artisan has mastered the craft of potent use of the ichor in order to by-pass the normal uses and durations of the potions. ',
    effects: [
      'Whenever an ally uses on potion, the Elixir Artisan can add 1 more round on the duration of the potion. (This ability can only be used if the potion has duration 1 round and more)',
      'Whenever an ally uses on potion, the Elixir Artisan can add 1 more round on the duration of the potion. (This ability can only be used if the potion has duration 1 round and more)',
      'Whenever an ally uses on potion, the Elixir Artisan can add 2 more round on the duration of the potion. (This ability can only be used if the potion has duration 1 round and more)',
      'Whenever an ally uses on potion, the Elixir Artisan can add 2 more round on the duration of the potion. (This ability can only be used if the potion has duration 1 round and more)',
      'Whenever an ally uses on potion, the Elixir Artisan can add 2 more round on the duration of the potion. (This ability can only be used if the potion has duration 1 round and more)',
      'Whenever an ally uses on potion, the Elixir Artisan can add 3 more round on the duration of the potion. (This ability can only be used if the potion has duration 1 round and more)',
      'Whenever an ally uses on potion, the Elixir Artisan can add 3 more round on the duration of the potion. (This ability can only be used if the potion has duration 1 round and more)',
      'Whenever an ally uses on potion, the Elixir Artisan can add 3 more round on the duration of the potion. (This ability can only be used if the potion has duration 1 round and more)',
      'Whenever an ally uses on potion, the Elixir Artisan can add 4 more round on the duration of the potion. (This ability can only be used if the potion has duration 1 round and more)',
      'Whenever an ally uses on potion, the Elixir Artisan can add 4 more round on the duration of the potion. (This ability can only be used if the potion has duration 1 round and more)',
    ],
    fragmentsToLevel: [0, 100, 150, 250, 350, 500, 700, 1000, 1500, 2200],
  },
  {
    path: 'Alchemist',
    subpath: 'Elixir Artisan',
    name: 'Potion of Shadow Veil',
    rarity: 'Alchemist epic combat scroll',
    attackRoll: [
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
    ],
    defendingStat: 'Agility',
    priority: [2, 4, 4, 4, 6, 6, 6, 8, 8, 8],
    damage: [
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
    ],
    target: [
      'One Ally',
      'One Ally',
      'One Ally',
      'One Ally',
      'One Ally',
      'One Ally',
      'One Ally',
      'One Ally',
      'One Ally',
      'One Ally',
    ],
    keyword: 'Passive',
    duration: [
      'Two rounds',
      'Two rounds',
      'Two rounds',
      'Two rounds',
      'Two rounds',
      'Three rounds',
      'Three rounds',
      'Three rounds',
      'Three rounds',
      'Three rounds',

    ],
    durable: ['No', 'No', 'No', 'No', 'No', 'No', 'No', 'No', 'No', 'No'],
    description:
      'The Elixir Artisan uses all his crafts in order to save his allies from harms way. ',
    effects: [
      'The Target of the scroll cannot be targeted by any action for the duration. If a scroll or an attack says “all allies or all enemies” then he is getting affected. All buffs and debuffs that are already is placed on the target still works as intended.',
      'The Target of the scroll cannot be targeted by any action for the duration. If a scroll or an attack says “all allies or all enemies” then he is getting affected. All buffs and debuffs that are already is placed on the target still works as intended.',
      'The Target of the scroll cannot be targeted by any action for the duration. If a scroll or an attack says “all allies or all enemies” then he is getting affected. All buffs and debuffs that are already is placed on the target still works as intended.',
      'The Target of the scroll cannot be targeted by any action for the duration. If a scroll or an attack says “all allies or all enemies” then he is getting affected. All buffs and debuffs that are already is placed on the target still works as intended.',
      'The Target of the scroll cannot be targeted by any action for the duration. If a scroll or an attack says “all allies or all enemies” then he is getting affected. All buffs and debuffs that are already is placed on the target still works as intended.',
      'The Target of the scroll cannot be targeted by any action for the duration. If a scroll or an attack says “all allies or all enemies” then he is getting affected. All buffs and debuffs that are already is placed on the target still works as intended.',
      'The Target of the scroll cannot be targeted by any action for the duration. If a scroll or an attack says “all allies or all enemies” then he is getting affected. All buffs and debuffs that are already is placed on the target still works as intended.',
      'The Target of the scroll cannot be targeted by any action for the duration. If a scroll or an attack says “all allies or all enemies” then he is getting affected. All buffs and debuffs that are already is placed on the target still works as intended.',
      'The Target of the scroll cannot be targeted by any action for the duration. If a scroll or an attack says “all allies or all enemies” then he is getting affected. All buffs and debuffs that are already is placed on the target still works as intended.',
      'The Target of the scroll cannot be targeted by any action for the duration. If a scroll or an attack says “all allies or all enemies” then he is getting affected. All buffs and debuffs that are already is placed on the target still works as intended.',
    ],
    fragmentsToLevel: [0, 100, 150, 250, 350, 500, 700, 1000, 1500, 2200],
  },
  {
    path: 'Clockwork Shaman',
    subpath: '',
    name: 'Steam Surge',
    rarity: 'Clockwork Shaman rare combat scroll',
    attackRoll: [
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
    ],
    defendingStat: 'None',
    priority: [15, 17, 17, 19, 19, 21, 21, 23, 23, 25],
    damage: [
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
    ],
    target: [
      'Self',
      'Self',
      'Self',
      'Self',
      'Self',
      'Self',
      'Self',
      'Self',
      'Self',
      'Self',
    ],
    keyword: 'None',
    duration: [
      'Two rounds',
      'Two rounds',
      'Two rounds',
      'Three rounds',
      'Three rounds',
      'Three rounds',
      'Four rounds',
      'Four rounds',
      'Four rounds',
      'Four rounds',
    ],
    durable: [
      'Yes',
      'Yes',
      'Yes',
      'Yes',
      'Yes',
      'Yes',
      'Yes',
      'Yes',
      'Yes',
      'Yes',
    ],
    description:
      'Anyone who applies this ointment by the Alchemist to their body sees their skin hardening like stone while remaining just as flexible',
    effects: [
      'The Clockwork Shaman gains 2 counterattack and 3 overcharge stacks.',
      'The Clockwork Shaman gains 2 counterattack and 3 overcharge stacks.',
      'The Clockwork Shaman gains 2 counterattack and 4 overcharge stacks.',
      'The Clockwork Shaman gains 3 counterattack and 4 overcharge stacks.',
      'The Clockwork Shaman gains 3 counterattack and 4 overcharge stacks.',
      'The Clockwork Shaman gains 3 counterattack and 5 overcharge stacks.',
      'The Clockwork Shaman gains 4 counterattack and 5 overcharge stacks.',
      'The Clockwork Shaman gains 4 counterattack and 5 overcharge stacks.',
      'The Clockwork Shaman gains 4 counterattack and 6 overcharge stacks.',
      'The Clockwork Shaman gains 5 counterattack and 6 overcharge stacks.',
    ],
    fragmentsToLevel: [0, 70, 120, 180, 250, 350, 500, 700, 1000, 1500],
  },
  {
    path: 'Clockwork Shaman',
    subpath: '',
    name: 'Boiler Burst',
    rarity: 'Clockwork Shaman rare combat scroll',
    attackRoll: [
      '3d6',
      '3d6',
      '4d6',
      '4d6',
      '6d6',
      '6d6',
      '7d6',
      '7d6',
      '8d6',
      '8d6',
    ],

    defendingStat: 'None',
    priority: [15, 17, 17, 19, 19, 21, 21, 23, 23, 25],
    damage: ['5', '7', '7', '9', '9', '12', '12', '15', '15', '17'],
    target: [
      'One enemy',
      'One enemy',
      'One enemy',
      'One enemy',
      'One enemy',
      'One enemy',
      'One enemy',
      'One enemy',
      'One enemy',
      'One enemy',
    ],

    keyword: 'None',
    duration: [
      'Instant',
      'Instant',
      'Instant',
      'Instant',
      'Instant',
      'Instant',
      'Instant',
      'Instant',
      'Instant',
      'Instant',
    ],
    durable: ['No', 'No', 'No', 'No', 'No', 'No', 'No', 'No', 'No', 'No'],
    description:
      'The Clockwork Shaman increases the temperature and pressure within the hand cannon he carries, releasing a burst of fire and steam that envelops his target.',
    effects: [
      'This attack adds an additional overcharge stack upon success.',
      'This attack adds an additional overcharge stack upon success.',
      'This attack adds an additional overcharge stack upon success.',
      'This attack adds an additional overcharge stack upon success.',
      'This attack adds an additional overcharge stack upon success.',
      'This attack two an additional overcharge stack upon success.',
      'This attack two an additional overcharge stack upon success.',
      'This attack two an additional overcharge stack upon success.',
      'This attack two an additional overcharge stack upon success.',
      'This attack two an additional overcharge stack upon success.',
    ],
    fragmentsToLevel: [0, 70, 120, 180, 250, 350, 500, 700, 1000, 1500],
  },
  {
    path: 'Clockwork Shaman',
    subpath: '',
    name: 'Thermodynamic Reasonance',
    rarity: 'Clockwork Shaman rare combat scroll',
    attackRoll: [
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
    ],
    defendingStat: 'None',
    priority: [18, 21, 21, 23, 23, 25, 25, 27, 27, 29],
    damage: [
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
    ],
    target: [
      'One enemy',
      'One enemy',
      'One enemy',
      'One enemy',
      'One enemy',
      'One enemy',
      'One enemy',
      'One enemy',
      'One enemy',
      'One enemy',
    ],

    keyword: 'None',
    duration: [
      'One rounds',
      'One rounds',
      'One rounds',
      'One rounds',
      'One rounds',
      'Two rounds',
      'Two rounds',
      'Two rounds',
      'Two rounds',
      'Two rounds',
    ],
    durable: ['No', 'No', 'No', 'No', 'No', 'No', 'No', 'No', 'No', 'No'],
    description:
      'The Clockwork Shaman unleashes thermodynamic pulses from his mechanisms, capable of halting the attacker’s aggressive momentum.',
    effects: [
      'Enemy’s next attack must target the Clockwork Shaman. Clockwork Shaman gains 3 damage reduction for this attack. If the Clockwork Shaman takes damage from the target’s next attack, he receives an additional overcharge stack.',
      'Enemy’s next attack must target the Clockwork Shaman. Clockwork Shaman gains 3 damage reduction for this attack. If the Clockwork Shaman takes damage from the target’s next attack, he receives an additional overcharge stack.',
      'Enemy’s next attack must target the Clockwork Shaman. Clockwork Shaman gains 4 damage reduction for this attack. If the Clockwork Shaman takes damage from the target’s next attack, he receives an additional overcharge stack.',
      'Enemy’s next attack must target the Clockwork Shaman. Clockwork Shaman gains 4 damage reduction for this attack. If the Clockwork Shaman takes damage from the target’s next attack, he receives an additional overcharge stack.',
      'Enemy’s next attack must target the Clockwork Shaman. Clockwork Shaman gains 5 damage reduction for this attack. If the Clockwork Shaman takes damage from the target’s next attack, he receives an additional overcharge stack.',
      'Enemy’s next attack must target the Clockwork Shaman. Clockwork Shaman gains 5 damage reduction for this attack. If the Clockwork Shaman takes damage from the target’s next attack, he receives an additional overcharge stack.',
      'Enemy’s next attack must target the Clockwork Shaman. Clockwork Shaman gains 6 damage reduction for this attack. If the Clockwork Shaman takes damage from the target’s next attack, he receives an additional overcharge stack.',
      'Enemy’s next attack must target the Clockwork Shaman. Clockwork Shaman gains 6 damage reduction for this attack. If the Clockwork Shaman takes damage from the target’s next attack, he receives an additional overcharge stack.',
      'Enemy’s next attack must target the Clockwork Shaman. Clockwork Shaman gains 7 damage reduction for this attack. If the Clockwork Shaman takes damage from the target’s next attack, he receives an additional overcharge stack.',
      'Enemy’s next attack must target the Clockwork Shaman. Clockwork Shaman gains 7 damage reduction for this attack. If the Clockwork Shaman takes damage from the target’s next attack, he receives an additional overcharge stack.',
    ],
    fragmentsToLevel: [0, 70, 120, 180, 250, 350, 500, 700, 1000, 1500],
  },
  {
    path: 'Clockwork Shaman',
    subpath: '',
    name: 'Blinding Smog',
    rarity: 'Clockwork Shaman epic combat scroll',
    attackRoll: [
      '2d6',
      '2d6',
      '3d6',
      '3d6',
      '4d6',
      '4d6',
      '5d6',
      '5d6',
      '6d6',
      '6d6',
    ],

    defendingStat: 'Intuition',
    priority: [13, 15, 15, 17, 17, 19, 19, 21, 21, 23],
    damage: ['3', '4', '4', '5', '5', '6', '6', '7', '7', '8'],
    target: [
      'One enemy',
      'One enemy',
      'One enemy',
      'One enemy',
      'One enemy',
      'One enemy',
      'One enemy',
      'One enemy',
      'One enemy',
      'One enemy',
    ],

    keyword: 'Debuff Priority',
    duration: [
      'One rounds',
      'One rounds',
      'One rounds',
      'One rounds',
      'One rounds',
      'Two rounds',
      'Two rounds',
      'Two rounds',
      'Two rounds',
      'Two rounds',
    ],
    durable: ['No', 'No', 'No', 'No', 'No', 'No', 'No', 'No', 'No', 'No'],
    description:
      'The Clockwork Shaman unleashes thermodynamic pulses from his mechanisms, capable of halting the attacker’s aggressive momentum.',
    effects: [
      'The target’s priority is reduced by -6 for the duration of the scroll. 1 overcharge stack: The attack gains +2 damage and +5 priority. 1 overcharge stack: The attack gains +1 duration. 2 overcharge stacks: The attack deals an additional 3 damage to the target. 2 overcharge stacks: The attack imposes an additional -2 penalty to the attack roll of the target.',
      'The target’s priority is reduced by -6 for the duration of the scroll. 1 overcharge stack: The attack gains +2 damage and +5 priority. 1 overcharge stack: The attack gains +1 duration. 2 overcharge stacks: The attack deals an additional 3 damage to the target. 2 overcharge stacks: The attack imposes an additional -2 penalty to the attack roll of the target.',
      'The target’s priority is reduced by -6 for the duration of the scroll. 1 overcharge stack: The attack gains +2 damage and +5 priority. 1 overcharge stack: The attack gains +1 duration. 2 overcharge stacks: The attack deals an additional 3 damage to the target. 2 overcharge stacks: The attack imposes an additional -2 penalty to the attack roll of the target.',
      'The target’s priority is reduced by -6 for the duration of the scroll. 1 overcharge stack: The attack gains +2 damage and +5 priority. 1 overcharge stack: The attack gains +1 duration. 2 overcharge stacks: The attack deals an additional 3 damage to the target. 2 overcharge stacks: The attack imposes an additional -2 penalty to the attack roll of the target.',
      'The target’s priority is reduced by -6 for the duration of the scroll. 1 overcharge stack: The attack gains +2 damage and +5 priority. 1 overcharge stack: The attack gains +1 duration. 2 overcharge stacks: The attack deals an additional 3 damage to the target. 2 overcharge stacks: The attack imposes an additional -2 penalty to the attack roll of the target.',
      'The target’s priority is reduced by -6 for the duration of the scroll. 1 overcharge stack: The attack gains +2 damage and +5 priority. 1 overcharge stack: The attack gains +1 duration. 2 overcharge stacks: The attack deals an additional 3 damage to the target. 2 overcharge stacks: The attack imposes an additional -2 penalty to the attack roll of the target.',
      'The target’s priority is reduced by -6 for the duration of the scroll. 1 overcharge stack: The attack gains +2 damage and +5 priority. 1 overcharge stack: The attack gains +1 duration. 2 overcharge stacks: The attack deals an additional 3 damage to the target. 2 overcharge stacks: The attack imposes an additional -2 penalty to the attack roll of the target.',
      'The target’s priority is reduced by -6 for the duration of the scroll. 1 overcharge stack: The attack gains +2 damage and +5 priority. 1 overcharge stack: The attack gains +1 duration. 2 overcharge stacks: The attack deals an additional 3 damage to the target. 2 overcharge stacks: The attack imposes an additional -2 penalty to the attack roll of the target.',
      'The target’s priority is reduced by -6 for the duration of the scroll. 1 overcharge stack: The attack gains +2 damage and +5 priority. 1 overcharge stack: The attack gains +1 duration. 2 overcharge stacks: The attack deals an additional 3 damage to the target. 2 overcharge stacks: The attack imposes an additional -2 penalty to the attack roll of the target.',
      'The target’s priority is reduced by -6 for the duration of the scroll. 1 overcharge stack: The attack gains +2 damage and +5 priority. 1 overcharge stack: The attack gains +1 duration. 2 overcharge stacks: The attack deals an additional 3 damage to the target. 2 overcharge stacks: The attack imposes an additional -2 penalty to the attack roll of the target.',
    ],
    fragmentsToLevel: [0, 100, 150, 250, 350, 500, 700, 1000, 1500, 2200],
  },
  {
    path: 'Clockwork Shaman',
    subpath: '',
    name: 'Caustic Sludge',
    rarity: 'Clockwork Shaman epic combat scroll',
    attackRoll: [
      '2d6',
      '2d6',
      '3d6',
      '3d6',
      '4d6',
      '4d6',
      '5d6',
      '5d6',
      '6d6',
      '6d6',
    ],

    defendingStat: 'Intuition',
    priority: [19, 22, 22, 24, 24, 26, 26, 28, 28, 30],
    damage: ['3', '4', '4', '5', '5', '6', '6', '7', '7', '8'],
    target: [
      'One enemy',
      'One enemy',
      'One enemy',
      'One enemy',
      'One enemy',
      'One enemy',
      'One enemy',
      'One enemy',
      'One enemy',
      'One enemy',
    ],

    keyword: 'None',
    duration: [
      'Instant',
      'Instant',
      'Instant',
      'Instant',
      'Instant',
      'Instant',
      'Instant',
      'Instant',
      'Instant',
      'Instant',
    ],
    durable: ['No', 'No', 'No', 'No', 'No', 'No', 'No', 'No', 'No', 'No'],
    description:
      'The Clockwork Shaman launches hot oil towards his target, which adheres to the target, causing continuous damage and multiple burns. The effects of this scroll are enhanced by using overcharge stacks.',
    effects: [
      '1 overcharge stack:The attack gains +2 damage. 2 overcharge stacks: The attack can target an additional enemy. 3 overcharge stacks: The attack gains the keyword “repeating” dealing the same amount of damage at the beginning of the enemy’s turn without the need to roll a new attack.',
      '1 overcharge stack:The attack gains +2 damage. 2 overcharge stacks: The attack can target an additional enemy. 3 overcharge stacks: The attack gains the keyword “repeating” dealing the same amount of damage at the beginning of the enemy’s turn without the need to roll a new attack.',
      '1 overcharge stack:The attack gains +2 damage. 2 overcharge stacks: The attack can target an additional enemy. 3 overcharge stacks: The attack gains the keyword “repeating” dealing the same amount of damage at the beginning of the enemy’s turn without the need to roll a new attack.',
      '1 overcharge stack:The attack gains +2 damage. 2 overcharge stacks: The attack can target an additional enemy. 3 overcharge stacks: The attack gains the keyword “repeating” dealing the same amount of damage at the beginning of the enemy’s turn without the need to roll a new attack.',
      '1 overcharge stack:The attack gains +2 damage. 2 overcharge stacks: The attack can target an additional enemy. 3 overcharge stacks: The attack gains the keyword “repeating” dealing the same amount of damage at the beginning of the enemy’s turn without the need to roll a new attack.',
      '1 overcharge stack:The attack gains +2 damage. 2 overcharge stacks: The attack can target an additional enemy. 3 overcharge stacks: The attack gains the keyword “repeating” dealing the same amount of damage at the beginning of the enemy’s turn without the need to roll a new attack.',
      '1 overcharge stack:The attack gains +2 damage. 2 overcharge stacks: The attack can target an additional enemy. 3 overcharge stacks: The attack gains the keyword “repeating” dealing the same amount of damage at the beginning of the enemy’s turn without the need to roll a new attack.',
      '1 overcharge stack:The attack gains +2 damage. 2 overcharge stacks: The attack can target an additional enemy. 3 overcharge stacks: The attack gains the keyword “repeating” dealing the same amount of damage at the beginning of the enemy’s turn without the need to roll a new attack.',
      '1 overcharge stack:The attack gains +2 damage. 2 overcharge stacks: The attack can target an additional enemy. 3 overcharge stacks: The attack gains the keyword “repeating” dealing the same amount of damage at the beginning of the enemy’s turn without the need to roll a new attack.',
      '1 overcharge stack:The attack gains +2 damage. 2 overcharge stacks: The attack can target an additional enemy. 3 overcharge stacks: The attack gains the keyword “repeating” dealing the same amount of damage at the beginning of the enemy’s turn without the need to roll a new attack.',
    ],
    fragmentsToLevel: [0, 100, 150, 250, 350, 500, 700, 1000, 1500, 2200],
  },
  {
    path: 'Clockwork Shaman',
    subpath: '',
    name: 'Flame Burst',
    rarity: 'Clockwork Shaman rare combat scroll',
    attackRoll: [
      '1d6',
      '1d6',
      '1d6',
      '2d6',
      '2d6',
      '2d6',
      '3d6',
      '3d6',
      '3d6',
      '4d6',
    ],
    defendingStat: 'Agility',
    priority: [4, 6, 6, 6, 8, 8, 8, 10, 10, 10],
    damage: [
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
    ],
    target: [
      'Two enemy',
      'Two enemy',
      'Two enemy',
      'Three enemy',
      'Three enemy',
      'Three enemy',
      'Four enemy',
      'Four enemy',
      'Four enemy',
      'Four enemy',
    ],

    keyword: 'None',
    duration: [
      'One rounds',
      'One rounds',
      'One rounds',
      'One rounds',
      'One rounds',
      'Two rounds',
      'Two rounds',
      'Two rounds',
      'Two rounds',
      'Two rounds',
    ],
    durable: ['No', 'No', 'No', 'No', 'No', 'No', 'No', 'No', 'No', 'No'],
    description:
      'The Clockwork Shaman releases pressurized oil flowing within his mechanisms. Upon contact with the air, it ignites into flames engulfing his targets. The effects of this scroll are amplified by using overcharge stacks.',
    effects: [
      '1 overcharge stack:The priority of the scroll becomes 14. 2 overcharge stacks: This attack gains the sneak attack keyword. 1 overcharge stack: The attack roll of this attack gains +1d6 2 overcharge stacks: The damage of this attack increases by 2.',
      '1 overcharge stack:The priority of the scroll becomes 14. 2 overcharge stacks: This attack gains the sneak attack keyword. 1 overcharge stack: The attack roll of this attack gains +1d6 2 overcharge stacks: The damage of this attack increases by 2.',
      '1 overcharge stack:The priority of the scroll becomes 14. 2 overcharge stacks: This attack gains the sneak attack keyword. 1 overcharge stack: The attack roll of this attack gains +1d6 2 overcharge stacks: The damage of this attack increases by 3.',
      '1 overcharge stack:The priority of the scroll becomes 16. 2 overcharge stacks: This attack gains the sneak attack keyword. 1 overcharge stack: The attack roll of this attack gains +1d6 2 overcharge stacks: The damage of this attack increases by 3.',
      '1 overcharge stack:The priority of the scroll becomes 16. 2 overcharge stacks: This attack gains the sneak attack keyword. 1 overcharge stack: The attack roll of this attack gains +1d6 2 overcharge stacks: The damage of this attack increases by 4.',
      '1 overcharge stack:The priority of the scroll becomes 16. 2 overcharge stacks: This attack gains the sneak attack keyword. 1 overcharge stack: The attack roll of this attack gains +1d6 2 overcharge stacks: The damage of this attack increases by 4.',
      '1 overcharge stack:The priority of the scroll becomes 18. 2 overcharge stacks: This attack gains the sneak attack keyword. 1 overcharge stack: The attack roll of this attack gains +1d6 2 overcharge stacks: The damage of this attack increases by 5.',
      '1 overcharge stack:The priority of the scroll becomes 18. 2 overcharge stacks: This attack gains the sneak attack keyword. 1 overcharge stack: The attack roll of this attack gains +1d6 2 overcharge stacks: The damage of this attack increases by 5.',
      '1 overcharge stack:The priority of the scroll becomes 18. 2 overcharge stacks: This attack gains the sneak attack keyword. 1 overcharge stack: The attack roll of this attack gains +1d6 2 overcharge stacks: The damage of this attack increases by 6.',
      '1 overcharge stack:The priority of the scroll becomes 20. 2 overcharge stacks: This attack gains the sneak attack keyword. 1 overcharge stack: The attack roll of this attack gains +1d6 2 overcharge stacks: The damage of this attack increases by 6.',
    ],
    fragmentsToLevel: [0, 70, 120, 180, 250, 350, 500, 700, 1000, 1500],
  },
  {
    path: 'Clockwork Shaman',
    subpath: '',
    name: 'Flametongue Strike',
    rarity: 'Clockwork Shaman rare combat scroll',
    attackRoll: [
      '3d6',
      '3d6',
      '4d6',
      '4d6',
      '6d6',
      '6d6',
      '7d6',
      '7d6',
      '8d6',
      '8d6',
    ],

    defendingStat: 'Agility',
    priority: [14, 16, 16, 18, 18, 20, 20, 22, 22, 24],
    damage: ['3', '4', '4', '5', '5', '6', '6', '7', '7', '8'],
    target: [
      'One enemy',
      'One enemy',
      'One enemy',
      'One enemy',
      'One enemy',
      'One enemy',
      'One enemy',
      'One enemy',
      'One enemy',
      'One enemy',
    ],

    keyword: 'None',
    duration: [
      'Instant',
      'Instant',
      'Instant',
      'Instant',
      'Instant',
      'Instant',
      'Instant',
      'Instant',
      'Instant',
      'Instant',
    ],
    durable: ['No', 'No', 'No', 'No', 'No', 'No', 'No', 'No', 'No', 'No'],
    description:
      'The Clockwork Shaman opens his arms, and multiple fireballs are launched towards his adversaries. The effects of this scroll are heightened by using overcharge stacks',
    effects: [
      '1 overcharge stack:This attack gains +2 damage 2 overcharge stacks: This attack gains Enervating Blow (3). 3 overcharge stack: This attak gains Double Strike',
      '1 overcharge stack:This attack gains +2 damage 2 overcharge stacks: This attack gains Enervating Blow (3). 3 overcharge stack: This attak gains Double Strike',
      '1 overcharge stack:This attack gains +3 damage 2 overcharge stacks: This attack gains Enervating Blow (3). 3 overcharge stack: This attak gains Double Strike',
      '1 overcharge stack:This attack gains +3 damage 2 overcharge stacks: This attack gains Enervating Blow (3). 3 overcharge stack: This attak gains Double Strike',
      '1 overcharge stack:This attack gains +3 damage 2 overcharge stacks: This attack gains Enervating Blow (3). 3 overcharge stack: This attak gains Double Strike',
      '1 overcharge stack:This attack gains +4 damage 2 overcharge stacks: This attack gains Enervating Blow (3). 3 overcharge stack: This attak gains Double Strike',
      '1 overcharge stack:This attack gains +4 damage 2 overcharge stacks: This attack gains Enervating Blow (3). 3 overcharge stack: This attak gains Double Strike',
      '1 overcharge stack:This attack gains +5 damage 2 overcharge stacks: This attack gains Enervating Blow (3). 3 overcharge stack: This attak gains Double Strike',
      '1 overcharge stack:This attack gains +5 damage 2 overcharge stacks: This attack gains Enervating Blow (3). 3 overcharge stack: This attak gains Double Strike',
      '1 overcharge stack:This attack gains +6 damage 2 overcharge stacks: This attack gains Enervating Blow (3). 3 overcharge stack: This attak gains Double Strike',
    ],
    fragmentsToLevel: [0, 70, 120, 180, 250, 350, 500, 700, 1000, 1500],
  },
  {
    path: 'Clockwork Shaman',
    subpath: '',
    name: 'Overcharge Release',
    rarity: 'Clockwork Shaman epic combat scroll',
    attackRoll: [
      '1d6',
      '1d6',
      '1d6',
      '2d6',
      '2d6',
      '2d6',
      '3d6',
      '3d6',
      '3d6',
      '4d6',
    ],
    defendingStat: 'Agility',
    priority: [20, 22, 22, 24, 24, 26, 26, 28, 28, 30],
    damage: [
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
    ],
    target: [
      'One enemy',
      'One enemy',
      'One enemy',
      'One enemy',
      'One enemy',
      'One enemy',
      'One enemy',
      'One enemy',
      'One enemy',
      'One enemy',
    ],
    keyword: 'None',
    duration: [
      'Instant',
      'Instant',
      'Instant',
      'Instant',
      'Instant',
      'Instant',
      'Instant',
      'Instant',
      'Instant',
      'Instant',
    ],
    durable: ['No', 'No', 'No', 'No', 'No', 'No', 'No', 'No', 'No', 'No'],
    description:
      'The Clockwork Shaman opens his arms, and multiple fireballs are launched towards his adversaries. The effects of this scroll are heightened by using overcharge stacks',
    effects: [
      '0 overcharge stack:No effect Per 1 Overcharge Stack (up to 5):The attack deals 3 additional damage per Overcharge Stack (up to 5). Per 1 Overcharge Stack (up to 5): The attack gains +1d6 to the attack roll per Overcharge Stack (up to 5).',
      '0 overcharge stack:No effect Per 1 Overcharge Stack (up to 5):The attack deals 3 additional damage per Overcharge Stack (up to 5). Per 1 Overcharge Stack (up to 5): The attack gains +1d6 to the attack roll per Overcharge Stack (up to 5).',
      '0 overcharge stack:No effect Per 1 Overcharge Stack (up to 5):The attack deals 3 additional damage per Overcharge Stack (up to 5). Per 1 Overcharge Stack (up to 6): The attack gains +1d6 to the attack roll per Overcharge Stack (up to 5).',
      '0 overcharge stack:No effect Per 1 Overcharge Stack (up to 5):The attack deals 3 additional damage per Overcharge Stack (up to 5). Per 1 Overcharge Stack (up to 6): The attack gains +1d6 to the attack roll per Overcharge Stack (up to 5).',
      '0 overcharge stack:No effect Per 1 Overcharge Stack (up to 5):The attack deals 4 additional damage per Overcharge Stack (up to 5). Per 1 Overcharge Stack (up to 7): The attack gains +1d6 to the attack roll per Overcharge Stack (up to 5).',
      '0 overcharge stack:No effect Per 1 Overcharge Stack (up to 5):The attack deals 4 additional damage per Overcharge Stack (up to 5). Per 1 Overcharge Stack (up to 7): The attack gains +1d6 to the attack roll per Overcharge Stack (up to 5).',
      '0 overcharge stack:No effect Per 1 Overcharge Stack (up to 5):The attack deals 4 additional damage per Overcharge Stack (up to 5). Per 1 Overcharge Stack (up to 7): The attack gains +1d6 to the attack roll per Overcharge Stack (up to 5).',
      '0 overcharge stack:No effect Per 1 Overcharge Stack (up to 5):The attack deals 4 additional damage per Overcharge Stack (up to 5). Per 1 Overcharge Stack (up to 8): The attack gains +1d6 to the attack roll per Overcharge Stack (up to 5).',
      '0 overcharge stack:No effect Per 1 Overcharge Stack (up to 5):The attack deals 4 additional damage per Overcharge Stack (up to 5). Per 1 Overcharge Stack (up to 8): The attack gains +1d6 to the attack roll per Overcharge Stack (up to 5).',
      '0 overcharge stack:No effect Per 1 Overcharge Stack (up to 5):The attack deals 5 additional damage per Overcharge Stack (up to 5). Per 1 Overcharge Stack (up to 8): The attack gains +1d6 to the attack roll per Overcharge Stack (up to 5).',
    ],
    fragmentsToLevel: [0, 100, 150, 250, 350, 500, 700, 1000, 1500, 2200],
  },
  {
    path: 'Clockwork Shaman',
    subpath: '',
    name: 'Wildfire Assault',
    rarity: 'Clockwork Shaman epic combat scroll',
    attackRoll: [
      '2d6',
      '2d6',
      '3d6',
      '3d6',
      '4d6',
      '4d6',
      '5d6',
      '5d6',
      '6d6',
      '6d6',
    ],
    defendingStat: 'Agility',
    priority: [18, 20, 20, 22, 22, 24, 24, 26, 26, 28],
    damage: ['0', '0', '0', '0', '0', '0', '0', '0', '0', '0'],
    target: [
      'One enemy',
      'One enemy',
      'One enemy',
      'One enemy',
      'One enemy',
      'One enemy',
      'One enemy',
      'One enemy',
      'One enemy',
      'One enemy',
    ],
    keyword: 'None',
    duration: [
      'Instant',
      'Instant',
      'Instant',
      'Instant',
      'Instant',
      'Instant',
      'Instant',
      'Instant',
      'Instant',
      'Instant',
    ],
    durable: ['No', 'No', 'No', 'No', 'No', 'No', 'No', 'No', 'No', 'No'],
    description:
      'The Clockwork Shaman unleashes a torrent of wildfire, engulfing his adversaries in searing flames. This inferno not only inflicts damage but also purges any buffs and enhancements that his opponents may have, leaving them vulnerable and stripped of their advantages',
    effects: [
      'The Clockwork Shaman chooses an active buff on the enemy and removes it ending its effects immediately.1 overcharge stack: This attack’s damage becomes 4 .3 overcharge stacks: You can choose another target to apply all the effects of the scroll. 3 overcharge stacks: You can remove an additional buff from the target(s).',
      'The Clockwork Shaman chooses an active buff on the enemy and removes it ending its effects immediately.1 overcharge stack: This attack’s damage becomes 4 .3 overcharge stacks: You can choose another target to apply all the effects of the scroll. 3 overcharge stacks: You can remove an additional buff from the target(s).',
      'The Clockwork Shaman chooses an active buff on the enemy and removes it ending its effects immediately.1 overcharge stack: This attack’s damage becomes 4 .3 overcharge stacks: You can choose another target to apply all the effects of the scroll. 3 overcharge stacks: You can remove an additional buff from the target(s).',
      'The Clockwork Shaman chooses an active buff on the enemy and removes it ending its effects immediately.1 overcharge stack: This attack’s damage becomes 4 .3 overcharge stacks: You can choose another target to apply all the effects of the scroll. 3 overcharge stacks: You can remove an additional buff from the target(s).',
      'The Clockwork Shaman chooses an active buff on the enemy and removes it ending its effects immediately.1 overcharge stack: This attack’s damage becomes 4 .3 overcharge stacks: You can choose another target to apply all the effects of the scroll. 3 overcharge stacks: You can remove an additional buff from the target(s).',
      'The Clockwork Shaman chooses an active buff on the enemy and removes it ending its effects immediately.1 overcharge stack: This attack’s damage becomes 4 .3 overcharge stacks: You can choose another target to apply all the effects of the scroll. 3 overcharge stacks: You can remove an additional buff from the target(s).',
      'The Clockwork Shaman chooses an active buff on the enemy and removes it ending its effects immediately.1 overcharge stack: This attack’s damage becomes 4 .3 overcharge stacks: You can choose another target to apply all the effects of the scroll. 3 overcharge stacks: You can remove an additional buff from the target(s).',
      'The Clockwork Shaman chooses an active buff on the enemy and removes it ending its effects immediately.1 overcharge stack: This attack’s damage becomes 4 .3 overcharge stacks: You can choose another target to apply all the effects of the scroll. 3 overcharge stacks: You can remove an additional buff from the target(s).',
      'The Clockwork Shaman chooses an active buff on the enemy and removes it ending its effects immediately.1 overcharge stack: This attack’s damage becomes 4 .3 overcharge stacks: You can choose another target to apply all the effects of the scroll. 3 overcharge stacks: You can remove an additional buff from the target(s).',
      'The Clockwork Shaman chooses an active buff on the enemy and removes it ending its effects immediately.1 overcharge stack: This attack’s damage becomes 4 .3 overcharge stacks: You can choose another target to apply all the effects of the scroll. 3 overcharge stacks: You can remove an additional buff from the target(s).',
    ],
    fragmentsToLevel: [0, 100, 150, 250, 350, 500, 700, 1000, 1500, 2200],
  },
  {
    path: 'Clockwork Shaman',
    subpath: 'Inferno Warden',
    name: 'Fireguard Veil',
    rarity: 'Clockwork Shaman epic combat scroll',
    attackRoll: [
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
    ],
    defendingStat: 'None',
    priority: [25, 27, 20, 22, 29, 29, 29, 31, 31, 31],
    damage:
      ['None',
        'None',
        'None',
        'None',
        'None',
        'None',
        'None',
        'None',
        'None',
        'None',
      ],
    target: [
      'Self',
      'Self',
      'Self',
      'Self',
      'Self',
      'Self',
      'Self',
      'Self',
      'Self',
      'Self',

    ],
    keyword: 'None',
    duration: [
      'One rounds',
      'One rounds',
      'One rounds',
      'One rounds',
      'Two rounds',
      'Two rounds',
      'Two rounds',
      'Three rounds',
      'Three rounds',
      'Three rounds',

    ],
    durable: ['No', 'No', 'No', 'No', 'No', 'No', 'No', 'No', 'No', 'No'],
    description:
      'The Inferno Warden has mastered the technic of trading his own life in order for his mechanical part to work even better and do more harassment to his opponents.',
    effects: [
      'For the duration of the scroll the Inferno warden whenever he takes damage, he can add 1 extra Overcharge stacks. (Only from attacks that the inferno warden receives damage.) (This scroll doesn’t count towards the regular scroll count of the turn.)',
      'For the duration of the scroll the Inferno warden whenever he takes damage, he can add 1 extra Overcharge stacks. (Only from attacks that the inferno warden receives damage.) (This scroll doesn’t count towards the regular scroll count of the turn.)',
      'For the duration of the scroll the Inferno warden whenever he takes damage, he can add 1 extra Overcharge stacks. (Only from attacks that the inferno warden receives damage.) (This scroll doesn’t count towards the regular scroll count of the turn.)',
      'For the duration of the scroll the Inferno warden whenever he takes damage, he can add 2 extra Overcharge stacks. (Only from attacks that the inferno warden receives damage.) (This scroll doesn’t count towards the regular scroll count of the turn.)',
      'For the duration of the scroll the Inferno warden whenever he takes damage, he can add 2 extra Overcharge stacks. (Only from attacks that the inferno warden receives damage.) (This scroll doesn’t count towards the regular scroll count of the turn.)',
      'For the duration of the scroll the Inferno warden whenever he takes damage, he can add 2 extra Overcharge stacks. (Only from attacks that the inferno warden receives damage.) (This scroll doesn’t count towards the regular scroll count of the turn.)',
      'For the duration of the scroll the Inferno warden whenever he takes damage, he can add 2 extra Overcharge stacks. (Only from attacks that the inferno warden receives damage.) (This scroll doesn’t count towards the regular scroll count of the turn.)',
      'For the duration of the scroll the Inferno warden whenever he takes damage, he can add 3 extra Overcharge stacks. (Only from attacks that the inferno warden receives damage.) (This scroll doesn’t count towards the regular scroll count of the turn.)',
      'For the duration of the scroll the Inferno warden whenever he takes damage, he can add 3 extra Overcharge stacks. (Only from attacks that the inferno warden receives damage.) (This scroll doesn’t count towards the regular scroll count of the turn.)',
      'For the duration of the scroll the Inferno warden whenever he takes damage, he can add 3 extra Overcharge stacks. (Only from attacks that the inferno warden receives damage.) (This scroll doesn’t count towards the regular scroll count of the turn.)',
    ],
    fragmentsToLevel: [0, 100, 150, 250, 350, 500, 700, 1000, 1500, 2200],
  },
  {
    path: 'Clockwork Shaman',
    subpath: 'Infernal Warden',
    name: 'Infernal Shield',
    rarity: 'Clockwork Shaman epic combat scroll',
    attackRoll: [
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
    ],
    defendingStat: 'None',
    priority: ['None', 'None', 'None', 'None', 'None', 'None', 'None', 'None', 'None', 'None'],
    damage:
      ['None',
        'None',
        'None',
        'None',
        'None',
        'None',
        'None',
        'None',
        'None',
        'None',
      ],
    target: [
      'One Ally',
      'One Ally',
      'One Ally',
      'One Ally',
      'One Ally',
      'One Ally',
      'One Ally',
      'One Ally',
      'One Ally',
      'One Ally',
    ],
    keyword: 'Interrupt',
    duration: [
      'Instant',
      'Instant',
      'Instant',
      'Instant',
      'Instant',
      'Instant',
      'Instant',
      'Instant',
      'Instant',
      'Instant',
    ],
    durable: ['No', 'No', 'No', 'No', 'No', 'No', 'No', 'No', 'No', 'No'],
    description:
      'The Inferno Warden can use his control over the flames and steam in order to protect his allies.',
    effects: [
      'As a reaction to the attack and before the defensive dices are rolled the Inferno warden can choose to give to an ally +2d6 AD +2d6 ID but the target and the Inferno warden will suffer 3 damage . If the attack is avoided, (even if the target chooses not to take the Infernal shield buff) then the Infernal warden receives 1 Overcharge stack.',
      'As a reaction to the attack and before the defensive dices are rolled the Inferno warden can choose to give to an ally +2d6 AD +2d6 ID but the target and the Inferno warden will suffer 3 damage . If the attack is avoided, (even if the target chooses not to take the Infernal shield buff) then the Infernal warden receives 1 Overcharge stack.',
      'As a reaction to the attack and before the defensive dices are rolled the Inferno warden can choose to give to an ally +3d6 AD +3d6 ID but the target and the Inferno warden will suffer 3 damage . If the attack is avoided, (even if the target chooses not to take the Infernal shield buff) then the Infernal warden receives 1 Overcharge stack.',
      'As a reaction to the attack and before the defensive dices are rolled the Inferno warden can choose to give to an ally +3d6 AD +3d6 ID but the target and the Inferno warden will suffer 4 damage . If the attack is avoided, (even if the target chooses not to take the Infernal shield buff) then the Infernal warden receives 1 Overcharge stack.',
      'As a reaction to the attack and before the defensive dices are rolled the Inferno warden can choose to give to an ally +3d6 AD +3d6 ID but the target and the Inferno warden will suffer 4 damage . If the attack is avoided, (even if the target chooses not to take the Infernal shield buff) then the Infernal warden receives 2 Overcharge stack.',
      'As a reaction to the attack and before the defensive dices are rolled the Inferno warden can choose to give to an ally +4d6 AD +4d6 ID but the target and the Inferno warden will suffer 4 damage . If the attack is avoided, (even if the target chooses not to take the Infernal shield buff) then the Infernal warden receives 2 Overcharge stack.',
      'As a reaction to the attack and before the defensive dices are rolled the Inferno warden can choose to give to an ally +4d6 AD +4d6 ID but the target and the Inferno warden will suffer 5 damage . If the attack is avoided, (even if the target chooses not to take the Infernal shield buff) then the Infernal warden receives 2 Overcharge stack.',
      'As a reaction to the attack and before the defensive dices are rolled the Inferno warden can choose to give to an ally +4d6 AD +4d6 ID but the target and the Inferno warden will suffer 5 damage . If the attack is avoided, (even if the target chooses not to take the Infernal shield buff) then the Infernal warden receives 3 Overcharge stack.',
      'As a reaction to the attack and before the defensive dices are rolled the Inferno warden can choose to give to an ally +5d6 AD +5d6 ID but the target and the Inferno warden will suffer 5 damage . If the attack is avoided, (even if the target chooses not to take the Infernal shield buff) then the Infernal warden receives 3 Overcharge stack.',
      'As a reaction to the attack and before the defensive dices are rolled the Inferno warden can choose to give to an ally +5d6 AD +5d6 ID but the target and the Inferno warden will suffer 6 damage . If the attack is avoided, (even if the target chooses not to take the Infernal shield buff) then the Infernal warden receives 3 Overcharge stack.',
    ],
    fragmentsToLevel: [0, 100, 150, 250, 350, 500, 700, 1000, 1500, 2200],
  },
  {
    path: 'Clockwork Shaman',
    subpath: 'Pyromancer',
    name: 'Pyro-jet Charge',
    rarity: 'Clockwork Shaman epic combat scroll',
    attackRoll: [
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
    ],
    defendingStat: 'None',
    priority: ['None', 'None', 'None', 'None', 'None', 'None', 'None', 'None', 'None', 'None'],
    damage: [
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
    ],
    target: [
      'Self',
      'Self',
      'Self',
      'Self',
      'Self',
      'Self',
      'Self',
      'Self',
      'Self',
      'Self',
    ],
    keyword: 'Passive',
    duration: [
      'Instant',
      'Instant',
      'Instant',
      'Instant',
      'Instant',
      'Instant',
      'Instant',
      'Instant',
      'Instant',
      'Instant',
    ],
    durable: ['No', 'No', 'No', 'No', 'No', 'No', 'No', 'No', 'No', 'No'],
    description:
      'The Pyromancer is a master of arcane fire magic, weaving intricate spells fueled by the power of steam and flames.',
    effects: [
      'Effect: Once per turn if the Pyromancer successfully attacked an enemy and inflicted damage he receives an extra overcharge stack.\nEvery time Pyromancer uses 3 overcharge stacks in a scroll the scroll receives the keyword “Revenge 5”.\nEvery time Pyromancer uses 6 overcharge stacks in a scroll the scroll receives the keyword “Critical Strike”',
      'Effect: Once per turn if the Pyromancer successfully attacked an enemy and inflicted damage he receives an extra overcharge stack.\nEvery time Pyromancer uses 3 overcharge stacks in a scroll the scroll receives the keyword “Revenge 5”.\nEvery time Pyromancer uses 6 overcharge stacks in a scroll the scroll receives the keyword “Critical Strike”',
      'Effect: Once per turn if the Pyromancer successfully attacked an enemy and inflicted damage he receives an extra overcharge stack.\nEvery time Pyromancer uses 3 overcharge stacks in a scroll the scroll receives the keyword “Revenge 5”.\nEvery time Pyromancer uses 6 overcharge stacks in a scroll the scroll receives the keyword “Critical Strike”',
      'Effect: Once per turn if the Pyromancer successfully attacked an enemy and inflicted damage he receives an extra overcharge stack.\nEvery time Pyromancer uses 3 overcharge stacks in a scroll the scroll receives the keyword “Revenge 5”.\nEvery time Pyromancer uses 6 overcharge stacks in a scroll the scroll receives the keyword “Critical Strike”',
      'Effect: Once per turn if the Pyromancer successfully attacked an enemy and inflicted damage he receives an extra overcharge stack.\nEvery time Pyromancer uses 3 overcharge stacks in a scroll the scroll receives the keyword “Revenge 5”.\nEvery time Pyromancer uses 6 overcharge stacks in a scroll the scroll receives the keyword “Critical Strike”',
      'Effect: Once per turn if the Pyromancer successfully attacked an enemy and inflicted damage he receives an extra overcharge stack.\nEvery time Pyromancer uses 3 overcharge stacks in a scroll the scroll receives the keyword “Revenge 5”.\nEvery time Pyromancer uses 6 overcharge stacks in a scroll the scroll receives the keyword “Critical Strike”',
      'Effect: Once per turn if the Pyromancer successfully attacked an enemy and inflicted damage he receives an extra overcharge stack.\nEvery time Pyromancer uses 3 overcharge stacks in a scroll the scroll receives the keyword “Revenge 5”.\nEvery time Pyromancer uses 6 overcharge stacks in a scroll the scroll receives the keyword “Critical Strike”',
      'Effect: Once per turn if the Pyromancer successfully attacked an enemy and inflicted damage he receives an extra overcharge stack.\nEvery time Pyromancer uses 3 overcharge stacks in a scroll the scroll receives the keyword “Revenge 5”.\nEvery time Pyromancer uses 6 overcharge stacks in a scroll the scroll receives the keyword “Critical Strike”',
      'Effect: Once per turn if the Pyromancer successfully attacked an enemy and inflicted damage he receives an extra overcharge stack.\nEvery time Pyromancer uses 3 overcharge stacks in a scroll the scroll receives the keyword “Revenge 5”.\nEvery time Pyromancer uses 6 overcharge stacks in a scroll the scroll receives the keyword “Critical Strike”',
      'Effect: Once per turn if the Pyromancer successfully attacked an enemy and inflicted damage he receives an extra overcharge stack.\nEvery time Pyromancer uses 3 overcharge stacks in a scroll the scroll receives the keyword “Revenge 5”.\nEvery time Pyromancer uses 6 overcharge stacks in a scroll the scroll receives the keyword “Critical Strike”',
    ],
    fragmentsToLevel: [0, 100, 150, 250, 350, 500, 700, 1000, 1500, 2200],
  },
  {
    path: 'Clockwork Shaman',
    subpath: 'Pyromancer',
    name: 'Fireball',
    rarity: 'Clockwork Shaman epic combat scroll',
    attackRoll: [
      '1d6',
      '1d6',
      '1d6',
      '2d6',
      '2d6',
      '2d6',
      '3d6',
      '3d6',
      '3d6',
      '4d6',
    ],
    defendingStat: 'Agility',
    priority: [6, 8, 8, 8, 10, 10, 12, 12, 12, 14],
    damage: [
      '9',
      '12',
      '12',
      '15',
      '15',
      '18',
      '18',
      '21',
      '21',
      '24',
    ],
    target: [
      'All',
      'All',
      'All',
      'All',
      'All',
      'All',
      'All',
      'All',
      'All',
      'All',
    ],
    keyword: 'None',
    duration: [
      'Instant',
      'Instant',
      'Instant',
      'Instant',
      'Instant',
      'Instant',
      'Instant',
      'Instant',
      'Instant',
      'Instant',
    ],
    durable: ['No', 'No', 'No', 'No', 'No', 'No', 'No', 'No', 'No', 'No'],
    description:
      'The Pyromancer is a master of Ichors fire magic, weaving intricate spells fueled by the power of steam and flames.',
    effects: [
      'Effect: The Pyromancer can use:\n3 Stacks in order to receive +2d6 on his attack roll.\n6 Stacks in order to change the description from All targets to 2 enemies and 2 allies.\n9 Stacks in order to change the description from all targets to All enemies. ”',
      'Effect: The Pyromancer can use:\n3 Stacks in order to receive +2d6 on his attack roll.\n6 Stacks in order to change the description from All targets to 2 enemies and 2 allies.\n9 Stacks in order to change the description from all targets to All enemies. ”',
      'Effect: The Pyromancer can use:\n3 Stacks in order to receive +2d6 on his attack roll.\n6 Stacks in order to change the description from All targets to 2 enemies and 2 allies.\n9 Stacks in order to change the description from all targets to All enemies. ”',
      'Effect: The Pyromancer can use:\n3 Stacks in order to receive +2d6 on his attack roll.\n6 Stacks in order to change the description from All targets to 2 enemies and 2 allies.\n9 Stacks in order to change the description from all targets to All enemies. ”',
      'Effect: The Pyromancer can use:\n3 Stacks in order to receive +2d6 on his attack roll.\n6 Stacks in order to change the description from All targets to 2 enemies and 2 allies.\n9 Stacks in order to change the description from all targets to All enemies. ”',
      'Effect: The Pyromancer can use:\n3 Stacks in order to receive +2d6 on his attack roll.\n6 Stacks in order to change the description from All targets to 2 enemies and 2 allies.\n9 Stacks in order to change the description from all targets to All enemies. ”',
      'Effect: The Pyromancer can use:\n3 Stacks in order to receive +2d6 on his attack roll.\n6 Stacks in order to change the description from All targets to 2 enemies and 2 allies.\n9 Stacks in order to change the description from all targets to All enemies. ”',
      'Effect: The Pyromancer can use:\n3 Stacks in order to receive +2d6 on his attack roll.\n6 Stacks in order to change the description from All targets to 2 enemies and 2 allies.\n9 Stacks in order to change the description from all targets to All enemies. ”',
      'Effect: The Pyromancer can use:\n3 Stacks in order to receive +2d6 on his attack roll.\n6 Stacks in order to change the description from All targets to 2 enemies and 2 allies.\n9 Stacks in order to change the description from all targets to All enemies. ”',
      'Effect: The Pyromancer can use:\n3 Stacks in order to receive +2d6 on his attack roll.\n6 Stacks in order to change the description from All targets to 2 enemies and 2 allies.\n9 Stacks in order to change the description from all targets to All enemies. ”',
    ],
    fragmentsToLevel: [0, 100, 150, 250, 350, 500, 700, 1000, 1500, 2200],
  },
  {
    path: 'Kryptios',
    subpath: '',
    name: 'Dirty Tricks',
    rarity: 'Kryptios rare combat scroll',
    attackRoll: [
      '2d6',
      '2d6',
      '3d6',
      '3d6',
      '4d6',
      '4d6',
      '5d6',
      '5d6',
      '6d6',
      '6d6',
    ],
    defendingStat: 'Intuition',
    priority: [24, 27, 27, 29, 29, 32, 32, 35, 35, 38],
    damage: [
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
    ],
    target: [
      'One enemy',
      'One enemy',
      'One enemy',
      'One enemy',
      'One enemy',
      'One enemy',
      'One enemy',
      'One enemy',
      'One enemy',
      'One enemy',
    ],

    keyword: 'Debuff Damage',
    duration: [
      'Two rounds',
      'Two rounds',
      'Two rounds',
      'Three rounds',
      'Three rounds',
      'Three rounds',
      'Four rounds',
      'Four rounds',
      'Four rounds',
      'Five rounds',
    ],
    durable: ['No', 'No', 'No', 'No', 'No', 'No', 'No', 'No', 'No', 'No'],
    description: 'A fight with the Kryptios in never a fair fight.',
    effects: [
      'The target receives -3 damage for the duration of this scroll.',
      'The target receives -3 damage for the duration of this scroll.',
      'The target receives -4 damage for the duration of this scroll.',
      'The target receives -4 damage for the duration of this scroll.',
      'The target receives -5 damage for the duration of this scroll.',
      'The target receives -5 damage for the duration of this scroll.',
      'The target receives -6 damage for the duration of this scroll.',
      'The target receives -6 damage for the duration of this scroll.',
      'The target receives -7 damage for the duration of this scroll.',
      'The target receives -7 damage for the duration of this scroll.',
    ],
    fragmentsToLevel: [0, 70, 120, 180, 250, 350, 500, 700, 1000, 1500],
  },
  {
    path: 'Kryptios',
    subpath: '',
    name: 'Evasion',
    rarity: 'Kryptios rare combat scroll',
    attackRoll: [
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
    ],

    defendingStat: 'None',
    priority: [20, 23, 23, 25, 25, 27, 27, 29, 29, 31],
    damage: [
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
    ],
    target: [
      'Self',
      'Self',
      'Self',
      'Self',
      'Self',
      'Self',
      'Self',
      'Self',
      'Self',
      'Self',
    ],

    keyword: 'Buff Agility Defense',
    duration: [
      'One round',
      'One round',
      'One round',
      'One round',
      'Two rounds',
      'Two rounds',
      'Two rounds',
      'Two rounds',
      'Three rounds',
      'Three rounds',
    ],
    durable: ['No', 'No', 'No', 'No', 'No', 'No', 'No', 'No', 'No', 'No'],
    description:
      'What makes a good Kryptios better is the knowledge of when to retreat from a fight.',
    effects: [
      'The Kryptios receives +2d6 to agility defense for the duration of the scroll.',
      'The Kryptios receives +2d6 to agility defense for the duration of the scroll.',
      'The Kryptios receives +2d6 to agility defense for the duration of the scroll..',
      'The Kryptios receives +3d6 to agility defense for the duration of the scroll.',
      'The Kryptios receives +3d6 to agility defense for the duration of the scroll.',
      'The Kryptios receives +3d6 to agility defense for the duration of the scroll.',
      'The Kryptios receives +4d6 to agility defense for the duration of the scroll.',
      'The Kryptios receives +4d6 to agility defense for the duration of the scroll..',
      'The Kryptios receives +4d6 to agility defense for the duration of the scroll.',
      'The Kryptios receives +5d6 to agility defense for the duration of the scroll.',
    ],
    fragmentsToLevel: [0, 70, 120, 180, 250, 350, 500, 700, 1000, 1500],
  },
  {
    path: 'Kryptios',
    subpath: '',
    name: 'Gimme That',
    rarity: 'Kryptios rare combat scroll',
    attackRoll: [
      '3d6',
      '3d6',
      '4d6',
      '4d6',
      '6d6',
      '6d6',
      '7d6',
      '7d6',
      '8d6',
      '8d6',
    ],
    defendingStat: 'Intuition',
    priority: [13, 15, 15, 17, 17, 19, 19, 21, 21, 23],
    damage: [
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
    ],
    target: [
      'One enemy',
      'One enemy',
      'One enemy',
      'One enemy',
      'One enemy',
      'One enemy',
      'One enemy',
      'One enemy',
      'One enemy',
      'One enemy',
    ],

    keyword: 'None',
    duration: [
      'Instant',
      'Instant',
      'Instant',
      'Instant',
      'Instant',
      'Instant',
      'Instant',
      'Instant',
      'Instant',
      'Instant',
    ],

    durable: ['No', 'No', 'No', 'No', 'No', 'No', 'No', 'No', 'No', 'No'],
    description:
      'The Kryptios creates a quick diversion to gain valuable time.',
    effects: [
      'If this scroll succeeds, the Kryptios steals one random attack from the enemy and uses it immediately. The attack roll, the damage and the effects are the same as the target’s’ attack. Buf s/debuf s apply normally to this attack.',
      'If this scroll succeeds, the Kryptios steals one random attack from the enemy and uses it immediately. The attack roll, the damage and the effects are the same as the target’s’ attack. Buf s/debuf s apply normally to this attack.',
      'If this scroll succeeds, the Kryptios steals one random attack from the enemy and uses it immediately. The attack roll, the damage and the effects are the same as the target’s’ attack. Buf s/debuf s apply normally to this attack.',
      'If this scroll succeeds, the Kryptios steals one random attack from the enemy and uses it immediately. The attack roll, the damage and the effects are the same as the target’s’ attack. Buf s/debuf s apply normally to this attack.',
      'If this scroll succeeds, the Kryptios steals one random attack from the enemy and uses it immediately. The attack roll, the damage and the effects are the same as the target’s’ attack. Buf s/debuf s apply normally to this attack.',
      'If this scroll succeeds, the Kryptios steals one random attack from the enemy and uses it immediately. The attack roll, the damage and the effects are the same as the target’s’ attack. Buf s/debuf s apply normally to this attack.',
      'If this scroll succeeds, the Kryptios steals one random attack from the enemy and uses it immediately. The attack roll, the damage and the effects are the same as the target’s’ attack. Buf s/debuf s apply normally to this attack.',
      'If this scroll succeeds, the Kryptios steals one random attack from the enemy and uses it immediately. The attack roll, the damage and the effects are the same as the target’s’ attack. Buf s/debuf s apply normally to this attack.',
      'If this scroll succeeds, the Kryptios steals one random attack from the enemy and uses it immediately. The attack roll, the damage and the effects are the same as the target’s’ attack. Buf s/debuf s apply normally to this attack.',
      'If this scroll succeeds, the Kryptios steals one random attack from the enemy and uses it immediately. The attack roll, the damage and the effects are the same as the target’s’ attack. Buf s/debuf s apply normally to this attack.',
    ],
    fragmentsToLevel: [0, 70, 120, 180, 250, 350, 500, 700, 1000, 1500],
  },
  {
    path: 'Kryptios',
    subpath: '',
    name: 'Misty Strike',
    rarity: 'Kryptios epic combat scroll',
    attackRoll: [
      '2d6',
      '2d6',
      '3d6',
      '3d6',
      '4d6',
      '4d6',
      '5d6',
      '5d6',
      '6d6',
      '6d6',
    ],

    defendingStat: 'Agility',
    priority: [1, 3, 3, 3, 5, 5, 5, 7, 7, 7],
    damage: ['3', '4', '4', '5', '5', '6', '6', '7', '7', '8'],
    target: [
      'One enemy',
      'One enemy',
      'One enemy',
      'One enemy',
      'One enemy',
      'One enemy',
      'One enemy',
      'One enemy',
      'One enemy',
      'One enemy',
    ],

    keyword: 'None',
    duration: [
      'Instant',
      'Instant',
      'Instant',
      'Instant',
      'Instant',
      'Instant',
      'Instant',
      'Instant',
      'Instant',
      'Instant',
    ],

    durable: ['No', 'No', 'No', 'No', 'No', 'No', 'No', 'No', 'No', 'No'],
    description:
      'The Kryptios becomes a blurry mist of steel, striking the target with superhuman speed.',
    effects: [
      'This scroll does not count into the turn allotment and can be used in adittion to the normal rule of one combat scroll per round',
      'This scroll does not count into the turn allotment and can be used in adittion to the normal rule of one combat scroll per round',
      'This scroll does not count into the turn allotment and can be used in adittion to the normal rule of one combat scroll per round',
      'This scroll does not count into the turn allotment and can be used in adittion to the normal rule of one combat scroll per round',
      'This scroll does not count into the turn allotment and can be used in adittion to the normal rule of one combat scroll per round',
      'This scroll does not count into the turn allotment and can be used in adittion to the normal rule of one combat scroll per round',
      'This scroll does not count into the turn allotment and can be used in adittion to the normal rule of one combat scroll per round',
      'This scroll does not count into the turn allotment and can be used in adittion to the normal rule of one combat scroll per round',
      'This scroll does not count into the turn allotment and can be used in adittion to the normal rule of one combat scroll per round',
      'This scroll does not count into the turn allotment and can be used in adittion to the normal rule of one combat scroll per round',
    ],
    fragmentsToLevel: [0, 100, 150, 250, 350, 500, 700, 1000, 1500, 2200],
  },
  {
    path: 'Kryptios',
    subpath: '',
    name: 'Mocking Strike',
    rarity: 'Kryptios rare combat scroll',
    attackRoll: [
      '2d6',
      '2d6',
      '3d6',
      '3d6',
      '4d6',
      '4d6',
      '5d6',
      '5d6',
      '6d6',
      '6d6',
    ],
    defendingStat: 'Agility',
    priority: [17, 19, 19, 21, 21, 23, 23, 25, 25, 27],
    damage: ['5', '7', '7', '9', '9', '12', '12', '15', '15', '18'],
    target: [
      'One enemy',
      'One enemy',
      'One enemy',
      'One enemy',
      'One enemy',
      'One enemy',
      'One enemy',
      'One enemy',
      'One enemy',
      'One enemy',
    ],

    keyword: 'Taunt',
    duration: [
      'Instant',
      'Instant',
      'Instant',
      'Instant',
      'Instant',
      'Instant',
      'Instant',
      'Instant',
      'Instant',
      'Instant',
    ],

    durable: ['No', 'No', 'No', 'No', 'No', 'No', 'No', 'No', 'No', 'No'],
    description:
      'The Kryptios strikes the enemy and hides in the shadows before the target realizes the origin of the attack',
    effects: [
      'If this attack succeeds, the Kryptios can choose an ally and offer him taunt. The next attack of the enemy who received the damage must target the chosen ally.',
      'If this attack succeeds, the Kryptios can choose an ally and offer him taunt. The next attack of the enemy who received the damage must target the chosen ally.',
      'If this attack succeeds, the Kryptios can choose an ally and offer him taunt. The next attack of the enemy who received the damage must target the chosen ally.',
      'If this attack succeeds, the Kryptios can choose an ally and offer him taunt. The next attack of the enemy who received the damage must target the chosen ally.',
      'If this attack succeeds, the Kryptios can choose an ally and offer him taunt. The next two attacks of the enemy who received the damage must target the chosen ally.',
      'If this attack succeeds, the Kryptios can choose an ally and offer him taunt. The next two attacks of the enemy who received the damage must target the chosen ally.',
      'If this attack succeeds, the Kryptios can choose an ally and offer him taunt. The next two attacks of the enemy who received the damage must target the chosen ally.',
      'If this attack succeeds, the Kryptios can choose an ally and offer him taunt. The next two attacks of the enemy who received the damage must target the chosen ally.',
      'If this attack succeeds, the Kryptios can choose an ally and offer him taunt. The next three attacks of the enemy who received the damage must target the chosen ally.',
      'If this attack succeeds, the Kryptios can choose an ally and offer him taunt. The next three attacks of the enemy who received the damage must target the chosen ally.',
    ],
    fragmentsToLevel: [0, 70, 120, 180, 250, 350, 500, 700, 1000, 1500],
  },
  {
    path: 'Kryptios',
    subpath: '',
    name: 'Phantom Assault',
    rarity: 'Kryptios rare combat scroll',
    attackRoll: [
      '2d6',
      '2d6',
      '3d6',
      '3d6',
      '4d6',
      '4d6',
      '5d6',
      '5d6',
      '6d6',
      '6d6',
    ],
    defendingStat: 'Agility',
    priority: [7, 9, 9, 9, 11, 11, 11, 13, 13, 13],
    damage: ['9', '12', '12', '15', '15', '18', '18', '21', '21', '24'],
    target: [
      'One enemy',
      'One enemy',
      'One enemy',
      'One enemy',
      'One enemy',
      'One enemy',
      'One enemy',
      'One enemy',
      'One enemy',
      'One enemy',
    ],

    keyword: 'Critical Strike',
    duration: [
      'Instant',
      'Instant',
      'Instant',
      'Instant',
      'Instant',
      'Instant',
      'Instant',
      'Instant',
      'Instant',
      'Instant',
    ],

    durable: ['No', 'No', 'No', 'No', 'No', 'No', 'No', 'No', 'No', 'No'],
    description:
      'The Kryptios strikes the enemy and hides in the shadows before the target realizes the origin of the attack',
    effects: [
      'If the Kryptios rolls at least 2 sixes in the attack roll, this attack deals 1.5x times the damage rounded up. The damage increase happens before the adittion of any buf /debuf.',
      'If the Kryptios rolls at least 2 sixes in the attack roll, this attack deals 1.5x times the damage rounded up. The damage increase happens before the adittion of any buf /debuf.',
      'If the Kryptios rolls at least 2 sixes in the attack roll, this attack deals 1.5x times the damage rounded up. The damage increase happens before the adittion of any buf /debuf.',
      'If the Kryptios rolls at least 2 sixes in the attack roll, this attack deals 1.5x times the damage rounded up. The damage increase happens before the adittion of any buf /debuf.',
      'If the Kryptios rolls at least 3 sixes in the attack roll, this attack deals 2x times the damage rounded up. The damage increase happens before the adittion of any buf /debuf.',
      'If the Kryptios rolls at least 3 sixes in the attack roll, this attack deals 2x times the damage rounded up. The damage increase happens before the adittion of any buf /debuf.',
      'If the Kryptios rolls at least 3 sixes in the attack roll, this attack deals 2x times the damage rounded up. The damage increase happens before the adittion of any buf /debuf.',
      'If the Kryptios rolls at least 3 sixes in the attack roll, this attack deals 2x times the damage rounded up. The damage increase happens before the adittion of any buf /debuf.',
      'If the Kryptios rolls at least 4 sixes in the attack roll, this attack deals 2.5x times the damage rounded up. The damage increase happens before the adittion of any buf /debuf.',
      'If the Kryptios rolls at least 4 sixes in the attack roll, this attack deals 2.5x times the damage rounded up. The damage increase happens before the adittion of any buf /debuf.',
    ],
    fragmentsToLevel: [0, 70, 120, 180, 250, 350, 500, 700, 1000, 1500],
  },
  {
    path: 'Kryptios',
    subpath: '',
    name: 'Poisoned Blades',
    rarity: 'Kryptios rare combat scroll',
    attackRoll: [
      '2d6',
      '2d6',
      '3d6',
      '3d6',
      '4d6',
      '4d6',
      '5d6',
      '5d6',
      '6d6',
      '6d6',
    ],
    defendingStat: 'Agility',
    priority: [14, 16, 16, 18, 18, 20, 20, 22, 22, 24],
    damage: ['3', '4', '4', '5', '5', '6', '6', '7', '7', '9'],
    target: [
      'One enemy',
      'One enemy',
      'One enemy',
      'One enemy',
      'One enemy',
      'One enemy',
      'One enemy',
      'One enemy',
      'One enemy',
      'One enemy',
    ],

    keyword: 'Repeating',
    duration: [
      'Two rounds',
      'Two rounds',
      'Two rounds',
      'Two rounds',
      'Two rounds',
      'Three rounds',
      'Three rounds',
      'Three rounds',
      'Three rounds',
      'Three rounds',
    ],
    durable: ['No', 'No', 'No', 'No', 'No', 'No', 'No', 'No', 'No', 'No'],
    description:
      'Votanology and poisoncrafting are invaluable assets for the Kryptios.',
    effects: [
      'The damage of this scroll repeats at the start of target’s next turn without needing to roll for a new attack. Any buff or debuff affects only the scroll damage without having any effect to the secondary repeating damage.',
      'The damage of this scroll repeats at the start of target’s next turn without needing to roll for a new attack. Any buff or debuff affects only the scroll damage without having any effect to the secondary repeating damage.',
      'The damage of this scroll repeats at the start of target’s next turn without needing to roll for a new attack. Any buff or debuff affects only the scroll damage without having any efect to the secondary repeating damage.',
      'The damage of this scroll repeats at the start of target’s next turn without needing to roll for a new attack. Any buff or debuff affects only the scroll damage without having any effect to the secondary repeating damage.',
      'The damage of this scroll repeats at the start of target’s next turn without needing to roll for a new attack. Any buff or debuff affects only the scroll damage without having any effect to the secondary repeating damage.',
      'The damage of this scroll repeats at the start of target’s next turn without needing to roll for a new attack. Any buff or debuff affects only the scroll damage without having any effect to the secondary repeating damage.',
      'The damage of this scroll repeats at the start of target’s next turn without needing to roll for a new attack. Any buff or debuff affects only the scroll damage without having any effect to the secondary repeating damage.',
      'The damage of this scroll repeats at the start of target’s next turn without needing to roll for a new attack. Any buff or debuff affects only the scroll damage without having any effect to the secondary repeating damage.',
      'The damage of this scroll repeats at the start of target’s next turn without needing to roll for a new attack. Any buff or debuff affects only the scroll damage without having any effect to the secondary repeating damage.',
      'The damage of this scroll repeats at the start of target’s next turn without needing to roll for a new attack. Any buff or debuff affects only the scroll damage without having any effect to the secondary repeating damage.',
    ],
    fragmentsToLevel: [0, 70, 120, 180, 250, 350, 500, 700, 1000, 1500],
  },
  {
    path: 'Kryptios',
    subpath: '',
    name: 'Shadowstep',
    rarity: 'Kryptios epic combat scroll',
    attackRoll: [
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
    ],

    defendingStat: 'None',
    priority: [7, 9, 9, 9, 11, 11, 11, 13, 13, 13],
    damage: [
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
    ],
    target: [
      'Self',
      'Self',
      'Self',
      'Self',
      'Self',
      'Self',
      'Self',
      'Self',
      'Self',
      'Self',
    ],

    keyword: 'Buff Attack, Buff Damage, Buff Priority',
    duration: [
      'Two rounds',
      'Two rounds',
      'Two rounds',
      'Two rounds',
      'Two rounds',
      'Three rounds',
      'Three rounds',
      'Three rounds',
      'Three rounds',
      'Three rounds',
    ],

    durable: ['No', 'No', 'No', 'No', 'No', 'No', 'No', 'No', 'No', 'No'],
    description:
      'The Kryptios uses the shadows around him to enhance his speed and of ensive capabilities.',
    effects: [
      'The Kryptios receives +1d6 to attack roll +2 to damage and +9 priority.',
      'The Kryptios receives +1d6 to attack roll +2 to damage and +10 priority.',
      'The Kryptios receives +2d6 to attack roll +2 to damage and +10 priority.',
      'The Kryptios receives +2d6 to attack roll +3 to damage and +11 priority.',
      'The Kryptios receives +2d6 to attack roll +3 to damage and +11 priority.',
      'The Kryptios receives +3d6 to attack roll +3 to damage and +12 priority.',
      'The Kryptios receives +3d6 to attack roll +4 to damage and +12 priority.',
      'The Kryptios receives +3d6 to attack roll +4 to damage and +13 priority.',
      'The Kryptios receives +4d6 to attack roll +4 to damage and +13 priority.',
      'The Kryptios receives +4d6 to attack roll +5 to damage and +14 priority.',
    ],
    fragmentsToLevel: [0, 100, 150, 250, 350, 500, 700, 1000, 1500, 2200],
  },
  {
    path: 'Kryptios',
    subpath: '',
    name: 'Silent Strike',
    rarity: 'Kryptios rare combat scroll',
    attackRoll: [
      '2d6',
      '2d6',
      '3d6',
      '3d6',
      '4d6',
      '4d6',
      '5d6',
      '5d6',
      '6d6',
      '6d6',
    ],
    defendingStat: 'Agility',
    priority: [8, 10, 10, 10, 12, 12, 12, 14, 14, 14],
    damage: ['9', '12', '12', '15', '15', '18', '18', '21', '21', '24'],
    target: [
      'One enemy',
      'One enemy',
      'One enemy',
      'One enemy',
      'One enemy',
      'One enemy',
      'One enemy',
      'One enemy',
      'One enemy',
      'One enemy',
    ],

    keyword: 'Sneak Attack',
    duration: [
      'Instant',
      'Instant',
      'Instant',
      'Instant',
      'Instant',
      'Instant',
      'Instant',
      'Instant',
      'Instant',
      'Instant',
    ],

    durable: ['No', 'No', 'No', 'No', 'No', 'No', 'No', 'No', 'No', 'No'],
    description:
      'The Kryptios becomes almost invisible in the shadows to unleash a surprise attack when expected the least.',
    effects: [
      'If the Kryptios acts before his target, this attack deals 1.5x times the damage rounded up. The damage increase happens before the adittion of any buff/debuff.',
      'If the Kryptios acts before his target, this attack deals 1.5x times the damage rounded up. The damage increase happens before the adittion of any buff/debuff.',
      'If the Kryptios acts before his target, this attack deals 1.5x times the damage rounded up. The damage increase happens before the adittion of any buff/debuff.',
      'If the Kryptios acts before his target, this attack deals 1.5x times the damage rounded up. The damage increase happens before the adittion of any buff/debuff.',
      'If the Kryptios acts before his target, this attack deals 1.5x times the damage rounded up. The damage increase happens before the adittion of any buff/debuff.',
      'If the Kryptios acts before his target, this attack deals 1.5x times the damage rounded up. The damage increase happens before the adittion of any buff/debuff.',
      'If the Kryptios acts before his target, this attack deals 1.5x times the damage rounded up. The damage increase happens before the adittion of any buff/debuff.',
      'If the Kryptios acts before his target, this attack deals 1.5x times the damage rounded up. The damage increase happens before the adittion of any buff/debuff.',
      'If the Kryptios acts before his target, this attack deals 1.5x times the damage rounded up. The damage increase happens before the adittion of any buff/debuff.',
      'If the Kryptios acts before his target, this attack deals 1.5x times the damage rounded up. The damage increase happens before the adittion of any buff/debuff.',
    ],
    fragmentsToLevel: [0, 70, 120, 180, 250, 350, 500, 700, 1000, 1500],
  },
  {
    path: 'Kryptios',
    subpath: '',
    name: 'Veiled Strike',
    rarity: 'Kryptios rare combat scroll',
    attackRoll: [
      '3d6',
      '3d6',
      '4d6',
      '4d6',
      '6d6',
      '6d6',
      '7d6',
      '7d6',
      '8d6',
      '8d6',
    ],

    defendingStat: 'Agility',
    priority: [8, 10, 10, 10, 12, 12, 12, 14, 14, 14],
    damage: ['5', '7', '7', '9', '9', '12', '12', '15', '15', '18'],

    target: [
      'One enemy',
      'One enemy',
      'One enemy',
      'One enemy',
      'One enemy',
      'One enemy',
      'One enemy',
      'One enemy',
      'One enemy',
      'One enemy',
    ],

    keyword: 'Enervating Blow',
    duration: [
      'Instant',
      'Instant',
      'Instant',
      'Instant',
      'Instant',
      'Instant',
      'Instant',
      'Instant',
      'Instant',
      'Instant',
    ],

    durable: ['No', 'No', 'No', 'No', 'No', 'No', 'No', 'No', 'No', 'No'],
    description:
      'The Kryptios becomes almost invisible in the shadows to find the right moment to strike. ',
    effects: [
      'If the target receives any damage beofre the end of his next turn, he receives an aditional 3 damage. This damage cannot be affected from buffs/debuffs.',
      'If the target receives any damage beofre the end of his next turn, he receives an aditional 3 damage. This damage cannot be affected from buffs/debuffs.',
      'If the target receives any damage beofre the end of his next turn, he receives an aditional 3 damage. This damage cannot be affected from buffs/debuffs.',
      'If the target receives any damage beofre the end of his next turn, he receives an aditional 3 damage. This damage cannot be affected from buffs/debuffs.',
      'If the target receives any damage beofre the end of his next turn, he receives an aditional 3 damage. This damage cannot be affected from buffs/debuffs.',
      'If the target receives any damage beofre the end of his next turn, he receives an aditional 3 damage. This damage cannot be affected from buffs/debuffs.',
      'If the target receives any damage beofre the end of his next turn, he receives an aditional 3 damage. This damage cannot be affected from buffs/debuffs.',
      'If the target receives any damage beofre the end of his next turn, he receives an aditional 3 damage. This damage cannot be affected from buffs/debuffs.',
      'If the target receives any damage beofre the end of his next turn, he receives an aditional 3 damage. This damage cannot be affected from buffs/debuffs.',
      'If the target receives any damage beofre the end of his next turn, he receives an aditional 3 damage. This damage cannot be affected from buffs/debuffs.',
    ],
    fragmentsToLevel: [0, 70, 120, 180, 250, 350, 500, 700, 1000, 1500],
  },
  {
    path: 'Kryptios',
    subpath: 'Assassin',
    name: 'Art of Silence',
    rarity: 'Kryptios epic combat scroll',
    attackRoll: [
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
    ],
    defendingStat: 'None',
    priority: ['None', 'None', 'None', 'None', 'None', 'None', 'None', 'None', 'None', 'None'],
    damage: [
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
    ],
    target: [
      'Self',
      'Self',
      'Self',
      'Self',
      'Self',
      'Self',
      'Self',
      'Self',
      'Self',
      'Self',
    ],

    keyword: 'Passive ',
    duration: [
      ' Until the end of Combat',
      ' Until the end of Combat',
      ' Until the end of Combat',
      ' Until the end of Combat',
      ' Until the end of Combat',
      ' Until the end of Combat',
      ' Until the end of Combat',
      ' Until the end of Combat',
      ' Until the end of Combat',
      ' Until the end of Combat',
    ],
    durable: ['No', 'No', 'No', 'No', 'No', 'No', 'No', 'No', 'No', 'No'],
    description:
      'The Kryptios becomes a blurry mist of shadows.',
    effects: [
      'This is a passive scroll that enhances the abilities of other Assassin scrolls as follows:\nAll combat scrolls with the keyword "Sneak Attack" increase their Priority by +3 (stacking with buffs).\nAll combat scrolls with the keyword "Repeating" receive +2 damage to their secondary damage.',
      'This is a passive scroll that enhances the abilities of other Assassin scrolls as follows:\nAll combat scrolls with the keyword "Sneak Attack" increase their Priority by +3 (stacking with buffs).\nAll combat scrolls with the keyword "Repeating" receive +2 damage to their secondary damage.',
      'This is a passive scroll that enhances the abilities of other Assassin scrolls as follows:\nAll combat scrolls with the keyword "Sneak Attack" increase their Priority by +3 (stacking with buffs).\nAll combat scrolls with the keyword "Repeating" receive +2 damage to their secondary damage.',
      'This is a passive scroll that enhances the abilities of other Assassin scrolls as follows:\nAll combat scrolls with the keyword "Sneak Attack" increase their Priority by +3 (stacking with buffs).\nAll combat scrolls with the keyword "Repeating" receive +2 damage to their secondary damage.',
      'This is a passive scroll that enhances the abilities of other Assassin scrolls as follows:\nAll combat scrolls with the keyword "Sneak Attack" increase their Priority by +3 (stacking with buffs).\nAll combat scrolls with the keyword "Repeating" receive +2 damage to their secondary damage.',
      'This is a passive scroll that enhances the abilities of other Assassin scrolls as follows:\nAll combat scrolls with the keyword "Sneak Attack" increase their Priority by +3 (stacking with buffs).\nAll combat scrolls with the keyword "Repeating" receive +2 damage to their secondary damage.',
      'This is a passive scroll that enhances the abilities of other Assassin scrolls as follows:\nAll combat scrolls with the keyword "Sneak Attack" increase their Priority by +3 (stacking with buffs).\nAll combat scrolls with the keyword "Repeating" receive +2 damage to their secondary damage.',
      'This is a passive scroll that enhances the abilities of other Assassin scrolls as follows:\nAll combat scrolls with the keyword "Sneak Attack" increase their Priority by +3 (stacking with buffs).\nAll combat scrolls with the keyword "Repeating" receive +2 damage to their secondary damage.',
      'This is a passive scroll that enhances the abilities of other Assassin scrolls as follows:\nAll combat scrolls with the keyword "Sneak Attack" increase their Priority by +3 (stacking with buffs).\nAll combat scrolls with the keyword "Repeating" receive +2 damage to their secondary damage.',
      'This is a passive scroll that enhances the abilities of other Assassin scrolls as follows:\nAll combat scrolls with the keyword "Sneak Attack" increase their Priority by +3 (stacking with buffs).\nAll combat scrolls with the keyword "Repeating" receive +2 damage to their secondary damage.',
    ],
    fragmentsToLevel: [0, 100, 150, 250, 350, 500, 700, 1000, 1500, 2200],
  },
  {
    path: 'Kryptios',
    subpath: 'Assassin',
    name: 'Art of Death',
    rarity: 'Kryptios epic combat scroll',
    attackRoll: [
      '2d6',
      '2d6',
      '3d6',
      '3d6',
      '4d6',
      '4d6',
      '5d6',
      '5d6',
      '6d6',
      '6d6',

    ],
    defendingStat: 'Intuition',
    priority: [17, 19, 19, 19, 21, 21, 21, 23, 23, 23],
    damage: [
      '7',
      '9',
      '9',
      '11',
      '11',
      '13',
      '13',
      '15',
      '15',
      '17',
    ],
    target: [
      'One enemy',
      'One enemy',
      'One enemy',
      'One enemy',
      'One enemy',
      'One enemy',
      'One enemy',
      'One enemy',
      'One enemy',
      'One enemy',
    ],

    keyword: 'Repeating, sneak attack ',
    duration: [
      'Instant',
      'Instant',
      'Instant',
      'Instant',
      'Instant',
      'Instant',
      'Instant',
      'Instant',
      'Instant',
      'Instant',
    ],
    durable: ['No', 'No', 'No', 'No', 'No', 'No', 'No', 'No', 'No', 'No'],
    description:
      'The Assassin has spent countless hours training the destructive technique.',
    effects: [
      'The damage of this scroll repeats at the beginning of target’s new round without the need to roll a new attack roll. Any buff or debuff applies only to the first attack.  ',
      'The damage of this scroll repeats at the beginning of target’s new round without the need to roll a new attack roll. Any buff or debuff applies only to the first attack.  ',
      'The damage of this scroll repeats at the beginning of target’s new round without the need to roll a new attack roll. Any buff or debuff applies only to the first attack.  ',
      'The damage of this scroll repeats at the beginning of target’s new round without the need to roll a new attack roll. Any buff or debuff applies only to the first attack.  ',
      'The damage of this scroll repeats at the beginning of target’s new round without the need to roll a new attack roll. Any buff or debuff applies only to the first attack.  ',
      'The damage of this scroll repeats at the beginning of target’s new round without the need to roll a new attack roll. Any buff or debuff applies only to the first attack.  ',
      'The damage of this scroll repeats at the beginning of target’s new round without the need to roll a new attack roll. Any buff or debuff applies only to the first attack.  ',
      'The damage of this scroll repeats at the beginning of target’s new round without the need to roll a new attack roll. Any buff or debuff applies only to the first attack.  ',
      'The damage of this scroll repeats at the beginning of target’s new round without the need to roll a new attack roll. Any buff or debuff applies only to the first attack.  ',
      'The damage of this scroll repeats at the beginning of target’s new round without the need to roll a new attack roll. Any buff or debuff applies only to the first attack.  ',
    ],
    fragmentsToLevel: [0, 100, 150, 250, 350, 500, 700, 1000, 1500, 2200],
  },
  {
    path: 'Kryptios',
    subpath: 'Shadowdancer',
    name: 'Shield of Darkness',
    rarity: 'Kryptios epic combat scroll',
    attackRoll: [
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
    ],
    defendingStat: 'None',
    priority: ['None', 'None', 'None', 'None', 'None', 'None', 'None', 'None', 'None', 'None'],
    damage: [
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
    ],
    target: [
      'Self',
      'Self',
      'Self',
      'Self',
      'Self',
      'Self',
      'Self',
      'Self',
      'Self',
      'Self',
    ],

    keyword: 'Passive ',
    duration: [
      ' Until the end of Combat',
      ' Until the end of Combat',
      ' Until the end of Combat',
      ' Until the end of Combat',
      ' Until the end of Combat',
      ' Until the end of Combat',
      ' Until the end of Combat',
      ' Until the end of Combat',
      ' Until the end of Combat',
      ' Until the end of Combat',
    ],
    durable: ['No', 'No', 'No', 'No', 'No', 'No', 'No', 'No', 'No', 'No'],
    description:
      ' The Shadowdancer envelops himself with the darkness making him gradually more difficult to be hit or even seen.',
    effects: [
      'This is a passive scroll which is active from the beginning of combat.Each time the Shadowdancer receives a successful attack targeting his agility, he gets a cumulative +1 to Agility defense (up to +4) until the end of combat. This scroll stacks with other buffs and empowerment.  ',
      'This is a passive scroll which is active from the beginning of combat.Each time the Shadowdancer receives a successful attack targeting his agility, he gets a cumulative +1 to Agility defense (up to +4) until the end of combat. This scroll stacks with other buffs and empowerment.  ',
      'This is a passive scroll which is active from the beginning of combat.Each time the Shadowdancer receives a successful attack targeting his agility, he gets a cumulative +1 to Agility defense (up to +5) until the end of combat. This scroll stacks with other buffs and empowerment.  ',
      'This is a passive scroll which is active from the beginning of combat.Each time the Shadowdancer receives a successful attack targeting his agility, he gets a cumulative +1 to Agility defense (up to +5) until the end of combat. This scroll stacks with other buffs and empowerment.  ',
      'This is a passive scroll which is active from the beginning of combat.Each time the Shadowdancer receives a successful attack targeting his agility, he gets a cumulative +2 to Agility defense (up to +5) until the end of combat. This scroll stacks with other buffs and empowerment.  ',
      'This is a passive scroll which is active from the beginning of combat.Each time the Shadowdancer receives a successful attack targeting his agility, he gets a cumulative +2 to Agility defense (up to +6) until the end of combat. This scroll stacks with other buffs and empowerment.  ',
      'This is a passive scroll which is active from the beginning of combat.Each time the Shadowdancer receives a successful attack targeting his agility, he gets a cumulative +2 to Agility defense (up to +6) until the end of combat. This scroll stacks with other buffs and empowerment.  ',
      'This is a passive scroll which is active from the beginning of combat.Each time the Shadowdancer receives a successful attack targeting his agility, he gets a cumulative +3 to Agility defense (up to +6) until the end of combat. This scroll stacks with other buffs and empowerment.  ',
      'This is a passive scroll which is active from the beginning of combat.Each time the Shadowdancer receives a successful attack targeting his agility, he gets a cumulative +3 to Agility defense (up to +7) until the end of combat. This scroll stacks with other buffs and empowerment.  ',
      'This is a passive scroll which is active from the beginning of combat.Each time the Shadowdancer receives a successful attack targeting his agility, he gets a cumulative +3 to Agility defense (up to +7) until the end of combat. This scroll stacks with other buffs and empowerment.  ',
    ],
    fragmentsToLevel: [0, 100, 150, 250, 350, 500, 700, 1000, 1500, 2200],
  },
  {
    path: 'Kryptios',
    subpath: 'Shadowdancer',
    name: 'Emerging from the Shadows',
    rarity: 'Kryptios epic combat scroll',
    attackRoll: [
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
    ],
    defendingStat: 'None',
    priority: ['None', 'None', 'None', 'None', 'None', 'None', 'None', 'None', 'None', 'None'],
    damage: [
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
    ],
    target: [
      'Self',
      'Self',
      'Self',
      'Self',
      'Self',
      'Self',
      'Self',
      'Self',
      'Self',
      'Self',
    ],
    keyword: 'Repeating ',
    duration: [
      ' Until the end of Combat',
      ' Until the end of Combat',
      ' Until the end of Combat',
      ' Until the end of Combat',
      ' Until the end of Combat',
      ' Until the end of Combat',
      ' Until the end of Combat',
      ' Until the end of Combat',
      ' Until the end of Combat',
      ' Until the end of Combat',
    ],
    durable: ['No', 'No', 'No', 'No', 'No', 'No', 'No', 'No', 'No', 'No'],
    description:
      ' Each failed attack towards the Shadowdancer allows him to vanish back to the shadows and prepare his counterattack.',
    effects: [
      'Every time the Shadowdancer successfully defends an attack targeting his Agility defense, he replenishes the combat scroll Misty Strike. ',
      'Every time the Shadowdancer successfully defends an attack targeting his Agility defense, he replenishes the combat scroll Misty Strike. ',
      'Every time the Shadowdancer successfully defends an attack targeting his Agility defense, he replenishes the combat scroll Misty Strike. ',
      'Every time the Shadowdancer successfully defends an attack targeting his Agility defense, he replenishes the combat scroll Misty Strike. ',
      'Every time the Shadowdancer successfully defends an attack targeting his Agility defense, he replenishes the combat scroll Misty Strike. ',
      'Every time the Shadowdancer successfully defends an attack targeting his Agility defense, he replenishes the combat scroll Misty Strike. ',
      'Every time the Shadowdancer successfully defends an attack targeting his Agility defense, he replenishes the combat scroll Misty Strike. ',
      'Every time the Shadowdancer successfully defends an attack targeting his Agility defense, he replenishes the combat scroll Misty Strike. ',
      'Every time the Shadowdancer successfully defends an attack targeting his Agility defense, he replenishes the combat scroll Misty Strike. ',
      'Every time the Shadowdancer successfully defends an attack targeting his Agility defense, he replenishes the combat scroll Misty Strike. ',
    ],
    fragmentsToLevel: [0, 100, 150, 250, 350, 500, 700, 1000, 1500, 2200],
  },
  {
    path: 'Myrmidon',
    subpath: '',
    name: 'Ancestor’s Resolve',
    rarity: 'Myrmidon rare combat scroll',
    attackRoll: [
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
    ],
    defendingStat: 'None',
    priority: [17, 19, 19, 21, 21, 23, 23, 25, 25, 27],
    damage: [
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
    ],

    target: [
      'One Ally',
      'One Ally',
      'One Ally',
      'One Ally',
      'One Ally',
      'One Ally',
      'One Ally',
      'One Ally',
      'One Ally',
      'One Ally',
    ],

    keyword: 'Buff Intuition Defense, temp hp',
    duration: [
      'Two rounds',
      'Two rounds',
      'Two rounds',
      'Two rounds',
      'Three rounds',
      'Three rounds',
      'Three rounds',
      'Three rounds',
      'Four rounds',
      'Four rounds',
    ],

    durable: ['No', 'No', 'No', 'No', 'No', 'No', 'No', 'No', 'No', 'No'],
    description:
      'The spectral warrior protects the mind of the target empowering his mental defenses as well as his endurance towards incoming attacks. ',
    effects: [
      'The target receives +1d6 Intuition defense and 5 temporary hit points.',
      'The target receives +1d6 Intuition defense and 5 temporary hit points.',
      'The target receives +1d6 Intuition defense and 7 temporary hit points.',
      'The target receives +2d6 Intuition defense and 7 temporary hit points.',
      'The target receives +2d6 Intuition defense and 9 temporary hit points.',
      'The target receives +2d6 Intuition defense and 9 temporary hit points.',
      'The target receives +3d6 Intuition defense and 11 temporary hit points.',
      'The target receives +3d6 Intuition defense and 11 temporary hit points.',
      'The target receives +3d6 Intuition defense and 13 temporary hit points.',
      'The target receives +3d6 Intuition defense and 13 temporary hit points.',
    ],
    fragmentsToLevel: [0, 70, 120, 180, 250, 350, 500, 700, 1000, 1500],
  },
  {
    path: 'Myrmidon',
    subpath: '',
    name: 'Blow for Blow',
    rarity: 'Legendary rare combat scroll',
    attackRoll: [
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
    ],
    defendingStat: 'None',
    priority: [15, 17, 17, 19, 19, 21, 21, 23, 23, 25],
    damage: [
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
    ],
    target: [
      'One enemy',
      'One enemy',
      'One enemy',
      'One enemy',
      'One enemy',
      'One enemy',
      'One enemy',
      'One enemy',
      'One enemy',
      'One enemy',
    ],
    keyword: 'Taunt',
    duration: [
      'Two rounds',
      'Two rounds',
      'Two rounds',
      'Two rounds',
      'Two rounds',
      'Three rounds',
      'Three rounds',
      'Three rounds',
      'Three rounds',
      'Three rounds',
    ],

    durable: ['No', 'No', 'No', 'No', 'No', 'No', 'No', 'No', 'No', 'No'],
    description: 'The Myrmidon breaks the line and jumps into the fray. ',
    effects: [
      'Whenever the Myrmidon receives an attack (sucessfull or unsucesfull) from his targeted enemy, he can immediately strike back with his weapon strike scroll for the duration of the scroll. Aditionally, the first attack of his selected target must target the Myrmidon. If the Myrmidon receives multiple attacks from the same target, he can strike back to any of them',
      'Whenever the Myrmidon receives an attack (sucessfull or unsucesfull) from his targeted enemy, he can immediately strike back with his weapon strike scroll for the duration of the scroll. Aditionally, the first attack of his selected target must target the Myrmidon. If the Myrmidon receives multiple attacks from the same target, he can strike back to any of them',
      'Whenever the Myrmidon receives an attack (sucessfull or unsucesfull) from his targeted enemy, he can immediately strike back with his weapon strike scroll for the duration of the scroll. Aditionally, the first attack of his selected target must target the Myrmidon. If the Myrmidon receives multiple attacks from the same target, he can strike back to any of them',
      'Whenever the Myrmidon receives an attack (sucessfull or unsucesfull) from his targeted enemy, he can immediately strike back with his weapon strike scroll for the duration of the scroll. Aditionally, the first attack of his selected target must target the Myrmidon. If the Myrmidon receives multiple attacks from the same target, he can strike back to any of them',
      'Whenever the Myrmidon receives an attack (sucessfull or unsucesfull) from his targeted enemy, he can immediately strike back with his weapon strike scroll for the duration of the scroll. Aditionally, the first attack of his selected target must target the Myrmidon. If the Myrmidon receives multiple attacks from the same target, he can strike back to any of them',
      'Whenever the Myrmidon receives an attack (sucessfull or unsucesfull) from his targeted enemy, he can immediately strike back with his weapon strike scroll for the duration of the scroll. Aditionally, the first attack of his selected target must target the Myrmidon. If the Myrmidon receives multiple attacks from the same target, he can strike back to any of them',
      'Whenever the Myrmidon receives an attack (sucessfull or unsucesfull) from his targeted enemy, he can immediately strike back with his weapon strike scroll for the duration of the scroll. Aditionally, the first attack of his selected target must target the Myrmidon. If the Myrmidon receives multiple attacks from the same target, he can strike back to any of them',
      'Whenever the Myrmidon receives an attack (sucessfull or unsucesfull) from his targeted enemy, he can immediately strike back with his weapon strike scroll for the duration of the scroll. Aditionally, the first attack of his selected target must target the Myrmidon. If the Myrmidon receives multiple attacks from the same target, he can strike back to any of them',
      'Whenever the Myrmidon receives an attack (sucessfull or unsucesfull) from his targeted enemy, he can immediately strike back with his weapon strike scroll for the duration of the scroll. Aditionally, the first attack of his selected target must target the Myrmidon. If the Myrmidon receives multiple attacks from the same target, he can strike back to any of them',
      'Whenever the Myrmidon receives an attack (sucessfull or unsucesfull) from his targeted enemy, he can immediately strike back with his weapon strike scroll for the duration of the scroll. Aditionally, the first attack of his selected target must target the Myrmidon. If the Myrmidon receives multiple attacks from the same target, he can strike back to any of them',
    ],
    fragmentsToLevel: [0, 150, 250, 400, 600, 850, 1200, 1600, 2200, 3000],
  },
  {
    path: 'Myrmidon',
    subpath: '',
    name: 'Come and Get Them',
    rarity: 'Legendary rare combat scroll',
    attackRoll: [
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
    ],
    defendingStat: 'None',
    priority: [
      'Interrupt',
      'Interrupt',
      'Interrupt',
      'Interrupt',
      'Interrupt',
      'Interrupt',
      'Interrupt',
      'Interrupt',
      'Interrupt',
      'Interrupt',
    ],
    damage: [
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
    ],
    target: [
      'One enemy',
      'One enemy',
      'One enemy',
      'One enemy',
      'One enemy',
      'One enemy',
      'One enemy',
      'One enemy',
      'One enemy',
      'One enemy',
    ],
    keyword: 'None',
    duration: [
      'Instant',
      'Instant',
      'Instant',
      'Instant',
      'Instant',
      'Instant',
      'Instant',
      'Instant',
      'Instant',
      'Instant',
    ],

    durable: ['No', 'No', 'No', 'No', 'No', 'No', 'No', 'No', 'No', 'No'],
    description:
      'The spectal warrior materializes and protects the Myrmidon from a strike that could be fatal ',
    effects: [
      'This scroll activates automatically when the Myrmidon receives any damage which drops him below 1 hit point. Instead of receiving the damage, he remains to 1 hit point. Additionally, he can immediately strike back with his weapon strike scroll. This scroll doesn not count into the turn allotment and can be triggered in adittion to the noraml rule of one scroll per round',
      'This scroll activates automatically when the Myrmidon receives any damage which drops him below 1 hit point. Instead of receiving the damage, he remains to 1 hit point. Additionally, he can immediately strike back with his weapon strike scroll. This scroll doesn not count into the turn allotment and can be triggered in adittion to the noraml rule of one scroll per round',
      'This scroll activates automatically when the Myrmidon receives any damage which drops him below 1 hit point. Instead of receiving the damage, he remains to 1 hit point. Additionally, he can immediately strike back with his weapon strike scroll. This scroll doesn not count into the turn allotment and can be triggered in adittion to the noraml rule of one scroll per round',
      'This scroll activates automatically when the Myrmidon receives any damage which drops him below 1 hit point. Instead of receiving the damage, he remains to 1 hit point. Additionally, he can immediately strike back with his weapon strike scroll. This scroll doesn not count into the turn allotment and can be triggered in adittion to the noraml rule of one scroll per round',
      'This scroll activates automatically when the Myrmidon receives any damage which drops him below 1 hit point. Instead of receiving the damage, he remains to 1 hit point. Additionally, he can immediately strike back with his weapon strike scroll. This scroll doesn not count into the turn allotment and can be triggered in adittion to the noraml rule of one scroll per round',
      'This scroll activates automatically when the Myrmidon receives any damage which drops him below 1 hit point. Instead of receiving the damage, he remains to 1 hit point. Additionally, he can immediately strike back with his weapon strike scroll. This scroll doesn not count into the turn allotment and can be triggered in adittion to the noraml rule of one scroll per round',
      'This scroll activates automatically when the Myrmidon receives any damage which drops him below 1 hit point. Instead of receiving the damage, he remains to 1 hit point. Additionally, he can immediately strike back with his weapon strike scroll. This scroll doesn not count into the turn allotment and can be triggered in adittion to the noraml rule of one scroll per round',
      'This scroll activates automatically when the Myrmidon receives any damage which drops him below 1 hit point. Instead of receiving the damage, he remains to 1 hit point. Additionally, he can immediately strike back with his weapon strike scroll. This scroll doesn not count into the turn allotment and can be triggered in adittion to the noraml rule of one scroll per round',
      'This scroll activates automatically when the Myrmidon receives any damage which drops him below 1 hit point. Instead of receiving the damage, he remains to 1 hit point. Additionally, he can immediately strike back with his weapon strike scroll. This scroll doesn not count into the turn allotment and can be triggered in adittion to the noraml rule of one scroll per round',
      'This scroll activates automatically when the Myrmidon receives any damage which drops him below 1 hit point. Instead of receiving the damage, he remains to 1 hit point. Additionally, he can immediately strike back with his weapon strike scroll. This scroll doesn not count into the turn allotment and can be triggered in adittion to the noraml rule of one scroll per round',
    ],
    fragmentsToLevel: [0, 150, 250, 400, 600, 850, 1200, 1600, 2200, 3000],
  },
  {
    path: 'Myrmidon',
    subpath: '',
    name: 'Crippling Strike',
    rarity: 'Myrmidon rare combat scroll',
    attackRoll: [
      '2d6',
      '2d6',
      '3d6',
      '3d6',
      '4d6',
      '4d6',
      '5d6',
      '5d6',
      '6d6',
      '6d6',
    ],
    defendingStat: 'Intuition',
    priority: [16, 18, 18, 20, 20, 22, 22, 24, 24, 26],
    damage: ['5', '7', '7', '9', '9', '12', '12', '15', '15', '18'],
    target: [
      'One enemy',
      'One enemy',
      'One enemy',
      'One enemy',
      'One enemy',
      'One enemy',
      'One enemy',
      'One enemy',
      'One enemy',
      'One enemy',
    ],
    keyword: 'Stun',
    duration: [
      'Instant',
      'Instant',
      'Instant',
      'Instant',
      'Instant',
      'Instant',
      'Instant',
      'Instant',
      'Instant',
      'Instant',
    ],
    durable: ['No', 'No', 'No', 'No', 'No', 'No', 'No', 'No', 'No', 'No'],
    description:
      'The spectral ancient warrior unleashes a spiritual attack to the mind of the target attempting to break his defenses and paralyze him',
    effects: [
      'If the Myrmidon succeeds in this attack, the target skips his next action.',
      'If the Myrmidon succeeds in this attack, the target skips his next action.',
      'If the Myrmidon succeeds in this attack, the target skips his next action.',
      'If the Myrmidon succeeds in this attack, the target skips his next action.',
      'If the Myrmidon succeeds in this attack, the target skips his next action.',
      'If the Myrmidon succeeds in this attack, the target skips his next action.',
      'If the Myrmidon succeeds in this attack, the target skips his next action.',
      'If the Myrmidon succeeds in this attack, the target skips his next action.',
      'If the Myrmidon succeeds in this attack, the target skips his next action.',
      'If the Myrmidon succeeds in this attack, the target skips his next action.',
    ],
    fragmentsToLevel: [0, 70, 120, 180, 250, 350, 500, 700, 1000, 1500],
  },
  {
    path: 'Myrmidon',
    subpath: '',
    name: 'Lineage Shield',
    rarity: 'Myrmidon epic combat scroll',
    attackRoll: [
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
    ],
    defendingStat: 'None',
    priority: [15, 17, 17, 19, 19, 21, 21, 23, 23, 25],
    damage: [
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
    ],
    target: [
      'One enemy',
      'One enemy',
      'One enemy',
      'One enemy',
      'One enemy',
      'One enemy',
      'One enemy',
      'One enemy',
      'One enemy',
      'One enemy',
    ],
    keyword: 'Cleanse',
    duration: [
      'Two rounds',
      'Two rounds',
      'Two rounds',
      'Two rounds',
      'Three rounds',
      'Three rounds',
      'Three rounds',
      'Three rounds',
      'Four rounds',
      'Four rounds',
    ],

    durable: ['No', 'No', 'No', 'No', 'No', 'No', 'No', 'No', 'No', 'No'],
    description:
      'The spectral warrior protects the mind of the target empowering his mental defenses as well as his endurance towards incoming attacks. ',
    effects: [
      'The Myrmidon can choose one debuff affecting him, ending its effects immediately.Adittionally, the next attack targeting the Myrmidon for the duration of the scroll does not deal any damage.',
      'The Myrmidon can choose one debuff affecting him, ending its effects immediately.Adittionally, the next attack targeting the Myrmidon for the duration of the scroll does not deal any damage.',
      'The Myrmidon can choose one debuff affecting him, ending its effects immediately.Adittionally, the next attack targeting the Myrmidon for the duration of the scroll does not deal any damage.',
      'The Myrmidon can choose one debuff affecting him, ending its effects immediately.Adittionally, the next attack targeting the Myrmidon for the duration of the scroll does not deal any damage.',
      'The Myrmidon can choose one debuff affecting him, ending its effects immediately.Adittionally, the next attack targeting the Myrmidon for the duration of the scroll does not deal any damage.',
      'The Myrmidon can choose one debuff affecting him, ending its effects immediately.Adittionally, the next attack targeting the Myrmidon for the duration of the scroll does not deal any damage.',
      'The Myrmidon can choose one debuff affecting him, ending its effects immediately.Adittionally, the next attack targeting the Myrmidon for the duration of the scroll does not deal any damage.',
      'The Myrmidon can choose one debuff affecting him, ending its effects immediately.Adittionally, the next attack targeting the Myrmidon for the duration of the scroll does not deal any damage.',
      'The Myrmidon can choose one debuff affecting him, ending its effects immediately.Adittionally, the next attack targeting the Myrmidon for the duration of the scroll does not deal any damage.',
      'The Myrmidon can choose one debuff affecting him, ending its effects immediately.Adittionally, the next attack targeting the Myrmidon for the duration of the scroll does not deal any damage.',
    ],
    fragmentsToLevel: [0, 100, 150, 250, 350, 500, 700, 1000, 1500, 2200],
  },
  {
    path: 'Myrmidon',
    subpath: '',
    name: 'Precision Strikes',
    rarity: 'Myrmidon epic combat scroll',
    attackRoll: [
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
    ],
    defendingStat: 'None',
    priority: [18, 20, 20, 22, 22, 25, 25, 27, 27, 30],
    damage: [
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
    ],
    target: [
      'One Ally',
      'One Ally',
      'Two Ally',
      'Two Ally',
      'Two Ally',
      'Three Ally',
      'Three Ally',
      'Three Ally',
      'Four Ally',
      'Four Ally',
    ],
    keyword: 'Cleanse',
    duration: [
      'Two rounds',
      'Three rounds',
      'Three rounds',
      'Three rounds',
      'Four rounds',
      'Four rounds',
      'Four rounds',
      'Four rounds',
      'Five rounds',
      'Five rounds',
    ],

    durable: [
      'Yes',
      'Yes',
      'Yes',
      'Yes',
      'Yes',
      'Yes',
      'Yes',
      'Yes',
      'Yes',
      'Yes',
    ],
    description: 'The combat experience of the Myrmidon is unsurpassable. ',
    effects: [
      'Allies gain 2 counterattack, returning 2 damage when targeted by enemies.',
      'Allies gain 2 counterattack, returning 2 damage when targeted by enemies.',
      'Allies gain 2 counterattack, returning 2 damage when targeted by enemies.',
      'Allies gain 3 counterattack, returning 2 damage when targeted by enemies.',
      'Allies gain 3 counterattack, returning 2 damage when targeted by enemies.',
      'Allies gain 3 counterattack, returning 2 damage when targeted by enemies.',
      'Allies gain 4 counterattack, returning 2 damage when targeted by enemies.',
      'Allies gain 4 counterattack, returning 2 damage when targeted by enemies.',
      'Allies gain 4 counterattack, returning 2 damage when targeted by enemies.',
      'Allies gain 5 counterattack, returning 2 damage when targeted by enemies.',
    ],
    fragmentsToLevel: [0, 100, 150, 250, 350, 500, 700, 1000, 1500, 2200],
  },
  {
    path: 'Myrmidon',
    subpath: '',
    name: 'Rending Slash',
    rarity: 'Myrmidon rare combat scroll',
    attackRoll: [
      '2d6',
      '2d6',
      '3d6',
      '3d6',
      '4d6',
      '4d6',
      '5d6',
      '5d6',
      '6d6',
      '6d6',
    ],
    defendingStat: 'Agility',
    priority: [14, 16, 16, 18, 18, 20, 20, 22, 22, 24],
    damage: ['5', '7', '7', '9', '9', '12', '12', '15', '15', '18'],

    target: [
      'One enemy',
      'One enemy',
      'One enemy',
      'One enemy',
      'One enemy',
      'One enemy',
      'One enemy',
      'One enemy',
      'One enemy',
      'One enemy',
    ],

    keyword: 'Revenge',
    duration: [
      'Instant',
      'Instant',
      'Instant',
      'Instant',
      'Instant',
      'Instant',
      'Instant',
      'Instant',
      'Instant',
      'Instant',
    ],

    durable: ['No', 'No', 'No', 'No', 'No', 'No', 'No', 'No', 'No', 'No'],
    description:
      'The Myrmidon rends and tears through flesh and bone while paying attention for any opening in opponents guard to take advantage of',
    effects: [
      'If the Myrmidon becomes the target of an attack before the end of his next turn, the attacker receives 3 damage and this effect ends ',
      'If the Myrmidon becomes the target of an attack before the end of his next turn, the attacker receives 4 damage and this effect ends ',
      'If the Myrmidon becomes the target of an attack before the end of his next turn, the attacker receives 4 damage and this effect ends ',
      'If the Myrmidon becomes the target of an attack before the end of his next turn, the attacker receives 5 damage and this effect ends ',
      'If the Myrmidon becomes the target of an attack before the end of his next turn, the attacker receives 5 damage and this effect ends ',
      'If the Myrmidon becomes the target of an attack before the end of his next turn, the attacker receives 6 damage and this effect ends ',
      'If the Myrmidon becomes the target of an attack before the end of his next turn, the attacker receives 6 damage and this effect ends ',
      'If the Myrmidon becomes the target of an attack before the end of his next turn, the attacker receives 7 damage and this effect ends ',
      'If the Myrmidon becomes the target of an attack before the end of his next turn, the attacker receives 7 damage and this effect ends ',
      'If the Myrmidon becomes the target of an attack before the end of his next turn, the attacker receives 9 damage and this effect ends ',
    ],
    fragmentsToLevel: [0, 70, 120, 180, 250, 350, 500, 700, 1000, 1500],
  },
  {
    path: 'Myrmidon',
    subpath: '',
    name: 'Thundering Charge ',
    rarity: 'Myrmidon rare combat scroll',
    attackRoll: [
      '3d6',
      '3d6',
      '4d6',
      '4d6',
      '6d6',
      '6d6',
      '7d6',
      '7d6',
      '8d6',
      '8d6',
    ],
    defendingStat: 'Agility',
    priority: [7, 9, 9, 9, 11, 11, 11, 13, 13, 13],
    damage: ['5', '7', '7', '9', '9', '12', '12', '15', '15', '18'],
    target: [
      'One enemy',
      'One enemy',
      'One enemy',
      'One enemy',
      'One enemy',
      'One enemy',
      'One enemy',
      'One enemy',
      'One enemy',
      'One enemy',
    ],
    keyword: 'Deathtouch',
    duration: [
      'Instant',
      'Instant',
      'Instant',
      'Instant',
      'Instant',
      'Instant',
      'Instant',
      'Instant',
      'Instant',
      'Instant',
    ],
    durable: ['No', 'No', 'No', 'No', 'No', 'No', 'No', 'No', 'No', 'No'],
    description:
      'Myrmidon’s charge is empowered by the raging spirit of the ancient warrior with destructive results.',
    effects: [
      'If the hitpoints of the target are 4 or lower after the damage of this attack, the target is defeated instead.',
      'If the hitpoints of the target are 4 or lower after the damage of this attack, the target is defeated instead.',
      'If the hitpoints of the target are 5 or lower after the damage of this attack, the target is defeated instead.',
      'If the hitpoints of the target are 6 or lower after the damage of this attack, the target is defeated instead.',
      'If the hitpoints of the target are 6 or lower after the damage of this attack, the target is defeated instead.',
      'If the hitpoints of the target are 7 or lower after the damage of this attack, the target is defeated instead.',
      'If the hitpoints of the target are 8 or lower after the damage of this attack, the target is defeated instead.',
      'If the hitpoints of the target are 8 or lower after the damage of this attack, the target is defeated instead.',
      'If the hitpoints of the target are 9 or lower after the damage of this attack, the target is defeated instead.',
      'If the hitpoints of the target are 10 or lower after the damage of this attack, the target is defeated instead.',
    ],
    fragmentsToLevel: [0, 70, 120, 180, 250, 350, 500, 700, 1000, 1500],
  },
  {
    path: 'Myrmidon',
    subpath: '',
    name: 'Unyelding Resolve',
    rarity: 'Myrmidon rare combat scroll',
    attackRoll: [
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
    ],
    defendingStat: 'None',
    priority: [2, 4, 4, 4, 6, 6, 6, 8, 8, 8],
    damage: [
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
    ],
    target: [
      'One Ally',
      'One Ally',
      'One Ally',
      'One Ally',
      'One Ally',
      'One Ally',
      'One Ally',
      'One Ally',
      'One Ally',
      'One Ally',
    ],
    keyword: 'Cleanse',
    duration: [
      'Instant',
      'Instant',
      'Instant',
      'Instant',
      'Instant',
      'Instant',
      'Instant',
      'Instant',
      'Instant',
      'Instant',
    ],
    durable: ['No', 'No', 'No', 'No', 'No', 'No', 'No', 'No', 'No', 'No'],
    description:
      'The ancestral warriors begin chanting war songs which empower the target of the Myrmidon giving him the will to continue fighting.',
    effects: [
      'The target of the Myrmidon chooses one debuff affecting him ending its effects immediately. Additionally, the target receives 5 temporary hit points',
      'The target of the Myrmidon chooses one debuff affecting him ending its effects immediately. Additionally, the target receives 5 temporary hit points',
      'The target of the Myrmidon chooses one debuff affecting him ending its effects immediately. Additionally, the target receives 7 temporary hit points',
      'The target of the Myrmidon chooses one debuff affecting him ending its effects immediately. Additionally, the target receives 7 temporary hit points',
      'The target of the Myrmidon chooses one debuff affecting him ending its effects immediately. Additionally, the target receives 9 temporary hit points',
      'The target of the Myrmidon chooses one debuff affecting him ending its effects immediately. Additionally, the target receives 9 temporary hit points',
      'The target of the Myrmidon chooses one debuff affecting him ending its effects immediately. Additionally, the target receives 11 temporary hit points',
      'The target of the Myrmidon chooses one debuff affecting him ending its effects immediately. Additionally, the target receives 11 temporary hit points',
      'The target of the Myrmidon chooses one debuff affecting him ending its effects immediately. Additionally, the target receives 13 temporary hit points',
      'The target of the Myrmidon chooses one debuff affecting him ending its effects immediately. Additionally, the target receives 13 temporary hit points',
    ],
    fragmentsToLevel: [0, 70, 120, 180, 250, 350, 500, 700, 1000, 1500],
  },
  {
    path: 'Myrmidon',
    subpath: '',
    name: 'We Shall Never Surrender ',
    rarity: 'Myrmidon epic combat scroll',
    attackRoll: [
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
    ],
    defendingStat: 'None',
    priority: [6, 8, 8, 8, 10, 10, 10, 12, 12, 12],
    damage: [
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
    ],
    target: [
      'Two Ally',
      'Two Ally',
      'Two Ally',
      'Three Ally',
      'Three Ally',
      'Three Ally',
      'Four Ally',
      'Four Ally',
      'Four Ally',
      'Five Ally',
    ],
    keyword: 'Temp hp',
    duration: [
      'Instant',
      'Instant',
      'Instant',
      'Instant',
      'Instant',
      'Instant',
      'Instant',
      'Instant',
      'Instant',
      'Instant',
    ],
    durable: ['No', 'No', 'No', 'No', 'No', 'No', 'No', 'No', 'No', 'No'],
    description:
      'The Myrmidon enters a bloodlust state which allows him to fight despite the wounds he receives.',
    effects: [
      'The targeted allies receive 5 temporary hit points.',
      'The targeted allies receive 6 temporary hit points.',
      'The targeted allies receive 7 temporary hit points.',
      'The targeted allies receive 8 temporary hit points.',
      'The targeted allies receive 9 temporary hit points.',
      'The targeted allies receive 10 temporary hit points.',
      'The targeted allies receive 11 temporary hit points.',
      'The targeted allies receive 13 temporary hit points.',
      'The targeted allies receive 15 temporary hit points.',
      'The targeted allies receive 17 temporary hit points.',
    ],
    fragmentsToLevel: [0, 100, 150, 250, 350, 500, 700, 1000, 1500, 2200],
  },
  {
    path: 'Myrmidon',
    subpath: '',
    name: 'Whirlwind Strike',
    rarity: 'Myrmidon rare combat scroll',
    attackRoll: [
      '2d6',
      '2d6',
      '3d6',
      '3d6',
      '4d6',
      '4d6',
      '5d6',
      '5d6',
      '6d6',
      '6d6',
    ],
    defendingStat: 'Agility',
    priority: [4, 6, 6, 6, 8, 8, 8, 10, 10, 10],
    damage: ['5', '7', '7', '9', '9', '12', '12', '15', '15', '18'],
    target: [
      'Two enemy',
      'Two enemy',
      'Three enemy',
      'Three enemy',
      'Three enemy',
      'Three enemy',
      'Four enemy',
      'Four enemy',
      'Four enemy',
      'Four enemy',
    ],
    keyword: 'None',
    duration: [
      'Instant',
      'Instant',
      'Instant',
      'Instant',
      'Instant',
      'Instant',
      'Instant',
      'Instant',
      'Instant',
      'Instant',
    ],
    durable: ['No', 'No', 'No', 'No', 'No', 'No', 'No', 'No', 'No', 'No'],
    description:
      'The ancestral spirits of the Myrmidon raise from the underwold, fully clad in armor, spears in hand to decimate all who oppose the Awakened.',
    effects: [
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
    ],
    fragmentsToLevel: [0, 70, 120, 180, 250, 350, 500, 700, 1000, 1500],
  },
  {
    path: 'Myrmidon',
    subpath: 'Bloodbound',
    name: 'Bloodlust',
    rarity: 'Myrmidon epic combat scroll',
    attackRoll: [
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
    ],
    defendingStat: 'None',
    priority: ['None', 'None', 'None', 'None', 'None', 'None', 'None', 'None', 'None', 'None'],
    damage: [
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
    ],
    target: [
      'Self',
      'Self',
      'Self',
      'Self',
      'Self',
      'Self',
      'Self',
      'Self',
      'Self',
      'Self',
    ],
    keyword: 'Passive,Buff damage',
    duration: [
      'Instant',
      'Instant',
      'Instant',
      'Instant',
      'Instant',
      'Instant',
      'Instant',
      'Instant',
      'Instant',
      'Instant',
    ],
    durable: ['Yes', 'Yes', 'Yes', 'Yes', 'Yes', 'Yes', 'Yes', 'Yes', 'Yes', 'Yes'],
    description:
      'The Bloodbound Myrmidon revels in the pain and destruction he causes.',
    effects: [
      'After each successful weapon attack, the Bloodbound gains a cumulative +1 to damage (up to a maximum of +3) for all future weapon strike attacks.',
      'After each successful weapon attack, the Bloodbound gains a cumulative +1 to damage (up to a maximum of +4) for all future weapon strike attacks.',
      'After each successful weapon attack, the Bloodbound gains a cumulative +2 to damage (up to a maximum of +4) for all future weapon strike attacks.',
      'After each successful weapon attack, the Bloodbound gains a cumulative +2 to damage (up to a maximum of +5) for all future weapon strike attacks.',
      'After each successful weapon attack, the Bloodbound gains a cumulative +2 to damage (up to a maximum of +6) for all future weapon strike attacks.',
      'After each successful weapon attack, the Bloodbound gains a cumulative +3 to damage (up to a maximum of +6) for all future weapon strike attacks.',
      'After each successful weapon attack, the Bloodbound gains a cumulative +3 to damage (up to a maximum of +7) for all future weapon strike attacks.',
      'After each successful weapon attack, the Bloodbound gains a cumulative +3 to damage (up to a maximum of +7) for all future weapon strike attacks.',
      'After each successful weapon attack, the Bloodbound gains a cumulative +4 to damage (up to a maximum of +8) for all future weapon strike attacks.',
      'After each successful weapon attack, the Bloodbound gains a cumulative +4 to damage (up to a maximum of +9) for all future weapon strike attacks.',
    ],
    fragmentsToLevel: [0, 100, 150, 250, 350, 500, 700, 1000, 1500, 2200],
  },
  {
    path: 'Myrmidon',
    subpath: 'Bloodbound',
    name: 'No pain No gain',
    rarity: 'Myrmidon epic combat scroll',
    attackRoll: [
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
    ],
    defendingStat: 'None',
    priority: ['None', 'None', 'None', 'None', 'None', 'None', 'None', 'None', 'None', 'None'],
    damage: [
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
    ],
    target: [
      'Self',
      'Self',
      'Self',
      'Self',
      'Self',
      'Self',
      'Self',
      'Self',
      'Self',
      'Self',
    ],
    keyword: 'Passive,Buff damage',
    duration: [
      'Instant',
      'Instant',
      'Instant',
      'Instant',
      'Instant',
      'Instant',
      'Instant',
      'Instant',
      'Instant',
      'Instant',
    ],
    durable: ['Yes', 'Yes', 'Yes', 'Yes', 'Yes', 'Yes', 'Yes', 'Yes', 'Yes', 'Yes'],
    description:
      'The Bloodbound Myrmidon attacks with no regard to his personal health as long as his strikes become increasingly destructive. ',
    effects: [
      'After the Bloodbound Myrmidon successfully hits a target, but before dealing damage, he has the option to deal up to 2 damage to himself to increase the damage of his attack by the same amount.The Bloodbound Myrmidon cannot use temporary hit points to activate this power.The Bloodbound Myrmidon cannot be reduced to 0 hit points by this power, and the damage cannot be mitigated by any buffs or debuffs. This power’s effects stack with any buffs or debuffs active on the Bloodbound Myrmidon.',
      'After the Bloodbound Myrmidon successfully hits a target, but before dealing damage, he has the option to deal up to 2 damage to himself to increase the damage of his attack by the same amount.The Bloodbound Myrmidon cannot use temporary hit points to activate this power.The Bloodbound Myrmidon cannot be reduced to 0 hit points by this power, and the damage cannot be mitigated by any buffs or debuffs. This power’s effects stack with any buffs or debuffs active on the Bloodbound Myrmidon.',
      'After the Bloodbound Myrmidon successfully hits a target, but before dealing damage, he has the option to deal up to 3 damage to himself to increase the damage of his attack by the same amount.The Bloodbound Myrmidon cannot use temporary hit points to activate this power.The Bloodbound Myrmidon cannot be reduced to 0 hit points by this power, and the damage cannot be mitigated by any buffs or debuffs. This power’s effects stack with any buffs or debuffs active on the Bloodbound Myrmidon.',
      'After the Bloodbound Myrmidon successfully hits a target, but before dealing damage, he has the option to deal up to 3 damage to himself to increase the damage of his attack by the same amount.The Bloodbound Myrmidon cannot use temporary hit points to activate this power.The Bloodbound Myrmidon cannot be reduced to 0 hit points by this power, and the damage cannot be mitigated by any buffs or debuffs. This power’s effects stack with any buffs or debuffs active on the Bloodbound Myrmidon.',
      'After the Bloodbound Myrmidon successfully hits a target, but before dealing damage, he has the option to deal up to 4 damage to himself to increase the damage of his attack by the same amount.The Bloodbound Myrmidon cannot use temporary hit points to activate this power.The Bloodbound Myrmidon cannot be reduced to 0 hit points by this power, and the damage cannot be mitigated by any buffs or debuffs. This power’s effects stack with any buffs or debuffs active on the Bloodbound Myrmidon.',
      'After the Bloodbound Myrmidon successfully hits a target, but before dealing damage, he has the option to deal up to 4 damage to himself to increase the damage of his attack by the same amount.The Bloodbound Myrmidon cannot use temporary hit points to activate this power.The Bloodbound Myrmidon cannot be reduced to 0 hit points by this power, and the damage cannot be mitigated by any buffs or debuffs. This power’s effects stack with any buffs or debuffs active on the Bloodbound Myrmidon.',
      'After the Bloodbound Myrmidon successfully hits a target, but before dealing damage, he has the option to deal up to 5 damage to himself to increase the damage of his attack by the same amount.The Bloodbound Myrmidon cannot use temporary hit points to activate this power.The Bloodbound Myrmidon cannot be reduced to 0 hit points by this power, and the damage cannot be mitigated by any buffs or debuffs. This power’s effects stack with any buffs or debuffs active on the Bloodbound Myrmidon.',
      'After the Bloodbound Myrmidon successfully hits a target, but before dealing damage, he has the option to deal up to 5 damage to himself to increase the damage of his attack by the same amount.The Bloodbound Myrmidon cannot use temporary hit points to activate this power.The Bloodbound Myrmidon cannot be reduced to 0 hit points by this power, and the damage cannot be mitigated by any buffs or debuffs. This power’s effects stack with any buffs or debuffs active on the Bloodbound Myrmidon.',
      'After the Bloodbound Myrmidon successfully hits a target, but before dealing damage, he has the option to deal up to 6 damage to himself to increase the damage of his attack by the same amount.The Bloodbound Myrmidon cannot use temporary hit points to activate this power.The Bloodbound Myrmidon cannot be reduced to 0 hit points by this power, and the damage cannot be mitigated by any buffs or debuffs. This power’s effects stack with any buffs or debuffs active on the Bloodbound Myrmidon.',
      'After the Bloodbound Myrmidon successfully hits a target, but before dealing damage, he has the option to deal up to 6 damage to himself to increase the damage of his attack by the same amount.The Bloodbound Myrmidon cannot use temporary hit points to activate this power.The Bloodbound Myrmidon cannot be reduced to 0 hit points by this power, and the damage cannot be mitigated by any buffs or debuffs. This power’s effects stack with any buffs or debuffs active on the Bloodbound Myrmidon.',
    ],
    fragmentsToLevel: [0, 100, 150, 250, 350, 500, 700, 1000, 1500, 2200],
  },
  {
    path: 'Myrmidon',
    subpath: 'Spiritbound',
    name: 'Covenant of Pain',
    rarity: 'Myrmidon epic combat scroll',
    attackRoll: [
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
    ],
    defendingStat: 'None',
    priority: ['None', 'None', 'None', 'None', 'None', 'None', 'None', 'None', 'None', 'None'],
    damage: [
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
    ],
    target: [
      'Self',
      'Self',
      'Self',
      'Self',
      'Self',
      'Self',
      'Self',
      'Self',
      'Self',
      'Self',
    ],
    keyword: 'Passive',
    duration: [
      'Instant',
      'Instant',
      'Instant',
      'Instant',
      'Instant',
      'Instant',
      'Instant',
      'Instant',
      'Instant',
      'Instant',
    ],
    durable: ['Yes', 'Yes', 'Yes', 'Yes', 'Yes', 'Yes', 'Yes', 'Yes', 'Yes', 'Yes'],
    description:
      'As long as Myrmidon finds the strength to attack his enemies, he strengthens his mind to protect his allies',
    effects: [
      'Whenever the Myrmidon successfully makes a weapon attack, he can refresh the "Spirit Guardian" scroll.',
      'Whenever the Myrmidon successfully makes a weapon attack, he can refresh the "Spirit Guardian" scroll.',
      'Whenever the Myrmidon successfully makes a weapon attack, he can refresh the "Spirit Guardian" scroll.',
      'Whenever the Myrmidon successfully makes a weapon attack, he can refresh the "Spirit Guardian" scroll.',
      'Whenever the Myrmidon successfully makes a weapon attack, he can refresh the "Spirit Guardian" scroll.',
      'Whenever the Myrmidon successfully makes a weapon attack, he can refresh the "Spirit Guardian" scroll.',
      'Whenever the Myrmidon successfully makes a weapon attack, he can refresh the "Spirit Guardian" scroll.',
      'Whenever the Myrmidon successfully makes a weapon attack, he can refresh the "Spirit Guardian" scroll.',
      'Whenever the Myrmidon successfully makes a weapon attack, he can refresh the "Spirit Guardian" scroll.',
      'Whenever the Myrmidon successfully makes a weapon attack, he can refresh the "Spirit Guardian" scroll.',
    ],
    fragmentsToLevel: [0, 100, 150, 250, 350, 500, 700, 1000, 1500, 2200],
  },
  {
    path: 'Myrmidon',
    subpath: 'Spiritbound',
    name: 'Spirit Guardian',
    rarity: 'Myrmidon epic combat scroll',
    attackRoll: [
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
    ],
    defendingStat: 'None',
    priority: ['22', '24', '24', '26', '26', '28', '28', '30', '30', '32'],
    damage: [
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
      'None',
    ],
    target: [
      'One Ally',
      'One Ally',
      'One Ally',
      'One Ally',
      'One Ally',
      'One Ally',
      'One Ally',
      'One Ally',
      'One Ally',
      'One Ally',
    ],
    keyword: 'Passive',
    duration: [
      'Two rounds',
      'Two rounds',
      'Two rounds',
      'Three rounds',
      'Three rounds',
      'Three rounds',
      'Four rounds',
      'Four rounds',
      'Four rounds',
      'Five rounds',
    ],
    durable: ['Yes', 'Yes', 'Yes', 'Yes', 'Yes', 'Yes', 'Yes', 'Yes', 'Yes', 'Yes'],
    description:
      'As long as Myrmidon finds the strength to attack his enemies, he strengthens his mind to protect his allies',
    effects: [
      'Whenever the Myrmidon successfully makes a weapon attack, he can refresh the "Spirit Guardian" scroll.',
      'Whenever the Myrmidon successfully makes a weapon attack, he can refresh the "Spirit Guardian" scroll.',
      'Whenever the Myrmidon successfully makes a weapon attack, he can refresh the "Spirit Guardian" scroll.The target of the scroll receives and +1d6 on his Intuition defense and +1d6 on his Agility defense',
      'Whenever the Myrmidon successfully makes a weapon attack, he can refresh the "Spirit Guardian" scroll.The target of the scroll receives and +2d6 on his Intuition defense and +1d6 on his Agility defense',
      'Whenever the Myrmidon successfully makes a weapon attack, he can refresh the "Spirit Guardian" scroll.The target of the scroll receives and +2d6 on his Intuition defense and +2d6 on his Agility defense',
      'Whenever the Myrmidon successfully makes a weapon attack, he can refresh the "Spirit Guardian" scroll.The target of the scroll receives and +2d6 on his Intuition defense and +2d6 on his Agility defense',
      'Whenever the Myrmidon successfully makes a weapon attack, he can refresh the "Spirit Guardian" scroll.The target of the scroll receives and +3d6 on his Intuition defense and +2d6 on his Agility defense',
      'Whenever the Myrmidon successfully makes a weapon attack, he can refresh the "Spirit Guardian" scroll.The target of the scroll receives and +3d6 on his Intuition defense and +3d6 on his Agility defense',
      'Whenever the Myrmidon successfully makes a weapon attack, he can refresh the "Spirit Guardian" scroll.The target of the scroll receives and +3d6 on his Intuition defense and +3d6 on his Agility defense',
      'Whenever the Myrmidon successfully makes a weapon attack, he can refresh the "Spirit Guardian" scroll.The target of the scroll receives and +4d6 on his Intuition defense and +3d6 on his Agility defense',
    ],
    fragmentsToLevel: [0, 100, 150, 250, 350, 500, 700, 1000, 1500, 2200],
  },
];

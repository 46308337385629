import React, { useState, useEffect } from 'react';
import { combatScrolls } from './CotaScrollData';

const ScrollSlot = ({
  index,
  scroll,
  openModal,
  showing,
  darkMode,
  leveledScrolls,
}) => {
  let hasLevels = true;
  try {
    hasLevels = Array.isArray(scroll.attackRoll);
  } catch {}

  const [scrollLevel, setScrollLevel] = useState(0);

  useEffect(() => {
    // Find the level for the currently displayed scroll
    const leveledScroll = leveledScrolls.find(
      (item) => item.scroll === showing
    );
    if (leveledScroll) {
      setScrollLevel(leveledScroll.level);
    } else {
      setScrollLevel(0); // Default level if not found
    }
  }, [showing, leveledScrolls]);

  // Function to convert a number to Roman numerals
  const numberToRoman = (num) => {
    const romanNumerals = [
      'I',
      'II',
      'III',
      'IV',
      'V',
      'VI',
      'VII',
      'VIII',
      'IX',
      'X',
    ];
    return romanNumerals[num] || '';
  };

  return (
    <div
      className={
        scroll !== -1 && scroll.subpath && scroll.subpath !== ''
          ? 'cota_outline_subpath cota_scroll_slot'
          : 'cota_outline' + darkMode() + ' cota_scroll_slot'
      }
      onClick={() => {
        openModal(index, showing);
      }}
    >
      {scroll !== -1 ? (
        <div className='cota_scroll_stats'>
          <h3 className='cota_scroll_title'>
            {scroll.name} {hasLevels && numberToRoman(scrollLevel)}
          </h3>
          <p className='cota_scroll_stat'>
            Attack Roll:{' '}
            {hasLevels ? scroll.attackRoll[scrollLevel] : scroll.attackRoll}
          </p>
          <p className='cota_scroll_stat'>Defending: {scroll.defendingStat}</p>
          <p className='cota_scroll_stat'>
            Priority:{' '}
            {hasLevels ? scroll.priority[scrollLevel] : scroll.priority}
          </p>
          <p className='cota_scroll_stat'>
            Damage: {hasLevels ? scroll.damage[scrollLevel] : scroll.damage}
          </p>
          <p className='cota_scroll_stat'>
            Target: {hasLevels ? scroll.target[scrollLevel] : scroll.target}
          </p>
          <p className='cota_scroll_stat'>Keyword: {scroll.keyword}</p>
          <p className='cota_scroll_stat'>
            Duration:{' '}
            {hasLevels ? scroll.duration[scrollLevel] : scroll.duration}
          </p>
          <p className='cota_scroll_stat'>
            Durable: {hasLevels ? scroll.durable[scrollLevel] : scroll.durable}
          </p>
        </div>
      ) : (
        <div className='cota_scroll_select'>
          <div>
            <div>SCROLL</div>
            <div>SLOT</div>
            <div>+</div>
          </div>
        </div>
      )}
    </div>
  );
};

const CotaScrollGrid = ({
  declaredScrolls,
  openModal,
  mode,
  path,
  subpath,
  guildTitle,
  leveledScrolls,
}) => {
  const [filteredScrolls, setFilteredScrolls] = useState(combatScrolls);

  const darkMode = () => {
    if (mode === 'dark') {
      return '_dark';
    }
    return '';
  };

  useEffect(() => {
    const updatedScrolls = combatScrolls.filter(
      (scroll) =>
        scroll.path === 'Any' ||
        scroll.path === guildTitle ||
        (scroll.path === path && scroll.subpath === '') ||
        (scroll.path === path && scroll.subpath === subpath)
    );
    setFilteredScrolls(updatedScrolls);
  }, [path, subpath, guildTitle]);

  const renderScrollSlots = () => {
    return declaredScrolls.map((scrollIndex, i) => {
      const scroll = scrollIndex !== -1 ? filteredScrolls[scrollIndex] : -1;
      const showing = scrollIndex;
      if (scroll) {
        return (
          <ScrollSlot
            key={i}
            index={i}
            showing={showing}
            scroll={scroll}
            openModal={openModal}
            darkMode={darkMode}
            leveledScrolls={leveledScrolls}
          />
        );
      } else {
        <ScrollSlot //Loading
          key={i}
          index={i}
          showing={-1}
          scroll={-1}
          openModal={openModal}
          darkMode={darkMode}
          leveledScrolls={leveledScrolls}
        />;
      }
    });
  };

  return (
    <div className='cota_character_sheet_right_down'>
      <div className='cota_decorative_outline cota_character_page_3'>
        <div className='cota_stats_spacer' />
        <div className={'cota_scroll_row cota_scroll_row' + darkMode()}>
          {renderScrollSlots().slice(0, 5)}
        </div>
        <div className={'cota_scroll_row cota_scroll_row' + darkMode()}>
          {renderScrollSlots().slice(5, 10)}
        </div>
      </div>
    </div>
  );
};

export default CotaScrollGrid;

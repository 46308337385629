import { configureStore, combineReducers } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { thunk } from 'redux-thunk';
import appearanceReducer from './slices/appearanceSlice';
import cotaReducer from './slices/cotaSlice';
import trialmentReducer from './slices/trialmentSlice';

const rootReducer = combineReducers({
  appearance: appearanceReducer,
  cota: cotaReducer,
  trialment: trialmentReducer,
});

const persistConfig = {
  key: 'root',
  storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(thunk),
});

const persistor = persistStore(store);

export { store, persistor };
import Rodal from 'rodal';
import { useState, useEffect } from 'react';
import { Button } from './Button';
import { useNavigate } from 'react-router-dom';
import 'rodal/lib/rodal.css';
import '../styles/Toastify.css';

import trialmentLogo from './../assets/logos/trialmentlogo.png';
import trialmentTitle from './../assets/logos/trialmenttitle.png';
import cotaLogo from './../assets/logos/cotalogo.png';
import cotaTitle from './../assets/logos/cotatitle.png';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const trialmentCampaigns = [
  'Coves of Elvion',
  'The Cradle',
  'Mother Sea',
  'The First Light',
];

export const LoginModal = (props) => {
  const { system, show, close } = props;

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [selectedCampaign, setSelectedCampaign] = useState(null);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const getIconClass = (system) => {
    if (system === 'trialment') {
      return 'trialment_login_logo';
    } else if (system === 'cota') {
      return 'cota_login_logo';
    }
    return '';
  };

  const handleCampaignSelect = (campaign) => {
    if (!loading) {
      setSelectedCampaign(campaign);
    }
  };

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const doLogin = () => {
    if (
      //Temp login management
      //TODO: Perm
      (username === "theompi@gmail.com" && password === "Flowers(3343)") ||
      (username === "dfilip91@gmail.com" &&
        password === "Psilos(1)" &&
        system === "cota") ||
      (username === "halkos" && password === "p@ssw0rd" && system === "cota") ||
      (username === "Lucius" &&
        password === "200300125" &&
        system === "cota") ||
      (username === "Bobina" && password === "787742" && system === "cota") ||
      (username === "Mr.Ajax" && password === "Ajax" && system === "cota") ||
      (username === "Anaroch" && password === "asdfghjkl@" && system === "cota")  ||
      (username === "Syvilla" && password === "6979625292" && system === "cota")
    ) {
      localStorage.setItem('username', username);
      localStorage.setItem('system', system);
      localStorage.setItem('campaign', selectedCampaign);
      setTimeout(() => {
        navigate('/campaign');
      }, '1000');
    } else {
      setTimeout(() => {
        setLoading(false);
        toast.warn(
          'Username or password is either incorrect, or system/campaign is not available for this user.'
        );
      }, '1000');
    }
  };

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.keyCode === 13 && !loading) {
        // 13 is the Enter key
        const canSubmit =
          (system === 'trialment' &&
            selectedCampaign !== null &&
            username !== '' &&
            password !== '') ||
          (system === 'cota' && username !== '' && password !== '');

        if (canSubmit) {
          setLoading(true);
          doLogin();
        }
      }
    };

    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [username, password, selectedCampaign, system, loading]); // Dependencies

  return (
    <Rodal
      animation={'fade'}
      visible={show}
      onClose={!loading ? close : () => {}}
      //showMask={false}
      customStyles={{
        border: '2px solid #e4E7C5', //beige
        backgroundColor: '#f6f7eb', //ivory
      }}
      height={system === 'trialment' ? 645 : 465}
      width={350}
    >
      <ToastContainer
        position='bottom-center'
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme='colored'
      />
      <div>
        <img
          className={`system_logo ${getIconClass(system)}`}
          src={
            system === 'trialment'
              ? trialmentLogo
              : system === 'cota'
              ? cotaLogo
              : ''
          }
          alt=''
        />
      </div>
      <div>
        <div>
          <img
            className='system_logo_txt trialment_login_logo'
            src={
              system === 'trialment'
                ? trialmentTitle
                : system === 'cota'
                ? cotaTitle
                : ''
            }
            alt=''
          />
        </div>
      </div>
      {system === 'trialment' && (
        <div className='trialment_login_form'>
          <div className='trialment_login_form_row'>
            <>Select Campaign</>
          </div>
          {trialmentCampaigns.map((campaign, index) => (
            <span key={index}>
              {index % 2 === 0 && index + 1 < trialmentCampaigns.length && (
                <div className='trialment_login_form_row'>
                  <span>
                    <Button
                      label={campaign}
                      type={
                        selectedCampaign === campaign
                          ? 'cinnabar'
                          : 'black-white'
                      }
                      onPress={() => handleCampaignSelect(campaign)}
                    />
                  </span>
                  <span className='button_margin'>
                    <Button
                      label={trialmentCampaigns[index + 1]}
                      type={
                        selectedCampaign === trialmentCampaigns[index + 1]
                          ? 'cinnabar'
                          : 'black-white'
                      }
                      onPress={() =>
                        handleCampaignSelect(trialmentCampaigns[index + 1])
                      }
                    />
                  </span>
                </div>
              )}
            </span>
          ))}
        </div>
      )}

      <form className='trialment_login_form'>
        <div className='trialment_login_form_row'>Login</div>
        <div className='trialment_login_form_row'>
          <input
            type='text'
            value={username}
            disabled={loading}
            placeholder='Username'
            onChange={(e) => !loading && setUsername(e.target.value)}
            autoComplete='username'
          />
        </div>
        <div
          className='trialment_login_form_row'
          style={{ position: 'relative' }}
        >
          <input
            type={showPassword ? 'text' : 'password'}
            value={password}
            disabled={loading}
            placeholder='password'
            onChange={(e) => setPassword(e.target.value)}
            autoComplete='current-password'
          />
          <span
            onClick={toggleShowPassword}
            style={{
              position: 'absolute',
              right: '10px',
              top: '50%',
              transform: 'translateY(-50%)',
              cursor: 'pointer',
            }}
          >
            {showPassword ? '👁️' : '👁️‍🗨️'}
          </span>
        </div>
      </form>
      <div style={{ paddingTop: '10px', marginBottom: '10px' }}>
        <Button label={'Login with Google'} type={'disabled'} />
        <span className='button_margin'>
          <Button label={'Forgot Password'} type={'disabled'} />
        </span>
      </div>

      <div style={{ paddingTop: '10px', marginBottom: '10px' }}>
        <Button
          label={'➤ Enter'}
          type={
            loading
              ? 'placeholder'
              : system === 'trialment' &&
                selectedCampaign !== null &&
                username !== '' &&
                password !== ''
              ? 'cinnabar'
              : system === 'cota' && username !== '' && password !== ''
              ? 'cinnabar'
              : 'disabled'
          }
          onPress={() => {
            setLoading(true);
            doLogin();
          }}
        />
      </div>
    </Rodal>
  );
};

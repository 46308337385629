import { useState, useEffect } from 'react';
import { LoginModal } from '../../components/LoginModal.jsx';
import { Button } from '../../components/Button.jsx';

import '../../styles/Login.css';

export default function Login() {
  const [txt1, setTxt1] = useState('D');
  const [txt2, setTxt2] = useState('F');
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [system, setSystem] = useState('trialment');

  //which expands into
  const word1 = 'Dimension';
  const word2 = 'Factory';
  const timeoutValue = 30;
  const loadingTimeout = timeoutValue * (word1.length + word2.length);
  //with the use of
  const expand = () => {
    let i = 1;
    const expandTxt1 = () => {
      if (i <= word1.length) {
        setTxt1(word1.substring(0, i));
        i++;
        setTimeout(expandTxt1, timeoutValue);
      }
    };
    let j = 1;
    const expandTxt2 = () => {
      if (j <= word2.length) {
        setTxt2(word2.substring(0, j));
        j++;
        setTimeout(expandTxt2, timeoutValue);
      }
    };
    expandTxt1();
    setTimeout(expandTxt2, timeoutValue * word1.length);
  };
  const contract = () => {
    let i = Math.max(word1.length);
    const contractTxt1 = () => {
      if (i > 0) {
        setTxt1(word1.substring(0, i));
        i--;
        setTimeout(contractTxt1, timeoutValue);
      }
    };
    let j = Math.max(word2.length);
    const contractTxt2 = () => {
      if (j > 0) {
        setTxt2(word2.substring(0, j));
        j--;
        setTimeout(contractTxt2, timeoutValue);
      }
    };
    contractTxt2();
    setTimeout(contractTxt1, timeoutValue * word2.length);
  };
  //at the Start
  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      expand();
      setTimeout(() => setLoading(false), loadingTimeout);
    }, 200);
    // eslint-disable-next-line
  }, []);
  //or when I click the Logo
  const animate = () => {
    if (!loading) {
      setLoading(true);
      setTimeout(() => setLoading(false), loadingTimeout);
      if (txt1 === 'D') {
        expand();
      } else {
        contract();
      }
    }
  };

  const openModal = (system) => {
    setSystem(system);
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  return (
    <div className='login_page_container'>
      <div className='logo_wrapper' onClick={() => animate()}>
        <h2 className='logo rotate'>{txt1}</h2>
        <h2 className='logo '>{txt2}</h2>
      </div>
      <div style={{margin: "10px 0 10px 0"}}></div>
      <div className='text'>Home of</div>
      <div style={{margin: "10px 0 10px 0"}}></div>
      <div>
        <span>
          <Button
            label={'Trialment'}
            icon={'trialmentLogo'}
            type='black-white'
            onPress={() => openModal('trialment')}
          />
        </span>
        <span className='button_margin'>
          <Button
            label={'CotA'}
            icon={'cotaLogo'}
            type='black-white'
            onPress={() => openModal('cota')}
          />
        </span>
      </div>
      <LoginModal show={showModal} system={system} close={closeModal} />
    </div>
  );
}

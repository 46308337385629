import './App.css';
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useNavigate,
} from 'react-router-dom';
//import SlideRoutes from 'react-slide-routes';
import { useEffect } from 'react';
import Login from './routes/login';
import Campaign from './routes/campaign';

function App() {
  const navigate = useNavigate();

  useEffect(() => {
    const username = localStorage.getItem('username');
    const system = localStorage.getItem('system');
    const campaign = localStorage.getItem('campaign');

    if (username && system && campaign) {
      navigate('/campaign');
    } else {
      navigate('/');
    }
  }, [navigate]);

  return (
    <div className='App'>
      <header className='App-header'>
        <Routes>
          <Route path='/' element={<Login />} />
          <Route path='/campaign' element={<Campaign />} />
        </Routes>
      </header>
    </div>
  );
}

function AppWrapper() {
  return (
    <Router>
      <App />
    </Router>
  );
}

export default AppWrapper;

import { useState, useEffect, useRef } from 'react';
import Rodal from 'rodal';
import { Button } from './Button';
import 'rodal/lib/rodal.css';
import '../styles/Cota.css';
import { combatScrolls } from './CotaScrollData';
import enlightenedlogo from '../assets/logos/enlightenedlogo.png';
import honorboundlogo from '../assets/logos/honorboundlogo.png';
import ironsteamlogo from '../assets/logos/ironsteamlogo.png';
import wildheartlogo from '../assets/logos/wildheartlogo.png';
import noguildlogo from '../assets/logos/selectguildlogo.png';

export const CotaGuild = (props) => {
  const {
    show,
    close,
    mode,
    characterGuild,
    handleGuildChange,
    characterGuildTitle,
    handleGuildTitleChange,
  } = props;

  const [isFullScreen, setIsFullScreen] = useState(false);
  const contentRef = useRef(null);

  useEffect(() => {
    const mediaQuery = window.matchMedia('(max-aspect-ratio: 1510/962)');

    const handleMediaChange = (e) => {
      setIsFullScreen(e.matches);
    };
    handleMediaChange(mediaQuery);
    mediaQuery.addListener(handleMediaChange);
    return () => mediaQuery.removeListener(handleMediaChange);
  }, []);

  const darkMode = () => {
    return mode === 'dark' ? '_dark' : '';
  };

  const guilds = [
    { name: 'Enlightened', logo: enlightenedlogo },
    { name: 'Honorbound', logo: honorboundlogo },
    { name: 'Ironsteam', logo: ironsteamlogo },
    { name: 'Wildheart', logo: wildheartlogo },
    { name: 'None', logo: noguildlogo },
  ];

  const guildTitles = [
    { name: 'Archon' },
    { name: 'Herald' },
    { name: 'Ambassador' },
    { name: 'Librarian' },
    { name: 'Member' },
  ];

  return (
    <Rodal
      animation={'door'}
      visible={show}
      onClose={close}
      customStyles={{
        position: 'absolute',
        border: mode === 'dark' ? '2px solid #393e41' : '2px solid #e4E7C5', // beige
        backgroundColor: mode === 'dark' ? '#26292C' : '#f6f7eb', // ivory
        width: isFullScreen ? 'calc(100% - 34px)' : '350px',
        height: isFullScreen ? 'calc(100% - 114px)' : '610px',
        marginTop: isFullScreen ? '80px' : '',
      }}
    >
      <div
        ref={contentRef}
        className={'cota_scroll_modal_content cota_scroll_modal_content' + darkMode()}
      >
        <h3 className='cota_scroll_modal_paragraph'>
          Select Guild{' '}
          
        </h3>
        <div className={'cota_scroll_modal_divider cota_scroll_modal_divider' + darkMode()} />

        {/* Guild Selection */}
        <div className='trialment_login_form_row' >
              <b>
                ⚠️ Changing Guilds/Roles will reset your Scrolls.
              </b>
            </div>

        <div>
          {guilds.map((guild, index) => (
            <span key={index}>
              {/* Render every guild and if it's the last odd element, display it in a full row */}
              {index % 2 === 0 && (
                <div className='trialment_login_form_row'>
                  <span>
                    <Button
                      label={guild.name}
                      type={
                        characterGuild === guild.name
                          ? 'cinnabar'
                          : mode === 'dark'
                          ? 'onyx'
                          : 'black-white'
                      }
                      onPress={() => {
                        handleGuildChange(guild.name);
                      }}
                    />
                  </span>
                  {guilds[index + 1] && (
                    <span className='button_margin'>
                      <Button
                        label={guilds[index + 1].name}
                        type={
                          characterGuild === guilds[index + 1].name
                            ? 'cinnabar'
                            : mode === 'dark'
                            ? 'onyx'
                            : 'black-white'
                        }
                        onPress={() => {
                          handleGuildChange(guilds[index + 1].name);
                        }}
                      />
                    </span>
                  )}
                </div>
              )}
            </span>
          ))}
        </div>

        {/* Guild Titles Selection */}
        <div style={{ marginTop: '12px' }}>
          My role is that of:
          <div className='guild_selection_container'>
            {guildTitles.map((guildTitle, index) => (
              <span key={index}>
                {/* Render every title and if it's the last odd element, display it in a full row */}
                {index % 2 === 0 && (
                  <div className='trialment_login_form_row'>
                    <span>
                      <Button
                        label={guildTitle.name}
                        type={
                          characterGuildTitle === guildTitle.name
                            ? 'cinnabar'
                            : mode === 'dark'
                            ? 'onyx'
                            : 'black-white'
                        }
                        onPress={() => {
                          handleGuildTitleChange(guildTitle.name);
                        }}
                      />
                    </span>
                    {guildTitles[index + 1] && (
                      <span className='button_margin'>
                        <Button
                          label={guildTitles[index + 1].name}
                          type={
                            characterGuildTitle === guildTitles[index + 1].name
                              ? 'cinnabar'
                              : mode === 'dark'
                              ? 'onyx'
                              : 'black-white'
                          }
                          onPress={() => {
                            handleGuildTitleChange(guildTitles[index + 1].name);
                          }}
                        />
                      </span>
                    )}
                  </div>
                )}
              </span>
            ))}
          </div>
          <div
            className='cota_scroll_modal_footer'
            style={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Button
              onPress={() => {
                close();
              }}
              label='Select'
              type={'cinnabar'}
            />
          </div>
        </div>
      </div>
    </Rodal>
  );
};

import React, { useState, useEffect } from 'react';
import { RPAbility } from './CotaRPData'; // Ensure the path is correct

const CotaRPAbility = ({ id, diceBox, mode, path, subpath }) => {
  const [RPAbilityName, setRPAbilityName] = useState('');
  const [RPExplainer, setRPExplainer] = useState('');
  const [RPUses, setRPUses] = useState('');
  const [RPRoll, setRPRoll] = useState('');

  const darkMode = () => (mode === 'dark' ? '_dark' : '');

  useEffect(() => {
    let ability = RPAbility.find(
      (item) => item.path === path && item.slot === id
    );

    if (id === 'Universal Talent') {
      ability = RPAbility.find(
        (item) => item.path === 'Any' && item.slot === 'Universal Talent'
      );
    }

    if (id === 'Subpath Talent') {
      ability = RPAbility.find(
        (item) => item.path === path && item.subpath === subpath && item.slot === 'Subpath Talent'
      );
    }

    if (ability) {
      setRPAbilityName(ability.name);
      setRPExplainer(ability.description[0]); // Assuming the first description is the one to show
      setRPUses(ability.uses[0]); // Defaulting to the first value
      setRPRoll(ability.RPRoll[0]); // Defaulting to the first value
    }
  }, [id, path, subpath]);

  const roll = () => {
    const validDiceRegex = /^[1-9]\d*\s*d\s*(4|6|8|10|12|20|100)$/;
    const RPUsesInt = parseInt(RPUses, 10);
    if (validDiceRegex.test(RPRoll)) {
      if (RPUses === 'Limitless') {
        diceBox.roll(RPRoll);
      } else {
        if (RPUsesInt > 0) {
          diceBox.roll(RPRoll);
          setRPUses(RPUsesInt - 1);
        } else {
          // Handle case where uses are exhausted
          // diceBox.clean();
        }
      }
    }
  };

  return (
    <div
      key={id}
      className={`cota_rp_container cota_rp_container${darkMode()}`}
    >
      <div className='cota_rp_title'>
        <div className='cota_rp_indicator'>
          {id === 'Universal Talent' ? (
            <>RP BASIC:</>
          ) : id === 'Minor Talent' ? (
            <>RP MINOR:</>
          ) : id === 'Major Talent' ? (
            <>RP MAJOR:</>
          ) : id === 'Subpath Talent' ? (
            <>RP SUBPATH:</>
          ) : (
            <></>
          )}
        </div>

        <div className='cota_rp_name' suppressContentEditableWarning>
          {RPAbilityName}
        </div>
      </div>
      <div className='cota_rp_explainer' suppressContentEditableWarning>
        {RPExplainer.split('\n').map((line, index) => (
          <span key={index}>
            {line}
            <br />
          </span>
        ))}
      </div>
      <div className='cota_rp_actions'>
        <div className='cota_rp_action_uses_title'>➤ USES:</div>
        <div
          className='cota_rp_action_uses'
          contentEditable
          suppressContentEditableWarning
          onBlur={(e) => setRPUses(e.target.innerText)}
        >
          {RPUses}
        </div>
        <div className='cota_rp_action_rolls_title'>➤ ROLL:</div>
        <div
          className='cota_rp_action_rolls'
          contentEditable
          suppressContentEditableWarning
          onBlur={(e) => setRPRoll(e.target.innerText)}
        >
          {RPRoll}
        </div>
        <div className='cota_rp_action_dice' onClick={roll}>
          🎲
        </div>
      </div>
    </div>
  );
};

export default CotaRPAbility;

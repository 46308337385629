import { AwesomeButton } from 'react-awesome-button';
import './../styles/Button.css';

import trialmentLogo from '../assets/logos/trialmentlogo.png';
import cotaLogo from '../assets/logos/cotalogo.png';
import logout from '../assets/logos/logout.png';
import lightmode from '../assets/logos/lightmode.png';
import darkmode from '../assets/logos/darkmode.png';

/*
types:
cinnabar | onyx | ivory | tomato | blue | green | placeholder | disabled
*/

/*
icons:
trialmentLogo | cotaLogo | logout | lightmode | darkmode
*/

//TODO replaceOnClickProp because of Span

export const Button = (props) => {
  const { loading, onPress, type, disabled, label, icon } = props;

  const doPress = () => {
    if (!disabled && type !== 'disabled') {
      onPress();
    }
  };

  //Icon Class
  const getIconClass = (icon) => {
    if (icon === 'trialmentLogo' || icon === 'cotaLogo') {
      return 'trialment_login_logo';
    }
    return ''; //logout | lightmode | darkmode
  };

  return (
    <span
      onClick={() => {
        !disabled && doPress();
      }}
    >
      <AwesomeButton
        //onPress={!disabled && onClick && onClick}
        //sometimes it doesn't work
        type={
          loading
            ? 'placeholder'
            : disabled
            ? 'disabled'
            : type
            ? type
            : 'tomato'
        }
      >
        {icon &&
          (icon === 'trialmentLogo' ||
            icon === 'cotaLogo' ||
            icon === 'logout' ||
            icon === 'lightmode' ||
            icon === 'darkmode') && (
            <>
              <img
                className={`system_logo_txt ${getIconClass(icon)}`}
                src={
                  //Icon Src
                  icon === 'trialmentLogo'
                    ? trialmentLogo
                    : icon === 'cotaLogo'
                    ? cotaLogo
                    : icon === 'logout'
                    ? logout
                    : icon === 'lightmode'
                    ? lightmode
                    : icon === 'darkmode'
                    ? darkmode
                    : ''
                }
                alt={''}
              />
              &nbsp;
            </>
          )}
        {label}
      </AwesomeButton>
    </span>
  );
};

export const RPAbility = [
  {
    path: 'Kryptios',
    subpath: '',
    name: 'Shadowalk',
    slot: 'Minor Talent', // Universal Talent, Minor Talent, Major Talent , Subpath Talent
    description: [
      'The Kryptios wields influence over shadows and dim light, allowing them to absorb any light source by reducing its radiation and allowing dim light to prevail. The Kryptios can manipulate or reduce light in an area to create dim light or extinguish it completely, forming any desired shape devoid of light sources. If someone creates a light source within the area controlled by the Kryptios, such as lighting a torch, the torch ignites but does not emit any light. The Kryptios can see normally within the supernatural darkness they create. These powers of light and darkness control work better during the afternoon hours when sunlight diminishes. If used during morning or midday, greater effort is required from the Kryptios, with uncertain results. When the Kryptios is in dim light or darkness, they are enhanced by the shadow covering their steps and absorbing sounds. The Kryptios adds an additional d6 to all agility roleplay dice rolls while in dim light or darkness (natural or supernatural).',
      'The Kryptios wields influence over shadows and dim light, allowing them to absorb any light source by reducing its radiation and allowing dim light to prevail. The Kryptios can manipulate or reduce light in an area to create dim light or extinguish it completely, forming any desired shape devoid of light sources. If someone creates a light source within the area controlled by the Kryptios, such as lighting a torch, the torch ignites but does not emit any light. The Kryptios can see normally within the supernatural darkness they create. These powers of light and darkness control work better during the afternoon hours when sunlight diminishes. If used during morning or midday, greater effort is required from the Kryptios, with uncertain results. When the Kryptios is in dim light or darkness, they are enhanced by the shadow covering their steps and absorbing sounds. The Kryptios adds an additional d6 to all agility roleplay dice rolls while in dim light or darkness (natural or supernatural).',
      'The Kryptios wields influence over shadows and dim light, allowing them to absorb any light source by reducing its radiation and allowing dim light to prevail. The Kryptios can manipulate or reduce light in an area to create dim light or extinguish it completely, forming any desired shape devoid of light sources. If someone creates a light source within the area controlled by the Kryptios, such as lighting a torch, the torch ignites but does not emit any light. The Kryptios can see normally within the supernatural darkness they create. These powers of light and darkness control work better during the afternoon hours when sunlight diminishes. If used during morning or midday, greater effort is required from the Kryptios, with uncertain results. When the Kryptios is in dim light or darkness, they are enhanced by the shadow covering their steps and absorbing sounds. The Kryptios adds an additional d6 to all agility roleplay dice rolls while in dim light or darkness (natural or supernatural).',
      'The Kryptios wields influence over shadows and dim light, allowing them to absorb any light source by reducing its radiation and allowing dim light to prevail. The Kryptios can manipulate or reduce light in an area to create dim light or extinguish it completely, forming any desired shape devoid of light sources. If someone creates a light source within the area controlled by the Kryptios, such as lighting a torch, the torch ignites but does not emit any light. The Kryptios can see normally within the supernatural darkness they create. These powers of light and darkness control work better during the afternoon hours when sunlight diminishes. If used during morning or midday, greater effort is required from the Kryptios, with uncertain results. When the Kryptios is in dim light or darkness, they are enhanced by the shadow covering their steps and absorbing sounds. The Kryptios adds an additional d6 to all agility roleplay dice rolls while in dim light or darkness (natural or supernatural).',
    ],

    uses: ['Limitless', 'Limitless', 'Limitless', 'Limitless'],
    RPRoll: ['1d6', '2d6', '3d6', '4d6'],
  },
  {
    path: 'Kryptios',
    subpath: '',
    name: 'Shadowmeld',
    slot: 'Major Talent', // Universal Talent, Minor Talent, Major Talent , Subpath Talent
    description: [
      'Kryptios can blend with the shadows himself and one companion from his team, making them practically invisible and untraceable by mortal means. Even in full sunlight, this power dims the light as if a cloud passes, creating a semi-darkness. The duration of this supernatural invisibility is one hour. Finally, opponents protected by ichor can attempt to detect the Awakened hiding within the shadows.',
      'Kryptios can blend with the shadows himself and two companions from his team, making them practically invisible and untraceable by mortal means. Even in full sunlight, this power dims the light as if a cloud passes, creating a semi-darkness. The duration of this supernatural invisibility is two hour. Finally, opponents protected by ichor can attempt to detect the Awakened hiding within the shadows.',
      'Kryptios can blend with the shadows himself and three companions from his team, making them practically invisible and untraceable by mortal means. Even in full sunlight, this power dims the light as if a cloud passes, creating a semi-darkness. The duration of this supernatural invisibility is one hour. Finally, opponents protected by ichor can attempt to detect the Awakened hiding within the shadows.',
      'Kryptios can blend with the shadows himself and all companions from his team, making them practically invisible and untraceable by mortal means. Even in full sunlight, this power dims the light as if a cloud passes, creating a semi-darkness. The duration of this supernatural invisibility is one hour. Finally, opponents protected by ichor can attempt to detect the Awakened hiding within the shadows.',
    ],

    uses: ['1', '1', '2', '2'],
    RPRoll: ['None', 'None', 'None', 'None'],
  },
  {
    path: 'Kryptios',
    subpath: 'Shadowdancer',
    name: 'Shadowjump',
    slot: 'Subpath Talent', // Universal Talent, Minor Talent, Major Talent
    description: [
      '“Shadowjump” is a mysterious and otherworldly power wielded by the enigmatic Shadowdancer, allowing them and one chosen ally to traverse the intricate network of twilight, teleporting from one shadowy location to another with seamless grace.In practice, a shroud of darkness envelops them and their chosen companion. The very essence of darkness weaves around them, obscuring their forms until they vanish into the shadows. With an effortless transition, they emerge from another shadowy spot, their arrival as silent and swift as their departure.In order for this power to activate, the Shadowdancer and his ally need to be submerged in darkness or dim light. The destination spot also needs to be covered in shadow or darkness. In the absence of shadow or dim light, this power cannot be activated. There is no limit to the distance travelled by this power as long as the destination spot is visible.',
      '“Shadowjump” is a mysterious and otherworldly power wielded by the enigmatic Shadowdancer, allowing them and two chosen allies to traverse the intricate network of twilight, teleporting from one shadowy location to another with seamless grace.In practice, a shroud of darkness envelops them and their chosen companion. The very essence of darkness weaves around them, obscuring their forms until they vanish into the shadows. With an effortless transition, they emerge from another shadowy spot, their arrival as silent and swift as their departure.In order for this power to activate, the Shadowdancer and his ally need to be submerged in darkness or dim light. The destination spot also needs to be covered in shadow or darkness. In the absence of shadow or dim light, this power cannot be activated. There is no limit to the distance travelled by this power as long as the destination spot is visible.',
      '“Shadowjump” is a mysterious and otherworldly power wielded by the enigmatic Shadowdancer, allowing them and three chosen allies to traverse the intricate network of twilight, teleporting from one shadowy location to another with seamless grace.In practice, a shroud of darkness envelops them and their chosen companion. The very essence of darkness weaves around them, obscuring their forms until they vanish into the shadows. With an effortless transition, they emerge from another shadowy spot, their arrival as silent and swift as their departure.In order for this power to activate, the Shadowdancer and his ally need to be submerged in darkness or dim light. The destination spot also needs to be covered in shadow or darkness. In the absence of shadow or dim light, this power cannot be activated. There is no limit to the distance travelled by this power as long as the destination spot is visible.',
      '“Shadowjump” is a mysterious and otherworldly power wielded by the enigmatic Shadowdancer, allowing them and four chosen allies to traverse the intricate network of twilight, teleporting from one shadowy location to another with seamless grace.In practice, a shroud of darkness envelops them and their chosen companion. The very essence of darkness weaves around them, obscuring their forms until they vanish into the shadows. With an effortless transition, they emerge from another shadowy spot, their arrival as silent and swift as their departure.In order for this power to activate, the Shadowdancer and his ally need to be submerged in darkness or dim light. The destination spot also needs to be covered in shadow or darkness. In the absence of shadow or dim light, this power cannot be activated. There is no limit to the distance travelled by this power as long as the destination spot is visible.',
    ],
    uses: ['2', '2', '2', '3'],
    RPRoll: ['None', 'None', 'None', 'None'],
  },
  {
    path: 'Kryptios',
    subpath: 'Assassin',
    name: 'Shadow Phasing',
    slot: 'Subpath Talent', // Universal Talent, Minor Talent, Major Talent
    description: [
      '“Shadow Phasing” is a mysterious power wielded by the Assassin, enabling him and one of his companions to become one with the shadows and pass through solid obstacles, including walls, as if they were a mere extension of the darkness itself.Their form seems to liquefy, turning into an amorphous, inky substance that melds seamlessly with the surrounding shadows. As they approach a wall or barrier, they are enveloped by the darkness, their body dispersing and allowing them to slip through it like a wisp of smoke.This power is not without its limitations; the Assassin must remain in contact with a shadowy area or surface to use it, and they cannot phase through structures that are magically warded or specifically protected against such intrusions.',
      '“Shadow Phasing” is a mysterious power wielded by the Assassin, enabling him and two of his companions to become one with the shadows and pass through solid obstacles, including walls, as if they were a mere extension of the darkness itself.Their form seems to liquefy, turning into an amorphous, inky substance that melds seamlessly with the surrounding shadows. As they approach a wall or barrier, they are enveloped by the darkness, their body dispersing and allowing them to slip through it like a wisp of smoke.This power is not without its limitations; the Assassin must remain in contact with a shadowy area or surface to use it, and they cannot phase through structures that are magically warded or specifically protected against such intrusions',
      '“Shadow Phasing” is a mysterious power wielded by the Assassin, enabling him and three of his companions to become one with the shadows and pass through solid obstacles, including walls, as if they were a mere extension of the darkness itself.Their form seems to liquefy, turning into an amorphous, inky substance that melds seamlessly with the surrounding shadows. As they approach a wall or barrier, they are enveloped by the darkness, their body dispersing and allowing them to slip through it like a wisp of smoke.This power is not without its limitations; the Assassin must remain in contact with a shadowy area or surface to use it, and they cannot phase through structures that are magically warded or specifically protected against such intrusions',
      '“Shadow Phasing” is a mysterious power wielded by the Assassin, enabling him and four of his companions to become one with the shadows and pass through solid obstacles, including walls, as if they were a mere extension of the darkness itself.Their form seems to liquefy, turning into an amorphous, inky substance that melds seamlessly with the surrounding shadows. As they approach a wall or barrier, they are enveloped by the darkness, their body dispersing and allowing them to slip through it like a wisp of smoke.This power is not without its limitations; the Assassin must remain in contact with a shadowy area or surface to use it, and they can phase through structures that are magically warded or specifically protected against such intrusions',
    ],
    uses: ['2', '2', '2', '3'],
    RPRoll: ['None', 'None', 'None', 'None'],
  },
  {
    path: 'Alchemist',
    subpath: '',
    name: 'Alchemical Elixirs',
    slot: 'Minor Talent', // Universal Talent, Minor Talent, Major Talent , Subpath Talent
    description: [
      'Alchemist’s Secret Blend: This elixir, with its distinctive and bitter taste, can rejuvenate anyone who drinks it, activating and enhancing their mental abilities and revitalizing the body and spirit. It provides energy and clarity and is ideal for starting one’s day, making Alchemists particularly popular among the Awakened. Awakened who consume this elixir add a d6 either to all intuition roleplay dice or to divination dice (but not both) for the rest of the adventure. Volatile Brew: The Alchemist knows all the explosive combinations of ingredients, allowing him to create elixirs with various effects. The Volatile Brew, according to the Alchemist’s desire, can create fire that cannot be extinguished with water, capable of consuming anything flammable, or alternatively, it can create deep ice on any surface it comes into contact with.',
      'Alchemist’s Secret Blend: This elixir, with its distinctive and bitter taste, can rejuvenate anyone who drinks it, activating and enhancing their mental abilities and revitalizing the body and spirit. It provides energy and clarity and is ideal for starting one’s day, making Alchemists particularly popular among the Awakened. Awakened who consume this elixir add a d6 either to all intuition roleplay dice or to divination dice (but not both) for the rest of the adventure. Volatile Brew: The Alchemist knows all the explosive combinations of ingredients, allowing him to create elixirs with various effects. The Volatile Brew, according to the Alchemist’s desire, can create fire that cannot be extinguished with water, capable of consuming anything flammable, or alternatively, it can create deep ice on any surface it comes into contact with.',
      'Alchemist’s Secret Blend: This elixir, with its distinctive and bitter taste, can rejuvenate anyone who drinks it, activating and enhancing their mental abilities and revitalizing the body and spirit. It provides energy and clarity and is ideal for starting one’s day, making Alchemists particularly popular among the Awakened. Awakened who consume this elixir add a d6 either to all intuition roleplay dice or to divination dice (but not both) for the rest of the adventure. Volatile Brew: The Alchemist knows all the explosive combinations of ingredients, allowing him to create elixirs with various effects. The Volatile Brew, according to the Alchemist’s desire, can create fire that cannot be extinguished with water, capable of consuming anything flammable, or alternatively, it can create deep ice on any surface it comes into contact with.',
      'Alchemist’s Secret Blend: This elixir, with its distinctive and bitter taste, can rejuvenate anyone who drinks it, activating and enhancing their mental abilities and revitalizing the body and spirit. It provides energy and clarity and is ideal for starting one’s day, making Alchemists particularly popular among the Awakened. Awakened who consume this elixir add a d6 either to all intuition roleplay dice or to divination dice (but not both) for the rest of the adventure. Volatile Brew: The Alchemist knows all the explosive combinations of ingredients, allowing him to create elixirs with various effects. The Volatile Brew, according to the Alchemist’s desire, can create fire that cannot be extinguished with water, capable of consuming anything flammable, or alternatively, it can create deep ice on any surface it comes into contact with.',
    ],

    uses: ['2', '3', '4', '5'],
    RPRoll: ['None', 'None', 'None', 'None'],
  },
  {
    path: 'Alchemist',
    subpath: '',
    name: 'Potent Brews',
    slot: 'Major Talent', // Universal Talent, Minor Talent, Major Talent , Subpath Talent
    description: [
      'Transmutation Elixir: This elixir, upon contact with any surface, can instantly change its substance to a different material. For example, it can turn a stone wall into glass or transform a lake of lava into plain water. The change is permanent, and the elixir cannot affect any living organism or objects held or possessed by anyone. Additionally, objects of strong magic can resist this mixture. This elixir can affect up to two square meters of inorganic matter.The duration of the trasmutation is permanent. Alteration Brew: Unlike the Transmutation Elixir, this brew can affect organic matter. When consumed, its effect is immediate, transforming the Alchemist’s characteristics into any desired form. The Alchemist can control through this brew his height, gender, facial features, hair length, and any other detail he deems necessary regardless of how small or large it is. However, the power of this brew does not affect the Alchemist’s voice or behavior, and a careful observer may detect some inconsistencies if, for example, the Alchemist has transformed into someone known. The duration of the transformation lasts for one roleplaying instance.',
      'Transmutation Elixir: This elixir, upon contact with any surface, can instantly change its substance to a different material. For example, it can turn a stone wall into glass or transform a lake of lava into plain water. The change is permanent, and the elixir cannot affect any living organism or objects held or possessed by anyone. Additionally, objects of strong magic can resist this mixture. This elixir can affect up to two square meters of inorganic matter.The duration of the trasmutation is permanent. Alteration Brew: Unlike the Transmutation Elixir, this brew can affect organic matter. When consumed, its effect is immediate, transforming the Alchemist’s characteristics into any desired form. The Alchemist can control through this brew his height, gender, facial features, hair length, and any other detail he deems necessary regardless of how small or large it is. However, the power of this brew does not affect the Alchemist’s voice or behavior, and a careful observer may detect some inconsistencies if, for example, the Alchemist has transformed into someone known. The duration of the transformation lasts for one roleplaying instance.',
      'Transmutation Elixir: This elixir, upon contact with any surface, can instantly change its substance to a different material. For example, it can turn a stone wall into glass or transform a lake of lava into plain water. The change is permanent, and the elixir cannot affect any living organism or objects held or possessed by anyone. Additionally, objects of strong magic can resist this mixture. This elixir can affect up to two square meters of inorganic matter.The duration of the trasmutation is permanent. Alteration Brew: Unlike the Transmutation Elixir, this brew can affect organic matter. When consumed, its effect is immediate, transforming the Alchemist’s characteristics into any desired form. The Alchemist can control through this brew his height, gender, facial features, hair length, and any other detail he deems necessary regardless of how small or large it is. However, the power of this brew does not affect the Alchemist’s voice or behavior, and a careful observer may detect some inconsistencies if, for example, the Alchemist has transformed into someone known. The duration of the transformation lasts for one roleplaying instance.',
      'Transmutation Elixir: This elixir, upon contact with any surface, can instantly change its substance to a different material. For example, it can turn a stone wall into glass or transform a lake of lava into plain water. The change is permanent, and the elixir cannot affect any living organism or objects held or possessed by anyone. Additionally, objects of strong magic can resist this mixture. This elixir can affect up to two square meters of inorganic matter.The duration of the trasmutation is permanent. Alteration Brew: Unlike the Transmutation Elixir, this brew can affect organic matter. When consumed, its effect is immediate, transforming the Alchemist’s characteristics into any desired form. The Alchemist can control through this brew his height, gender, facial features, hair length, and any other detail he deems necessary regardless of how small or large it is. However, the power of this brew does not affect the Alchemist’s voice or behavior, and a careful observer may detect some inconsistencies if, for example, the Alchemist has transformed into someone known. The duration of the transformation lasts for one roleplaying instance.',
    ],

    uses: ['2', '2', '3', 'Limitless'],
    RPRoll: ['None', 'None', 'None', 'None'],
  },
  {
    path: 'Alchemist',
    subpath: 'Apothecary',
    name: 'Alchemical Elixirs',
    slot: 'Subpath Talent', // Universal Talent, Minor Talent, Major Talent , Subpath Talent
    description: [
      'The Apothecary Sage is a scholar of herbalism and natural remedies, harnessing the power of botanical ingredients to provide healing and support to allies. They specialize in creating salves, poultices, and herbal remedies that alleviate ailments and boost the resilience of their comrades. He receives automatically an extra d6 in any check that he makes that has anything to do with nature. He can also craft once per session 6 random minor potions. Those potions resolve at the end of the session. During any combat, the Artificer can ask the Narator to reveal one important information about a single enemy.',
      'The Apothecary Sage is a scholar of herbalism and natural remedies, harnessing the power of botanical ingredients to provide healing and support to allies. They specialize in creating salves, poultices, and herbal remedies that alleviate ailments and boost the resilience of their comrades. He receives automatically an extra d6 in any check that he makes that has anything to do with nature. He can also craft once per session 6  minor potions of his choise. Those potions resolve at the end of the session. During any combat, the Artificer can ask the Narator to reveal one important information about a single enemy.',
      'The Apothecary Sage is a scholar of herbalism and natural remedies, harnessing the power of botanical ingredients to provide healing and support to allies. They specialize in creating salves, poultices, and herbal remedies that alleviate ailments and boost the resilience of their comrades. He receives automatically an extra d6 in any check that he makes that has anything to do with nature. He can also craft once per session 6 minor potions of his choise and 1 random rare potion. Those potions resolve at the end of the session. During any combat, the Artificer can ask the Narator to reveal one important information about a single enemy.',
      'The Apothecary Sage is a scholar of herbalism and natural remedies, harnessing the power of botanical ingredients to provide healing and support to allies. They specialize in creating salves, poultices, and herbal remedies that alleviate ailments and boost the resilience of their comrades. He receives automatically an extra d6 in any check that he makes that has anything to do with nature. He can also craft once per session 6 minor potions of his choise and 1 rare potion of his choise. Those potions resolve at the end of the session. During any combat, the Artificer can ask the Narator to reveal one important information about a single enemy.',
    ],

    uses: ['Limitless', 'Limitless', 'Limitless', 'Limitless'],
    RPRoll: ['2d6', '3d6', '3d6', '4d6'],
  },
  {
    path: 'Alchemist',
    subpath: 'Elixir Artisan',
    name: 'Alchemical Elixirs',
    slot: 'Subpath Talent', // Universal Talent, Minor Talent, Major Talent , Subpath Talent
    description: [
      'The Elixir Artisan can use the ichor surrounding himself in order to create new and powerful potions. At the beginning of the session the Elixir Artisan receive a number of brew points that depends on the level of the Elixir Artisan. She can use those points in order to create her own powerful potions in any time, except in combat. Those elixir devolve at the end of the session. No more then 3 points can be spend in a single elixir.The Elixir Artisan starts the session with 7 points.',
      'The Elixir Artisan can use the ichor surrounding himself in order to create new and powerful potions. At the beginning of the session the Elixir Artisan receive a number of brew points that depends on the level of the Elixir Artisan. She can use those points in order to create her own powerful potions in any time, except in combat. Those elixir devolve at the end of the session. No more then 4 points can be spend in a single elixir.The Elixir Artisan starts the session with 10 points.',
      'The Elixir Artisan can use the ichor surrounding himself in order to create new and powerful potions. At the beginning of the session the Elixir Artisan receive a number of brew points that depends on the level of the Elixir Artisan. She can use those points in order to create her own powerful potions in any time, except in combat. Those elixir devolve at the end of the session. No more then 5 points can be spend in a single elixir.The Elixir Artisan starts the session with 13 points.',
      'The Elixir Artisan can use the ichor surrounding himself in order to create new and powerful potions. At the beginning of the session the Elixir Artisan receive a number of brew points that depends on the level of the Elixir Artisan. She can use those points in order to create her own powerful potions in any time, except in combat. Those elixir devolve at the end of the session. No more then 6 points can be spend in a single elixir.The Elixir Artisan starts the session with 16 points.',
    ],

    uses: ['1', '1', '1', '1'],
    RPRoll: ['None', 'None', 'None', 'None'],
  },
  {
    path: 'Blighter',
    subpath: '',
    name: 'Corrupted Bloom',
    slot: 'Minor Talent', // Universal Talent, Minor Talent, Major Talent , Subpath Talent
    description: [
      'A single whisper from a Blighter can drive awakened to achieve incredible feats by sacrificing a portion of their life force. When a Blighter observes an awakened (including themselves) failing a roleplay dice roll, they can push the life energy of the awakened to its limits. When this happens, the Blighter can manipulate the vital fluids in the awakened’s body and offer them the effects of adrenaline, clearing their mind and strengthening their body for their goal. The awakened who fails the dice roll can roll an additional d6 and add the result to their total, thus turning a potential failure into a success. However, the body does not respond positively to this external pressure and is pushed to its limits. The result of the additional die roll is suffered as damage that cannot be healed in any way until the end of the adventure. Essentially, with this power, the maximum life points of the target are reduced until the end of the adventure. However, the  willing consent of the affected awakened is also required for this to occur.',
      'A single whisper from a Blighter can drive awakened to achieve incredible feats by sacrificing a portion of their life force. When a Blighter observes an awakened (including themselves) failing a roleplay dice roll, they can push the life energy of the awakened to its limits. When this happens, the Blighter can manipulate the vital fluids in the awakened’s body and offer them the effects of adrenaline, clearing their mind and strengthening their body for their goal. The awakened who fails the dice roll can roll an additional d6 and add the result to their total, thus turning a potential failure into a success. However, the body does not respond positively to this external pressure and is pushed to its limits. The result of the additional die roll is suffered as half the damage that cannot be healed in any way until the end of the adventure. Essentially, with this power, the maximum life points of the target are reduced until the end of the adventure. However, the  willing consent of the affected awakened is also required for this to occur.',
      'A single whisper from a Blighter can drive awakened to achieve incredible feats by sacrificing a portion of their life force. When a Blighter observes an awakened (including themselves) failing a roleplay dice roll, they can push the life energy of the awakened to its limits. When this happens, the Blighter can manipulate the vital fluids in the awakened’s body and offer them the effects of adrenaline, clearing their mind and strengthening their body for their goal. The awakened who fails the dice roll can roll an additional d6 and add the result to their total, thus turning a potential failure into a success. However, the body does not respond positively to this external pressure and is pushed to its limits. The result of the additional die roll is suffered as half the damage that cannot be healed in any way until the end of the adventure. Essentially, with this power, the maximum life points of the target are reduced until the end of the adventure. However, the  willing consent of the affected awakened is also required for this to occur.',
      'A single whisper from a Blighter can drive awakened to achieve incredible feats by sacrificing a portion of their life force. When a Blighter observes an awakened (including themselves) failing a roleplay dice roll, they can push the life energy of the awakened to its limits. When this happens, the Blighter can manipulate the vital fluids in the awakened’s body and offer them the effects of adrenaline, clearing their mind and strengthening their body for their goal. The awakened who fails the dice roll can roll an additional d6 and add the result to their total, thus turning a potential failure into a success. However, the body does not respond positively to this external pressure and is pushed to its limits. The result of the additional die roll is suffered as half the damage that cannot be healed in any way until the end of the adventure. Essentially, with this power, the maximum life points of the target are reduced until the end of the adventure. However, the  willing consent of the affected awakened is also required for this to occur.',
    ],

    uses: ['Limitless', 'Limitless', 'Limitless', 'Limitless'],
    RPRoll: ['1d6', '1d6', '2d6', '2d6'],
  },
  {
    path: 'Blighter',
    subpath: '',
    name: 'Eroding Touch',
    slot: 'Major Talent', // Universal Talent, Minor Talent, Major Talent , Subpath Talent
    description: [
      'The Blighter can extend their influence towards destruction and decay onto any natural obstacle. The Blighter concentrates their power at the tips of their fingers and can corrode anything they touch. This power can turn giant rocks into pebbles, bring down stone walls, rust metal armor entirely, or petrify wooden obstacles.This ability can affect wooden surfaces and simple soft metals (e.g., copper, tin) not larger than four cubic meters in size. Objects protected by ichor or other magical/divine protections can resist this power. After corroding anything, the Blighter gains a d6 that they can use in any roleplay roll they desire.',
      'The Blighter can extend their influence towards destruction and decay onto any natural obstacle. The Blighter concentrates their power at the tips of their fingers and can corrode anything they touch. This power can turn giant rocks into pebbles, bring down stone walls, rust metal armor entirely, or petrify wooden obstacles.This ability can affect wooden surfaces and simple soft metals (e.g., copper, tin) not larger than four cubic meters in size. Objects protected by ichor or other magical/divine protections can resist this power. After corroding anything, the Blighter gains a d6 that they can use in any roleplay roll they desire.',
      'The Blighter can extend their influence towards destruction and decay onto any natural obstacle. The Blighter concentrates their power at the tips of their fingers and can corrode anything they touch. This power can turn giant rocks into pebbles, bring down stone walls, rust metal armor entirely, or petrify wooden obstacles.This ability can affect wooden surfaces and simple soft metals (e.g., copper, tin) not larger than four cubic meters in size. Objects protected by ichor or other magical/divine protections can resist this power. After corroding anything, the Blighter gains a d6 that they can use in any roleplay roll they desire.',
      'The Blighter can extend their influence towards destruction and decay onto any natural obstacle. The Blighter concentrates their power at the tips of their fingers and can corrode anything they touch. This power can turn giant rocks into pebbles, bring down stone walls, rust metal armor entirely, or petrify wooden obstacles.This ability can affect wooden surfaces and simple soft metals (e.g., copper, tin) not larger than four cubic meters in size. Objects protected by ichor or other magical/divine protections can resist this power. After corroding anything, the Blighter gains a d6 that they can use in any roleplay roll they desire.',
    ],

    uses: ['1', '1', '1', '1'],
    RPRoll: ['1d6', '2d6', '3d6', '4d6'],
  },
  {
    path: 'Blighter',
    subpath: 'Ruinbearer',
    name: 'Necrotic Harbinger',
    slot: 'Subpath Talent', // Universal Talent, Minor Talent, Major Talent , Subpath Talent
    description: [
      'The Ruinbearer has mastered the art of self-punishment in front of the great good. He can connect ritualistic a player whit his inner ichor in order to potent his ichor abilities. The target of the ritual whenever deals combat damage with a scroll (other than weapon scroll and pet scroll), he can suffer 2 damage in order to deal 2 more damage.',
      'The Ruinbearer has mastered the art of self-punishment in front of the great good. He can connect ritualistic a player whit his inner ichor in order to potent his ichor abilities. The target of the ritual whenever deals combat damage with a scroll (other than weapon scroll and pet scroll), he can suffer 2 or 3 damage in order to deal 2 or 3 more damage.Can target 1 more  ally with ability.',
      'The Ruinbearer has mastered the art of self-punishment in front of the great good. He can connect ritualistic a player whit his inner ichor in order to potent his ichor abilities. The target of the ritual whenever deals combat damage with a scroll (other than weapon scroll and pet scroll), he can suffer 2 or 3 damage in order to deal 2 or 3 more damage.Can target 1 more  ally with ability.',
      'The Ruinbearer has mastered the art of self-punishment in front of the great good. He can connect ritualistic a player whit his inner ichor in order to potent his ichor abilities. The target of the ritual whenever deals combat damage with a scroll (other than weapon scroll and pet scroll), he can suffer 2,3, or 4 damage in order to deal 2.3 or 4 more damage.Can target 2 more  allies with ability.',
    ],

    uses: ['1', '1', '1', '1'],
    RPRoll: ['None', 'None', 'None', 'None'],
  },
  {
    path: 'Blighter',
    subpath: 'Chaosweaver',
    name: 'Entropic Hex',
    slot: 'Subpath Talent', // Universal Talent, Minor Talent, Major Talent , Subpath Talent
    description: [
      'If the blighter already used his minor ability on a player, then he leaves him with a hex. The blighter can target in combat a with hex and reuse his minor role play ability “corrupted bloom” on that target again and in combat. The target of this minor receives twice the amount of damage from “corrupted bloom”. The target of “corrupted bloom” can choose not to use the blighters abilitie before but must deside not to use the ability before he rolls the additional dice',
      'If the blighter already used his minor ability on a player, then he leaves him with a hex. The blighter can target in combat a with hex and reuse his minor role play ability “corrupted bloom” on that target again and in combat. The target of this minor receives 1.5 the amount of damage from “corrupted bloom”. The target of “corrupted bloom” can choose not to use the blighters abilitie before but must deside not to use the ability before he rolls the additional dice',
      'If the blighter already used his minor ability on a player, then he leaves him with a hex. The blighter can target in combat a with hex and reuse his minor role play ability “corrupted bloom” on that target again and in combat. The target of this minor receives normal the amount of damage from “corrupted bloom”. The target of “corrupted bloom” can choose not to use the blighters abilitie before but must deside not to use the ability before he rolls the additional dice',
      'If the blighter already used his minor ability on a player, then he leaves him with a hex. The blighter can target in combat a with hex and reuse his minor role play ability “corrupted bloom” on that target again and in combat. The target of this minor receives half the amount of damage from “corrupted bloom”. The target of “corrupted bloom” can choose not to use the blighters abilitie before but must deside not to use the ability before he rolls the additional dice',
    ],

    uses: ['Limitless', 'Limitless', 'Limitless', 'Limitless'],
    RPRoll: ['None', 'None', 'None', 'None'],
  },
  {
    path: 'Any',
    subpath: '',
    name: 'Ichor’s Manifestation',
    slot: 'Universal Talent', // Universal Talent, Minor Talent, Major Talent , Subpath Talent
    description: [
      'With a focused invocation, you call upon the might of Ichor to elevate your deeds to superhuman levels. Once per adventure, you can unleash the full potential of Ichor’s Manifestation, granting you a surge of divine power to aid in achieving extraordinary feats. Whether it’s leaping great distances, withstanding immense pressures, or discerning hidden truths, Ichor’s Manifestation enhances your Might, Agility, Intuition, or Divination rolls, allowing you to add 1d6 to your next attempt. However, this manifestation cannot be combined with any other Path Talent. ',
      'With a focused invocation, you call upon the might of Ichor to elevate your deeds to superhuman levels. Once per adventure, you can unleash the full potential of Ichor’s Manifestation, granting you a surge of divine power to aid in achieving extraordinary feats. Whether it’s leaping great distances, withstanding immense pressures, or discerning hidden truths, Ichor’s Manifestation enhances your Might, Agility, Intuition, or Divination rolls, allowing you to add 1d6 to your next attempt. However, this manifestation cannot be combined with any other Path Talent.  ',
      'With a focused invocation, you call upon the might of Ichor to elevate your deeds to superhuman levels. Once per adventure, you can unleash the full potential of Ichor’s Manifestation, granting you a surge of divine power to aid in achieving extraordinary feats. Whether it’s leaping great distances, withstanding immense pressures, or discerning hidden truths, Ichor’s Manifestation enhances your Might, Agility, Intuition, or Divination rolls, allowing you to add 1d6 to your next attempt. However, this manifestation cannot be combined with any other Path Talent.  ',
      'With a focused invocation, you call upon the might of Ichor to elevate your deeds to superhuman levels. Once per adventure, you can unleash the full potential of Ichor’s Manifestation, granting you a surge of divine power to aid in achieving extraordinary feats. Whether it’s leaping great distances, withstanding immense pressures, or discerning hidden truths, Ichor’s Manifestation enhances your Might, Agility, Intuition, or Divination rolls, allowing you to add 1d6 to your next attempt. However, this manifestation cannot be combined with any other Path Talent. ',
    ],

    uses: ['1', '1', '2', '2'],
    RPRoll: ['1d6', '1d6', '2d6', '2d6'],
  },
  {
    path: 'Myrmidon',
    subpath: '',
    name: 'Ancestral Guardian',
    slot: 'Minor Talent', // Universal Talent, Minor Talent, Major Talent , Subpath Talent
    description: [
      'The connection of the Myrmidon with his ancestors is not only based on the training and martial principles he follows but is so powerful that it can be manifested and take shape in reality. The Myrmidon can summon at any time the spirit of one of his warrior ancestors, which materializes and takes physical form in the world. It is at least three meters tall and can take the form of an ancient hoplite or ome other type of ancient warrior, although it lacks the ability to speak and communicate with the Myrmidon’s team. However, it can communicate basic ideas and images with the Myrmidon through telepathy.This ancient ancestor can merge his spiritual powers with the Myrmidon and enhance his strength when needed, thus achieving superhuman feats of strength. The Myrmidon adds an additional d6 die to all might roleplay rolls he makes during the adventure.',
      'The connection of the Myrmidon with his ancestors is not only based on the training and martial principles he follows but is so powerful that it can be manifested and take shape in reality. The Myrmidon can summon at any time the spirit of one of his warrior ancestors, which materializes and takes physical form in the world. It is at least three meters tall and can take the form of an ancient hoplite or ome other type of ancient warrior, although it lacks the ability to speak and communicate with the Myrmidon’s team. However, it can communicate basic ideas and images with the Myrmidon through telepathy.This ancient ancestor can merge his spiritual powers with the Myrmidon and enhance his strength when needed, thus achieving superhuman feats of strength. The Myrmidon adds an additional d6 die to all might roleplay rolls he makes during the adventure.',
      'The connection of the Myrmidon with his ancestors is not only based on the training and martial principles he follows but is so powerful that it can be manifested and take shape in reality. The Myrmidon can summon at any time the spirit of one of his warrior ancestors, which materializes and takes physical form in the world. It is at least three meters tall and can take the form of an ancient hoplite or ome other type of ancient warrior, although it lacks the ability to speak and communicate with the Myrmidon’s team. However, it can communicate basic ideas and images with the Myrmidon through telepathy.This ancient ancestor can merge his spiritual powers with the Myrmidon and enhance his strength when needed, thus achieving superhuman feats of strength. The Myrmidon adds an additional d6 die to all might roleplay rolls he makes during the adventure.',
      'The connection of the Myrmidon with his ancestors is not only based on the training and martial principles he follows but is so powerful that it can be manifested and take shape in reality. The Myrmidon can summon at any time the spirit of one of his warrior ancestors, which materializes and takes physical form in the world. It is at least three meters tall and can take the form of an ancient hoplite or ome other type of ancient warrior, although it lacks the ability to speak and communicate with the Myrmidon’s team. However, it can communicate basic ideas and images with the Myrmidon through telepathy.This ancient ancestor can merge his spiritual powers with the Myrmidon and enhance his strength when needed, thus achieving superhuman feats of strength. The Myrmidon adds an additional d6 die to all might roleplay rolls he makes during the adventure.',
    ],

    uses: ['Limitless', 'Limitless', 'Limitless', 'Limitless'],
    RPRoll: ['1d6', '2d6', '3d6', '4d6'],
  },
  {
    path: 'Myrmidon',
    subpath: '',
    name: 'Aegis of the Ancestors',
    slot: 'Major Talent', // Universal Talent, Minor Talent, Major Talent , Subpath Talent
    description: [
      'The Myrmidon can invoke his genealogy and summon the spirits of warrior ancestors. These spirits appear fully armed, wielding their shields, and encircle the Myrmidon’s group, ready to protect them from any danger. The awakened beings within this circle, adorned with ethereal shields and spears, are shielded from any external influence or force, remaining unscathed even from earthquakes, tsunamis, or other natural disasters. The Myrmidon can use this power to protect himself and one companion from his group. If attacked by an opponent protected by ichor, this power dissipates, initiating the battle process.',
      'The Myrmidon can invoke his genealogy and summon the spirits of warrior ancestors. These spirits appear fully armed, wielding their shields, and encircle the Myrmidon’s group, ready to protect them from any danger. The awakened beings within this circle, adorned with ethereal shields and spears, are shielded from any external influence or force, remaining unscathed even from earthquakes, tsunamis, or other natural disasters. The Myrmidon can use this power to protect himself and two companions from his group. If attacked by an opponent protected by ichor, this power dissipates, initiating the battle process.',
      'The Myrmidon can invoke his genealogy and summon the spirits of warrior ancestors. These spirits appear fully armed, wielding their shields, and encircle the Myrmidon’s group, ready to protect them from any danger. The awakened beings within this circle, adorned with ethereal shields and spears, are shielded from any external influence or force, remaining unscathed even from earthquakes, tsunamis, or other natural disasters. The Myrmidon can use this power to protect himself and three companions from his group. If attacked by an opponent protected by ichor, this power dissipates, initiating the battle process.',
      'The Myrmidon can invoke his genealogy and summon the spirits of warrior ancestors. These spirits appear fully armed, wielding their shields, and encircle the Myrmidon’s group, ready to protect them from any danger. The awakened beings within this circle, adorned with ethereal shields and spears, are shielded from any external influence or force, remaining unscathed even from earthquakes, tsunamis, or other natural disasters. The Myrmidon can use this power to protect himself and all companions from his group. If attacked by an opponent protected by ichor, this power dissipates, initiating the battle process.',
    ],

    uses: ['1', '2', '2', '3'],
    RPRoll: ['None', 'None', 'None', 'None'],
  },
  {
    path: 'Myrmidon',
    subpath: 'Spiritbound',
    name: 'Lineage Oracle',
    slot: 'Subpath Talent', // Universal Talent, Minor Talent, Major Talent , Subpath Talent
    description: [
      'The Spiritbound can call upon a senator in times of need to receive useful information and consult them about upcoming events.The Spiritbound can request information about a specific event, and the spirits of their ancestors will endeavor to provide their knowledge.The information they receive is subject to the best capability of the ancestor. The more significant the event or item about which the Spiritbound seeks knowledge and guidance, the more information the ancestor can provide.This power cannot be interfered with by another person, but the general knowledge of the ancestors may be inaccurate.',
      'The Spiritbound can call upon a senator in times of need to receive useful information and consult them about upcoming events.The Spiritbound can request information about a specific event, and the spirits of their ancestors will endeavor to provide their knowledge.The information they receive is subject to the best capability of the ancestor. The more significant the event or item about which the Spiritbound seeks knowledge and guidance, the more information the ancestor can provide.This power cannot be interfered with by another person, but the general knowledge of the ancestors may be inaccurate.',
      'The Spiritbound can call upon a senator in times of need to receive useful information and consult them about upcoming events.The Spiritbound can request information about a specific event, and the spirits of their ancestors will endeavor to provide their knowledge.The information they receive is subject to the best capability of the ancestor. The more significant the event or item about which the Spiritbound seeks knowledge and guidance, the more information the ancestor can provide.This power cannot be interfered with by another person, but the general knowledge of the ancestors may be inaccurate.Myrmidon’s allies can interact with the ansestors.',
      'The Spiritbound can call upon a senator in times of need to receive useful information and consult them about upcoming events.The Spiritbound can request information about a specific event, and the spirits of their ancestors will endeavor to provide their knowledge.The information they receive is subject to the best capability of the ancestor. The more significant the event or item about which the Spiritbound seeks knowledge and guidance, the more information the ancestor can provide.This power cannot be interfered with by another person, but the general knowledge of the ancestors may be inaccurate.Myrmidon’s allies can interact with the ansestors.',
    ],

    uses: ['1', '2', '3', '3'],
    RPRoll: ['None', 'None', 'None', 'None'],
  },
  {
    path: 'Myrmidon',
    subpath: 'Bloodbound',
    name: 'Resononce Analyzer',
    slot: 'Subpath Talent', // Universal Talent, Minor Talent, Major Talent , Subpath Talent
    description: [
      'The Bloodbound Myrmidon chooses to connect with the more savage and bloodlust spirits of his ancestors. This connection leads to significant transformations to the psychological state and the physical appearence of the Myrmidon. His voice becomes deeper and more intimidating. His body becomes larger and more threatening and his eyes glows with the spark of savagery. An otherwordly red aura of rage seems to be surrounding him at all times creating tension to everyone around him. However, his physical and spiritual strength can be perceived as empowering in many instances.The Bloodbound receives +1d6 to all roleplay Intuition rolls that involve any kind of intimidation or leadership.',
      'The Bloodbound Myrmidon chooses to connect with the more savage and bloodlust spirits of his ancestors. This connection leads to significant transformations to the psychological state and the physical appearence of the Myrmidon. His voice becomes deeper and more intimidating. His body becomes larger and more threatening and his eyes glows with the spark of savagery. An otherwordly red aura of rage seems to be surrounding him at all times creating tension to everyone around him. However, his physical and spiritual strength can be perceived as empowering in many instances.The Bloodbound receives +2d6 to all roleplay Intuition rolls that involve any kind of intimidation or leadership.',
      'The Bloodbound Myrmidon chooses to connect with the more savage and bloodlust spirits of his ancestors. This connection leads to significant transformations to the psychological state and the physical appearence of the Myrmidon. His voice becomes deeper and more intimidating. His body becomes larger and more threatening and his eyes glows with the spark of savagery. An otherwordly red aura of rage seems to be surrounding him at all times creating tension to everyone around him. However, his physical and spiritual strength can be perceived as empowering in many instances.The Bloodbound receives +3d6 to all roleplay Intuition rolls that involve any kind of intimidation or leadership.',
      'The Bloodbound Myrmidon chooses to connect with the more savage and bloodlust spirits of his ancestors. This connection leads to significant transformations to the psychological state and the physical appearence of the Myrmidon. His voice becomes deeper and more intimidating. His body becomes larger and more threatening and his eyes glows with the spark of savagery. An otherwordly red aura of rage seems to be surrounding him at all times creating tension to everyone around him. However, his physical and spiritual strength can be perceived as empowering in many instances.The Bloodbound receives +4d6 to all roleplay Intuition rolls that involve any kind of intimidation or leadership.',
    ],

    uses: ['Limitless', 'Limitless', 'Limitless', 'Limitless'],
    RPRoll: ['1d6', '2d6', '3d6', '4d6'],
  },
  {
    path: 'Clockwork Shaman',
    subpath: '',
    name: 'Pyromancy',
    slot: 'Minor Talent', // Universal Talent, Minor Talent, Major Talent , Subpath Talent
    description: [
      'The Clockwork Shaman’s mechanical creations allow him to control the fire around him. He can create fire and control its size, turning a candle into a bright torch or transforming a fire into a fiery column. He can absorb heat and flame to enhance his powers or release it later with devastating results. Additionally, the fire itself can be launched from various mechanisms on the Clockwork Shaman’s body to propel him abruptly in the desired direction, offering him great flexibility and speed.The Clockwork Shaman adds one additional d6 to all agility roleplay rolls he performs during the adventure.',
      'The Clockwork Shaman’s mechanical creations allow him to control the fire around him. He can create fire and control its size, turning a candle into a bright torch or transforming a fire into a fiery column. He can absorb heat and flame to enhance his powers or release it later with devastating results. Additionally, the fire itself can be launched from various mechanisms on the Clockwork Shaman’s body to propel him abruptly in the desired direction, offering him great flexibility and speed.The Clockwork Shaman adds two additional d6 to all agility roleplay rolls he performs during the adventure.',
      'The Clockwork Shaman’s mechanical creations allow him to control the fire around him. He can create fire and control its size, turning a candle into a bright torch or transforming a fire into a fiery column. He can absorb heat and flame to enhance his powers or release it later with devastating results. Additionally, the fire itself can be launched from various mechanisms on the Clockwork Shaman’s body to propel him abruptly in the desired direction, offering him great flexibility and speed.The Clockwork Shaman adds three additional d6 to all agility roleplay rolls he performs during the adventure.',
      'The Clockwork Shaman’s mechanical creations allow him to control the fire around him. He can create fire and control its size, turning a candle into a bright torch or transforming a fire into a fiery column. He can absorb heat and flame to enhance his powers or release it later with devastating results. Additionally, the fire itself can be launched from various mechanisms on the Clockwork Shaman’s body to propel him abruptly in the desired direction, offering him great flexibility and speed.The Clockwork Shaman adds four additional d6 to all agility roleplay rolls he performs during the adventure.',
    ],

    uses: ['Limitless', 'Limitless', 'Limitless', 'Limitless'],
    RPRoll: ['1d6', '2d6', '3d6', '4d6'],
  },
  {
    path: 'Clockwork Shaman',
    subpath: '',
    name: 'Pyrojet',
    slot: 'Major Talent', // Universal Talent, Minor Talent, Major Talent , Subpath Talent
    description: [
      'The Clockwork Shaman can use his contraptions (usually mechanical constructions integrated into his boots) to launch flames with tremendous force towards the ground, allowing him to propel himself into the sky at high speed. This flight of the Clockwork Shamans can reach immense speeds, covering great distances or bypassing obstacles encountered along his path.The Clockwork Shaman can perform superhuman horizontal and vertical jumps with this power. He can propel himself into the sky to a height of over 15 meters and delay his descent by controlling the flow of fire.',
      'The Clockwork Shaman can use his contraptions (usually mechanical constructions integrated into his boots) to launch flames with tremendous force towards the ground, allowing him to propel himself into the sky at high speed. This flight of the Clockwork Shamans can reach immense speeds, covering great distances or bypassing obstacles encountered along his path.The Clockwork Shaman can perform superhuman horizontal and vertical jumps with this power. He can propel himself into the sky to a height of over 15 meters and delay his descent by controlling the flow of fire.Can use it in order to fly small distances.',
      'The Clockwork Shaman can use his contraptions (usually mechanical constructions integrated into his boots) to launch flames with tremendous force towards the ground, allowing him to propel himself into the sky at high speed. This flight of the Clockwork Shamans can reach immense speeds, covering great distances or bypassing obstacles encountered along his path.The Clockwork Shaman can perform superhuman horizontal and vertical jumps with this power. He can propel himself into the sky to a height of over 15 meters and delay his descent by controlling the flow of fire.Can use his abilities for his allies to by expending 1 use.',
      'The Clockwork Shaman can use his contraptions (usually mechanical constructions integrated into his boots) to launch flames with tremendous force towards the ground, allowing him to propel himself into the sky at high speed. This flight of the Clockwork Shamans can reach immense speeds, covering great distances or bypassing obstacles encountered along his path.The Clockwork Shaman can perform superhuman horizontal and vertical jumps with this power. He can propel himself into the sky to a height of over 15 meters and delay his descent by controlling the flow of fire.',
    ],

    uses: ['3', '3', '3', '5'],
    RPRoll: ['None', 'None', 'None', 'None'],
  },
  {
    path: 'Clockwork Shaman',
    subpath: 'Pyromancer',
    name: 'Flamecharge',
    slot: 'Subpath Talent', // Universal Talent, Minor Talent, Major Talent , Subpath Talent
    description: [
      'The pyromancer can release a burst of steam from their clockwork apparatus, creating a dense fog that obscures vision. This ability manifests through the ichor and make the pyromancer and 2 other allies invisibly to the non-awakened eyes. Also, as long the pyromancer is inside this fog made from ichor, all his senses are enchanted and receives an extra d6 for all his intuition rolls',
      'The pyromancer can release a burst of steam from their clockwork apparatus, creating a dense fog that obscures vision. This ability manifests through the ichor and make the pyromancer and 3 other allies invisibly to the non-awakened eyes. Also, as long the pyromancer is inside this fog made from ichor, all his senses are enchanted and receives two extra d6 for all his intuition rolls',
      'The pyromancer can release a burst of steam from their clockwork apparatus, creating a dense fog that obscures vision. This ability manifests through the ichor and make the pyromancer and 4 other allies invisibly to the non-awakened eyes. Also, as long the pyromancer is inside this fog made from ichor, all his senses are enchanted and receives three extra d6 for all his intuition rolls',
      'The pyromancer can release a burst of steam from their clockwork apparatus, creating a dense fog that obscures vision. This ability manifests through the ichor and make the pyromancer and 5 other allies invisibly to the non-awakened eyes. Also, as long the pyromancer is inside this fog made from ichor, all his senses are enchanted and receives four extra d6 for all his intuition rolls',
    ],

    uses: ['2', '2', '2', '3'],
    RPRoll: ['1d6', '2d6', '3d6', '4d6'],
  },
  {
    path: 'Clockwork Shaman',
    subpath: 'Inferno Warden',
    name: 'Shadow Phasing',
    slot: 'Subpath Talent', // Universal Talent, Minor Talent, Major Talent , Subpath Talent
    description: [
      'The Inferno Warden can use his ichor in order to manipulate the fire and the steam. This power can make him react faster in front of danger in order to protect his allies. He can use his power reactively in order to make them reroll up to two dices on Might and Agility rolls.',
      'The Inferno Warden can use his ichor in order to manipulate the fire and the steam. This power can make him react faster in front of danger in order to protect his allies. He can use his power reactively in order to make them reroll up to three dices on Might and Agility rolls.',
      'The Inferno Warden can use his ichor in order to manipulate the fire and the steam. This power can make him react faster in front of danger in order to protect his allies. He can use his power reactively in order to make them reroll up to four dices on Might and Agility rolls.',
      'The Inferno Warden can use his ichor in order to manipulate the fire and the steam. This power can make him react faster in front of danger in order to protect his allies. He can use his power reactively in order to make them reroll up to five dices on Might and Agility rolls.',
    ],

    uses: ['2', '2', '3', '4'],
    RPRoll: ['1d6', '2d6', '3d6', '4d6'],
  },
];

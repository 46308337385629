import Rodal from 'rodal';
import { useState, useEffect, useRef } from 'react';
import { Button } from './Button';
import 'rodal/lib/rodal.css';
import '../styles/Cota.css';
import { combatScrolls } from './CotaScrollData';

export const CotaPath = (props) => {
  const {
    show,
    close,
    mode,
    path,
    handlePathChange,
    subpath,
    handleSubPathChange,
    isPathOrSubpath,
    subpathRPAbilityPlace,
    setSubpathRPAbilityPlace,
  } = props;
  const [isFullScreen, setIsFullScreen] = useState(false);
  const contentRef = useRef(null);

  useEffect(() => {
    const mediaQuery = window.matchMedia('(max-aspect-ratio: 1510/962)');

    const handleMediaChange = (e) => {
      setIsFullScreen(e.matches);
    };
    handleMediaChange(mediaQuery);
    mediaQuery.addListener(handleMediaChange);
    return () => mediaQuery.removeListener(handleMediaChange);
  }, []);

  const paths = [
    {
      name: 'Alchemist',
      subpaths: ['Apothecary', 'Elixir Artisan'],
      enabled: true,
    },
    { name: 'Artificer', subpaths: ['Automancer', 'Gearbane'], enabled: false },
    {
      name: 'Blighter',
      subpaths: ['Ruinbearer', 'Chaosweaver'],
      enabled: true,
    },
    {
      name: 'Clockwork Shaman',
      subpaths: ['Inferno Warden', 'Pyromancer'],
      enabled: true,
    },
    { name: 'Doomsayer', subpaths: ['Martyr', 'Wrathbringer'], enabled: false },
    {
      name: 'Fatestealer',
      subpaths: ['Mnemonic Mage', 'Temporal Weaver'],
      enabled: false,
    },
    {
      name: 'Fateweaver',
      subpaths: [`Harmony's Weaver`, 'Netherweaver'],
      enabled: false,
    },
    { name: 'Kryptios', subpaths: ['Shadowdancer', 'Assassin'], enabled: true },
    {
      name: 'Myrmidon',
      subpaths: ['Bloodbound', 'Spiritbound'],
      enabled: true,
    },
    { name: 'Primal', subpaths: ['Furybound', 'Stormcaller'], enabled: false },
    { name: 'Prophet', subpaths: ['Pastfinder', 'Visionary'], enabled: false },
    {
      name: 'Runelord',
      subpaths: ['Runic Arcanist', 'Timekeeper'],
      enabled: false,
    },
  ];

  const selectedPath = paths.find((p) => p.name === path);
  const subpaths = selectedPath ? selectedPath.subpaths : [];

  const darkMode = () => {
    if (mode === 'dark') {
      return '_dark';
    }
    return '';
  };

  return (
    <Rodal
      animation={'door'}
      visible={show}
      onClose={close}
      customStyles={{
        position: 'absolute',
        border: mode === 'dark' ? '2px solid #393e41' : '2px solid #e4E7C5', // beige
        backgroundColor: mode === 'dark' ? '#26292C' : '#f6f7eb', // ivory
        width: isFullScreen ? 'calc(100% - 34px)' : '350px',
        height: isFullScreen
          ? 'calc(100% - 114px)'
          : isPathOrSubpath === 'path'
          ? '580px'
          : '440px',
        marginTop: isFullScreen ? '80px' : '',
      }}
    >
      <div
        ref={contentRef}
        className={
          'cota_scroll_modal_content cota_scroll_modal_content' + darkMode()
        }
      >
        {isPathOrSubpath === 'path' ? (
          <>
            <h3 className='cota_scroll_modal_paragraph'>Select Path</h3>
            <div
              className={
                'cota_scroll_modal_divider cota_scroll_modal_divider' +
                darkMode()
              }
            />
            <div className='trialment_login_form_row'>
              <b>⚠️ Selecting a new Path will reset your Scrolls!</b>
            </div>

            <div>
              <div className='guild_selection_container'>
                {paths.map((guildTitle, index) => (
                  <span key={index}>
                    {index % 2 === 0 && (
                      <div className='trialment_login_form_row'>
                        <span>
                          <Button
                            label={guildTitle.name}
                            type={
                              path === guildTitle.name
                                ? 'cinnabar'
                                : mode === 'dark'
                                ? 'onyx'
                                : 'black-white'
                            }
                            disabled={!guildTitle.enabled}
                            onPress={() => {
                              handlePathChange(guildTitle.name);
                            }}
                          />
                        </span>
                        {paths[index + 1] && (
                          <span className='button_margin'>
                            <Button
                              label={paths[index + 1].name}
                              type={
                                path === paths[index + 1].name
                                  ? 'cinnabar'
                                  : mode === 'dark'
                                  ? 'onyx'
                                  : 'black-white'
                              }
                              disabled={!paths[index + 1].enabled}
                              onPress={() => {
                                handlePathChange(paths[index + 1].name);
                              }}
                            />
                          </span>
                        )}
                      </div>
                    )}
                  </span>
                ))}
              </div>
              <div
                className='cota_scroll_modal_footer'
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <Button
                  onPress={() => {
                    close();
                  }}
                  label='Select'
                  type={'cinnabar'}
                />
              </div>
            </div>
          </>
        ) : (
          <>
            <h3 className='cota_scroll_modal_paragraph'>
              Select Subpath{' '}
              {/*<b style={{ color: '#e94f37' }}>-Selection Resets Scrolls-</b>*/}
            </h3>
            <div
              className={
                'cota_scroll_modal_divider cota_scroll_modal_divider' +
                darkMode()
              }
            />
            <div style={{ marginTop: '12px' }}>
              <div className='guild_selection_container'>
                {subpaths.map((subpathTitle, index) => (
                  <span key={index} style={{ marginRight: '10px' }}>
                    <Button
                      label={subpathTitle}
                      type={
                        subpath === subpathTitle
                          ? 'cinnabar'
                          : mode === 'dark'
                          ? 'onyx'
                          : 'black-white'
                      }
                      onPress={() => {
                        handleSubPathChange(subpathTitle);
                      }}
                    />
                  </span>
                ))}
              </div>
              <div>
                <div className='trialment_login_form_row'>
                  Replace one of your RP Abilities with the{' '}
                  <b style={{ color: '#e94f37' }}> Subpath Ability </b> to
                  render it visible.
                </div>
                <div className='guild_selection_container'>
                  <div className='trialment_login_form_row'>
                    <Button
                      label={'RP Basic'}
                      type={
                        subpathRPAbilityPlace === '1'
                          ? 'cinnabar'
                          : mode === 'dark'
                          ? 'onyx'
                          : 'black-white'
                      }
                      disabled={subpath === ''}
                      onPress={() => setSubpathRPAbilityPlace('1')}
                    />
                    <span className='button_margin'>
                      <Button
                        label={'RP Minor'}
                        type={
                          subpathRPAbilityPlace === '2'
                            ? 'cinnabar'
                            : mode === 'dark'
                            ? 'onyx'
                            : 'black-white'
                        }
                        disabled={subpath === ''}
                        onPress={() => setSubpathRPAbilityPlace('2')}
                      />
                    </span>
                  </div>
                  <div className='trialment_login_form_row'>
                    <Button
                      label={'RP Major'}
                      type={
                        subpathRPAbilityPlace === '3'
                          ? 'cinnabar'
                          : mode === 'dark'
                          ? 'onyx'
                          : 'black-white'
                      }
                      disabled={subpath === ''}
                      onPress={() => setSubpathRPAbilityPlace('3')}
                    />
                    <span className='button_margin'>
                      <Button
                        label={'None'}
                        type={
                          subpathRPAbilityPlace === 'None'
                            ? 'cinnabar'
                            : mode === 'dark'
                            ? 'onyx'
                            : 'black-white'
                        }
                        onPress={() => setSubpathRPAbilityPlace('None')}
                      />
                    </span>
                  </div>
                </div>
                <div className='trialment_login_form_row'>
                  Your new{' '}
                  <b style={{ color: '#e94f37' }}> Subpath Scroll/Scrolls </b>{' '}
                  have been added to the end of your available scrolls.
                </div>
              </div>
              <div
                className='cota_scroll_modal_footer'
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <Button
                  onPress={() => {
                    close();
                  }}
                  label='Select'
                  type={'cinnabar'}
                />
              </div>
            </div>
          </>
        )}
      </div>
    </Rodal>
  );
};

import { createSlice } from '@reduxjs/toolkit';

const appearanceSlice = createSlice({
  name: 'appearance',
  initialState: {
    mode: 'light',
  },
  reducers: {
    setMode: (state, action) => {
      state.mode = action.payload;
    },
  },
});

export const { setMode } = appearanceSlice.actions;
export default appearanceSlice.reducer;
import React from 'react';
import { useState, useEffect } from 'react';

const CotaRPAbility = ({ id, diceBox, mode  }) => {
  const [potionName, setPotionName] = useState('');
  const [potionRoll, SetPotionRoll] = useState();

  const darkMode = () => {
    if (mode === 'dark') {
      return '_dark';
    }
    return '';
  };


  useEffect(() => {
    const savedData = localStorage.getItem(`Potion${id}`);
    if (savedData) {
      const { potionName, potionRoll } = JSON.parse(savedData);
      setPotionName(potionName);
      SetPotionRoll(potionRoll);
    }
  }, []);

  const roll = () => {
    const validDiceRegex = /^[1-9]\d*\s*d\s*(4|6|8|10|12|20|100)$/;

    if (validDiceRegex.test(potionRoll)) {
      diceBox.roll(potionRoll);
    }
  };

  useEffect(() => {
    const RPData = {
      potionName,
      potionRoll,
    };
    localStorage.setItem(`Potion${id}`, JSON.stringify(RPData));
  }, [potionName, potionRoll]);

  return (
    <div key={id} className={'cota_rp_container cota_rp_container' + darkMode()}>
      <div className='cota_rp_title'>
        <div className='cota_rp_indicator'>POTION:</div>
      </div>
      <div
        className='cota_potion_name'
        contentEditable
        suppressContentEditableWarning
        onBlur={(e) => setPotionName(e.target.innerText)}
      >
        {potionName}
      </div>
      <div className='cota_rp_actions'>
        <div className='cota_potion_rolls_title'>➤ ROLL:</div>
        <div
          className='cota_potion_rolls'
          contentEditable
          suppressContentEditableWarning
          onBlur={(e) => SetPotionRoll(e.target.innerText)}
        >
          {potionRoll}
        </div>
        <div className='cota_potion_dice' onClick={() => roll()}>
          🎲
        </div>
      </div>
    </div>
  );
};

export default CotaRPAbility;
